import React, {useEffect, useState, useContext} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import API from '../../api';
import {hideLoader, toast, showLoader} from "../../_helpers/Loader";
import Moment from "react-moment";
import {Editor, bold, italic, underline,} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import optionsWysiwyg from '../helpers/wysiwyg';

const SendMails = (props) => {
    const context = useContext(Context);
    const [mails, setMails] = useState([]);
    const [remove_item, setRemove_item] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [stateL, setStateL] = useState({
        subject: '',
        from_email: '',
        body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        specific_user: '',
        all_user: true,
        exit: false,
        test_email: '',
        id: '',
        page: 1
    });

    const [fromMails, setFromMails] = useState([])
    const [showMailInput, setShowMailInput] = useState(false)

    useEffect(() => {
        getMails()
        getUsedMailAddress()
        return () => {
            console.log("")
        };
    }, []);

    const clear = () => {
        setStateL({
            editorState: EditorState.createEmpty(),
            id: '',
            subject: '',
            title: '',
            from_email: '',
            recipient: '',
            body: '',
            specific_user: '',
            recipient: '',
            all_user: true,
        })
    };

    const getMails = async()=> {
      showLoader()
        try {
          let res = await API.get('admin/messages/mailing_list/', );
            setMails(res.data);
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const process = async (event, method) => {
        event.preventDefault();
        let postData = {
            subject: stateL.subject,
            recipient: stateL.recipient,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            from_email: stateL.from_email
        };
        if (postData.body.length < 5) {
            toast.info("Content too small")
        } else {
            showLoader();
            if (method === 'put') {
                try {
                    let res = await API.put('admin/messages/' + stateL.id + '/', postData);
                    await getMails();
                    (stateL.exit === true) && setStateL({...stateL, id: '', page: 1, exit: false});
                    hideLoader()
                } catch (e) {
                    // console.log(e.response);
                    hideLoader();
                }
            } else {
                try {
                    let res = await API.post('admin/messages/to_send_mail/', postData);
                    clear();
                    toast.success("Message sent successfully")
                    // await getMails();
                    // (stateL.exit === true) && setStateL({...stateL, id: '', page: 1, exit: false});
                    hideLoader()
                } catch (e) {
                    // console.log(e.response);
                    hideLoader();
                }
            }
        }
    };

    const sendTest = async () => {
        try {
            let res = API.post(`admin/messages/${stateL.id}/send_test_mail/`, {email: stateL.test_email})
        } catch (e) {
            // console.log(e.response)
        }
    };

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const uploadCallback = (file, callback) => {
        console.log(file);
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          console.log(reader);
          reader.onloadend = async () => {
            const res = await API.uploadFile("users/uploads/", file);
            console.log(res)
            // setValue("thumbnail", res.data.secure_url);
            resolve({ data: { link: res.data.secure_url } });
          };
          reader.readAsDataURL(file);
        });
    };

    let options = optionsWysiwyg(uploadCallback);

    const getUsedMailAddress = async () => {
        showLoader()
        try {
          let res = await API.get('admin/messages/get_from_emails/', );
            console.log(res.data, "res.data");
            setFromMails(res.data);
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const addPage = () => {
        return (
            <Layout context={context} active_menu={'send-mail'} title={'Send Mail'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><Link onClick={event => setStateL({...stateL, page: 1})}><span className="fa fa-chevron-left"/>Send Mail
                            </Link></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Send Mail</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event)}>
                                        <div className="form-group">
                                            <h4>From Mail <small><i className="text-danger">Note that only mail from "twelvevest.com" goes through so email should be in form of "whatever@twelvevest.com"</i></small></h4>
                                            <select className="form-control" onChange={event => {
                                                if (event.target.value == "Enter Mail"){
                                                    setShowMailInput(true)
                                                } else {
                                                    setShowMailInput(false)
                                                    setStateL({...stateL, from_email: event.target.value})
                                                }
                                            }}>
                                                <option>Choose From Mail</option>
                                                {fromMails.length > 0 ? fromMails.map(item => {
                                                    if (item){
                                                        return (<option>{item}</option>)
                                                    }
                                                }) : ""}
                                                <option>Enter Mail</option>
                                            </select>
                                            {showMailInput ? 
                                            <>
                                            <br/>
                                            <label>Enter New From Mail</label>
                                            <input required value={stateL.from_email}
                                                   onChange={event => setStateL({...stateL, from_email: event.target.value})}
                                                   className="form-control" type="email"/> </> : ""}
                                        </div>
                                        <div className="form-group">
                                            <h4>Subject</h4>
                                            <input required value={stateL.subject}
                                                   onChange={event => setStateL({...stateL, subject: event.target.value})}
                                                   className="form-control" type="text" maxLength={"100"}/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Content <small><i className="text-danger">(to personalize add "%name%") e.g Dear %name%, </i></small></h4>
                                            <Editor
                                                editorState={editorState}
                                                toolbar={options}
                                                initialEditorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                          <h4>Select list to send to</h4>
                                          <select className='form-control' onChange={(e) => setStateL({...stateL, recipient: e.target.value})}>
                                            <option></option>
                                              {mails.length > 0 ? mails.map(item => (
                                                <option value={item.id}>{item.name}</option>
                                              )) : ""}
                                            
                                          </select>
                                        </div>
                                        {/* <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={stateL.all_user}
                                                       onChange={event => {
                                                           setStateL({...stateL, all_user: event.target.checked});
                                                           if (event.target.checked) {
                                                               setStateL({...stateL, specific_user: ''})
                                                           }
                                                       }}
                                                       type="checkbox" className="custom-control-input"
                                                       id="all"/>
                                                <label className="custom-control-label" htmlFor="all">All users</label>
                                            </div>
                                        </div> */}
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-green mr-3">Send</button>
                                            <button onClick={event => setStateL({...stateL, page: 3})}
                                                    className="btn btn-outline-dark">Preview
                                            </button>
                                        </div>
                                        <div className="mb-5"></div>
                                        <textarea style={{width: '100%'}}
                                                  disabled
                                                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    const editPage =() =>{
        return (
            <Layout context={context} active_menu={'send-mail'} title={'Add Mails'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><Link to="/mails"><span className="fa fa-chevron-left"/> Mail
                            List</Link></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Create Mail</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event, 'put')}>
                                        <div className="form-group">
                                            <h4>Subject</h4>
                                            <input required value={stateL.subject}
                                                   onChange={event => setStateL({...stateL, subject: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Title</h4>
                                            <input required value={stateL.title}
                                                   onChange={event => setStateL({...stateL, title: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Thumbnail</h4>
                                            <input required value={stateL.thumbnail}
                                                   onChange={event => setStateL({...stateL, thumbnail: event.target.value})}
                                                   className="form-control" type="text"/>
                                            <br/>
                                            <img width="10%" src={stateL.thumbnail} alt=""/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Content</h4>
                                            <Editor
                                                editorState={editorState}
                                                toolbar={options}
                                                initialEditorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={stateL.all_user}
                                                       onChange={event => {
                                                           setStateL({...stateL, all_user: event.target.checked});
                                                           if (event.target.checked) {
                                                               setStateL({...stateL, specific_user: ''})
                                                           }
                                                       }}
                                                       type="checkbox" className="custom-control-input"
                                                       id="all"/>
                                                <label className="custom-control-label" htmlFor="all">All users</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <h4>Specific users</h4>
                                            <textarea disabled={stateL.all_user} value={stateL.specific_user}
                                                      onChange={event => setStateL({...stateL, specific_user: event.target.value.replace(/^\s+|\s+$/gm, '')})}
                                                      className="form-control"></textarea>
                                            <small>Enter email address of users separated by a comma</small>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green mr-3">Save</button>
                                            <button onClick={event => clear(event)}
                                                    className="btn btn-outline-dark mr-3">Clear
                                            </button>
                                            <button onClick={event => setStateL({...stateL, exit: true})}
                                                    className="btn btn-orange">Save and Exit
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Test Email</label>
                                            <input onChange={event => setStateL({...stateL, test_email: event.target.value})}
                                                   type="email" className="col-4 form-control mb-3"/>
                                            <button type="button" onClick={event => sendTest(event)}
                                                    className="btn btn-orange">Send test mail
                                            </button>
                                            <br/>
                                            <small>If you made any changes, make sure you save before sending test
                                            </small>
                                        </div>
                                        <div className="mb-5"/>
                                        <textarea style={{width: '100%'}}
                                                  disabled
                                                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    const detailPage = () =>{
      return (
          <Layout context={context} active_menu={'send-mail'} title={'Preview Email'}>
              <div className="section">
                  <div className="breadcrumb">
                      <h5><Link onClick={event => {
                          setStateL({...stateL, page: 1})
                  }}><span className="fa fa-chevron-left"/> Back
                      </Link></h5>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="card">
                              <div className="card-header bg-green">
                                  <h4>Preview Email</h4>
                              </div>
                              <div className="card-body">
                                  <div className='text-left'><h2>Subject: {stateL.subject}</h2></div>
                                  <br/>
                                  <div className="mx-auto" dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(editorState.getCurrentContent()))}} />
                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Layout>
      )
  }


    if (stateL.page === 1) {
        return addPage();
    } else if (stateL.page == 2) {
        return editPage();
    } else {
      return detailPage();
    }
}

export default SendMails;
