import React, {useContext, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip'
import {CSVLink, CSVDownload} from "react-csv";
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import * as FileSaver from 'file-saver';
import $ from 'jquery';
import NumberFormat from "react-number-format";
import XLSX from "xlsx";
import extractDataFromExcel from './utils';
import { BaseObjectEvents } from '@amcharts/amcharts4/.internal/core/Base';

const ExcelJS = require('exceljs');
const moment = require('moment');

const OverviewInterestedClub = (props) =>{
    const [orders, setOrders] = useState([]);
    const [interestUrl, setInterestUrl] = useState('show_interest')
    const [current_order_for, setCurrent_order_for] = useState('individual')
    const [club, setClub] = useState(null);
    const [stated, setStated] = useState({
        investment: {},
        orders: [],
        stats: {},
        ind_stats: {},
        sub_stats: {},
        club_stats: {},
        total_stats: {},
        total_amount: null,
        payment_method_stats: {},
        status: 'paid',
        buffer: [],
        csv_data: [],
        current_page: 1,
        num_pages: 1,
        export: false,
        export_title: "Export File",
        recurring_activity: [],
        initiate_modal: '',
        payout_page: 1,
        file_payout_page: 1,
        file_payment_page: 1,
        payout_file: "",
        user_id: null,
        user_mail: null,
        penalty_order_id: null,
        current_order_for: 'individual',
    });
    const context = useContext(Context);

    useEffect(() => {
        showLoader();
        // console.log(props.match.params.investment_id, props.match.params.id, "hello there")
        if (Object.keys(stated.investment).length == 0){
            getInvestment();
        }
        getClub();
        // await getInvestmentStats();
        getOrders();
        ReactTooltip.rebuild();
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        hideLoader();

        return () => {
            console.log("")
        };
    }, [interestUrl, current_order_for]);

    const getInvestment = async () => {
        try {
            let res = await API.get('admin/investments/' + props.match.params.investment_id + '/');
            setStated({ ...stated, investment: res.data})
        } catch (e) {

        }
    };

    const getClub = async () => {
        //show_club_interested_members
      try{
        let res = await API.get('admin/clubs/' + props.match.params.id + '/');
        setClub(res.data)
      }catch(e){}
    }

    const getInvestmentStats = async () => {
        let postData = {
            investment: props.match.params.id
        };
        try {
            const res = await API.post('admin/investor_orders/get_all_investment_stats/', postData);
            setStated({ ...stated, 
                stats: res.data.individual,
                ind_stats: res.data.individual,
                sub_stats: res.data.sub_investor,
                club_stats: res.data.club,
                total_stats: res.data.total_stats,
                payment_method_stats: res.data.payment_stats,
            })
        } catch (e) {
            // console.log(e.response)
        }
    }

    const getOrders = async () => {
        showLoader()
        try {
            let res = await API.get(`admin/show_club_interest/${props.match.params.investment_id}/show_club_interested_members/?page=${stated.current_page}`);
            setStated({ ...stated, 
                current_page: res.data.current_page,
                num_pages: res.data.total_pages
            });
            setOrders(res.data.results)
        } catch (e) {
            // console.log(e)
        }
        hideLoader()
    };

    const filter = async () => {
        showLoader();

        try {
            let res = await API.get(`admin/show_club_interest/?page=${1}`);
            setStated({ ...stated, 
                orders: res.data.results,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            showLoader();
        }
    };

    const nextPage = async (page) => {
        showLoader();
        let postData = {
            investment: props.match.params.id
        };
        try {
            let res = await API.get(`admin/show_club_interest/${props.match.params.investment_id}/show_club_interested_members/?page=${page}`);
            setStated({ ...stated, 
                orders: res.data.results,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };


    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await searchFields(e.target.value);
        }
    };

    const searchFields = async (search_value) => {
        showLoader();
        try {
            let res = await API.get(`admin/show_club_interest/?page=${1}&search=${search_value}`);
            setStated({ ...stated, 
                orders: res.data.results,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages,
                status: res.data.status
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    const exportFile = async () => {
        setStated({ ...stated, export_title: "Processing..."});
        try {
            let res = await API.get(`admin/show_club_interest/${props.match.params.investment_id}/show_club_interested_members/?all=true`);
            // console.log(res.data, "khdkhkhfs")
            processCsv(res.data);
        } catch (e) {
        }
        return true
    };

    const processCsv = async (orders) => {
        let main_data = [];
        // console.log(orders, "oooooooo");
        orders.map(item => {
            main_data.push([item.id, item.member.user.email, `${item.member.user.first_name} ${item.member.user.last_name}`, item.proposed_unit, item.amount, moment(item.created_at).format("YYYY-MM-DD")])
        });

        let workbook = new ExcelJS.Workbook();
        let title = stated.investment.title;
        let worksheet = workbook.addWorksheet(`${club.name} Interested Member`);
        worksheet.columns = [
            {header: 'id (always include)', key: 'id', width: 30, style: {numFmt: '@'}},
            {header: 'email', key: 'email', width: 30},
            {header: 'name', key: 'name', width: 30},
            {header: 'units', key: 'units', width: 10},
            {header: 'amount', key: 'units', width: 10},
            {header: 'interested_date', key: 'investment_date', width: 25},
        ];
        
        title = 'club' + stated.investment.title 
        worksheet.addRows(main_data);
        await workbook.xlsx.writeBuffer().then(function (data) {
            var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `${((title).toLowerCase()).split(" ").join("_")}.csv`);
        });
        setStated({ ...stated, export_title: "Export File", export: false})
    };

    const changeUserOrder = async (value) => {
        // console.log(value, "vvalue value")
        showLoader();
        await getOrders(value);
        hideLoader();
    };

    const getBase64 = async(file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const exportTransactionExcel = async (event) => {
        setStated({ ...stated, export_title: 'Exporting...'})
        const req_data = {
            method: 'get',
            url: `admin/investments/${props.match.params.id}/export_transactions/`,
            responseType: "blob",
        }
        try {
            const res = await API.downloadData(req_data);
            var blob = new Blob([res.data], {type: res.headers['content-type']});
            var fileURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = `${stated.investment.title}_by_${stated.investment.company}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(fileURL);
            setStated({ ...stated, export_title: 'Export File'})
        } catch (e) {
            setStated({ ...stated, export_title: 'Export File'})
        }
    }


    return (
        <Layout context={context} active_menu={'investment'} title={'Investment Interest'}>
            <ReactTooltip/>
            <div className="section">
                <div className="breadcrumb">
                    <h5><Link to={`/show-interested/${stated.investment.id}`}><span className="fa fa-chevron-left"/> Investments</Link></h5>
                </div>
                <div className="row" id="orders">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green">
                                <h4>{club ? club.name + " Interested Members" : "" }
                                    <div className="float-lg-right float-xl-right float-md-right">
                                        <button onClick={event => exportFile()}
                                                className="btn btn-orange"><i
                                            className="fa fa-download"/> {stated.export_title}</button>
                                    </div>
                                </h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-6">
                                        <div id="example_filter" className="dataTables_filter"><label><input
                                            type="search" className="form-control form-control-sm"
                                            placeholder="Search"
                                            aria-controls="example"
                                            onKeyPress={event => handleSearch(event)}/></label>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='text-primary'><h4><strong>Investment Status:</strong> {stated.investment.status}</h4></div>
                                <div id="example_wrapper table-responsive"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer table-responsive ">
                                    <table
                                        className="table table-striped table-bordered border-t0 table-responsive-xl text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "15px"}}>S/N
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>{current_order_for == 'individual' ? 'Individuals' : current_order_for == 'child' ? 'Parent' : 'Clubs' }
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Email
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Units/Tokens
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Created At
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orders.map((item, key) => (
                                            <tr role="row" key={item.id}>
                                                <td>{((stated.current_page - 1) * 30) + (key + 1)}</td>
                                                <td className="sorting_1"><Link
                                                            to={`/user/${item.member.user.id}`}>{item.member.user.first_name} {item.member.user.last_name}</Link>
                                                </td>
                                                <td>{item.member.user.email}</td>
                                                <td>
                                                    {Utils.NumberDecimal(item.proposed_unit)}
                                                </td>
                                                <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-center">
                                    <ul className="pagination mt-3 mb-0">
                                        <li className={`${(stated.current_page > 1) ? '' : 'disabled'} page-item`}>
                                            <a className="page-link"
                                               onClick={event => nextPage(stated.current_page - 1)}>‹</a>
                                        </li>
                                        {[...Array(stated.num_pages)].map((item, key) => (
                                            <li className={`${((key + 1) === stated.current_page) ? 'active' : ''} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => nextPage(key + 1)}>{key + 1}</a>
                                            </li>
                                        ))}
                                        <li className={`${(stated.current_page === stated.num_pages) ? 'disabled' : ''} page-item`}>
                                            <a className="page-link"
                                               onClick={event => nextPage(stated.current_page + 1)}>»</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}


export default OverviewInterestedClub;
