import React, {Component} from 'react';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Layout from "../Layout";
import {Context} from "../MyContext";
import Moment from "react-moment";
import API from '../../api';

class PayOutMethodChange extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            monnify: "",
            paystack: "",
            ngn_to_usd: 0,
            display_usd: 0,
            ngn_to_gbp: 0,
            display_gbp: 0,
            qualification_cost: 0

        }
    }

    async componentDidMount() {
        await this.getAppSettings()
    }

    getAppSettings = async () => {
        showLoader();
        try {
            const res = await API.get('admin/settings/');
            console.log(res.data)
            if (res.data.length > 0) {
                this.setState({
                    'ngn_to_usd': res.data[0].ngn_to_usd,
                    'display_usd': res.data[0].display_usd,
                    'ngn_to_gbp': res.data[0].ngn_to_gbp,
                    'display_gbp': res.data[0].display_gbp,
                    'qualification_cost': res.data[0].qualification_cost
                })
            }
            hideLoader();
        } catch (err) {
            hideLoader();
        }
    }

    activatePayoutMethod = async () => {
        showLoader();
        let data = {
            payment_method: document.getElementById("payment_method").value
        }
        try {
            const res = API.post('admin/wallet/change_payout_method/', data);
            toast.success("Payout method has been switched to " + data.payment_method.split('_')[1].toUpperCase());
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Payout method switch Error");
        }
        this.setState({
            monnify: data.payment_method == "is_monnify" ? true : false,
            paystack: data.payment_method == "is_paystack" ? true : false
        })
    }

    updateSettings = async (event) => {
        showLoader();
        event.preventDefault();
        try {
            const res = await API.post('admin/settings/', {
                ngn_to_usd: this.state.ngn_to_usd,
                display_usd: this.state.display_usd,
                ngn_to_gbp: this.state.ngn_to_gbp,
                display_gbp: this.state.display_gbp,
            });
            toast.success("Updated successfully")
            if (res.data.length > 0) {
                this.setState({
                    ngn_to_usd: res.data[0].ngn_to_usd,
                    display_usd: this.state.display_usd
                })
            }
            hideLoader();
        } catch (e) {
            toast.error("Something went wrong")
            hideLoader();
        }
    }

    updateQualificationSettings = async (event) => {
        showLoader();
        event.preventDefault();
        try {
            const res = await API.post('admin/settings/qualification_cost/', {
                qualification_cost: this.state.qualification_cost,
            });
            toast.success("Updated successfully")
            if (res.data.length > 0) {
                this.setState({
                    qualification_cost: res.data[0].qualification_cost,
                })
            }
            hideLoader();
        } catch (e) {
            toast.error("Something went wrong")
            hideLoader();
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'settings'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>App Settings</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Currency Settings</h4>
                            </div>
                            <form onSubmit={event => this.updateSettings(event)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card">

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label>Current transactional USD value</label>
                                                        <input
                                                            onChange={event => this.setState({ngn_to_usd: event.target.value})}
                                                            value={this.state.ngn_to_usd} step=".01" type="number"
                                                            className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Current dashboard USD value</label>
                                                        <input
                                                            onChange={event => this.setState({display_usd: event.target.value})}
                                                            value={this.state.display_usd} step=".01" type="number"
                                                            className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="card">

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label>Current transactional GBP value</label>
                                                        <input
                                                            onChange={event => this.setState({ngn_to_gbp: event.target.value})}
                                                            value={this.state.ngn_to_gbp} step=".01" type="number"
                                                            className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Current dashboard GBP value</label>
                                                        <input
                                                            onChange={event => this.setState({display_gbp: event.target.value})}
                                                            value={this.state.display_gbp} step=".01" type="number"
                                                            className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-green">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Qualification Cost Settings</h4>
                            </div>
                            <form onSubmit={event => this.updateQualificationSettings(event)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label>Qualification Cost in USD</label>
                                                        <input
                                                            onChange={event => this.setState({qualification_cost: event.target.value})}
                                                            value={this.state.qualification_cost} step=".01" type="number"
                                                            className="form-control"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-green">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default PayOutMethodChange;
