import XLSX from "xlsx";

const extractDataFromExcel = async (filex) =>{
  const data = await filex.arrayBuffer();
  const workbook = XLSX.readFile(data);
  const sheets = workbook.SheetNames;
  let datax = {};
  for(let i=0; sheets.length > i; i++){
      let sht = XLSX.utils.sheet_to_json(workbook.Sheets[sheets[i]])
      datax[sheets[i].toLowerCase()] = sht;
  }
  return datax;
}

export default extractDataFromExcel;