import React, {Component} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Moment from "react-moment";
import API from '../../api';
import {showLoader, hideLoader, toast, Utils} from "../../_helpers/Loader";
import uuidv1 from 'uuid';
import {CircularProgressbar} from "react-circular-progressbar";
import NumberFormat from "react-number-format";
import Chart from 'chart.js';
import $ from 'jquery';
import excludeList from '../excludeList';

class ClubDashboard extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            portfolio: '',
            club: {},
            total_networth: '',
            total_amount: 0,
            total_return: 0,
            investments: [],
            percentage_interest: 0,
            activities: [],
            members: [],
            contributions: [],
            target: 0,
            chart_data: {},
            total_deposits: 0,
            amount_spent: 0,
            total_investment: 0,
            total_expected_return: 0,
            total_interest: 0,
            total_liability_value: 0,
            total_roi: 0,
            total_percentage: 0,
            modal: 'none',
            message: '',
            max_members: 0,
            wallet: {},
            switch_option: '',
        };

    }


    async componentDidMount() {
        showLoader()
        await this.getPortfolio();
        await this.getClubPortfolio()
        await this.getPieInfo()
        await this.getWalletInfo()
        hideLoader()
    }

    getAdminEmail(item) {
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user_role === 'admin') {
                    return item[i].user.email.toLowerCase();
                }
            }
            return false
        } else {
            return false
        }
    }

    async getPortfolio() {
        try {
            let res = await API.get(`admin/clubs/${this.props.match.params.id}/`);
            let data = res.data;
            this.setState({portfolio: data});
            let networth = data.total_asset_value - data.total_liability_value;
            let target = ((networth / data.networth_goal) * 100).toFixed(2);
            this.setState({total_networth: networth});
            this.setState({target: target});
            this.setState({max_members: data.club_subscription[0].package.max_members});
            this.setState({
                club: data,
                activities: data.club_activities.slice(0, 4),
                members: data.club_members,
                investment: data.club_investments,
                contributions: data.club_deposits.slice(0, 4)
            });
            this.setState({
                chart_data: {
                    asset: data.assets_total_quarterly,
                    liability: data.liabilities_total_quarterly,
                    networth: data.quarterly_networth
                }
            });
            this.setState({investments: data.club_clubinvestments});
            let total_investment = 0;
            let total_expected_return = 0;
            let total_roi = 0;
            let total_interest = 0;
            let total_percentage = 0;
            for (var i = 0; i < data.club_clubinvestments.length; i++) {
                total_investment = total_investment + parseFloat(data.club_clubinvestments[i].value);
                total_expected_return = total_expected_return + parseFloat(data.club_clubinvestments[i].expected_return);
                total_roi = total_roi + parseFloat(data.club_clubinvestments[i].roi);
            }
            total_interest = total_expected_return - total_investment;
            total_percentage = ((total_interest) / total_investment) * 100;
            this.setState({
                total_investment: total_investment,
                total_expected_return: total_expected_return,
                total_roi: total_roi,
                total_interest: total_interest,
                total_percentage: (total_percentage) ? total_percentage : 0
            });
            if (data.club_deposits.length > 0) {
                this.setState({total_deposits: data.club_deposits[0].total_deposits})
            }
            console.log(this.state.club,">><<")

        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    handleSwitch = async event => {
        const postData = {
            'option': this.state.switch_option
        }
        showLoader();
        try {
            const res = await API.post(`admin/clubs/${this.state.club.id}/switch_club/`, postData)
            this.setState({user: res.data});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            hideLoader();
            toast.success("Club successfully switched");
        } catch (e) {
            hideLoader();
        }
    }

    async getClubPortfolio() {
        try {
            let res = await API.get(`admin/clubs/${this.props.match.params.id}/get_stats/`);
            let data = res.data;
            let networth = data.total_asset_value - data.total_liability_value;
            let target = ((networth / parseFloat(this.state.club.networth_goal)) * 100).toFixed(2);
            if (this.state.target <= 0 || target < 0) {
                target = 0
            }
            this.setState({
                total_networth: networth,
                total_liability_value: data.total_liability_value,
                target: (target != Infinity) ? (target) : 0
            });
            (target < 0) && (this.setState({radial_colour: 'circle-danger'}));
            this.setState({
                chart_data: {
                    asset: data.assets_total_quarterly,
                    liability: data.liabilities_total_quarterly,
                    networth: data.quarterly_networth
                }
            });
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    getWalletInfo = async () => {
        let spent_count = 0;
        try {
            let res = await API.get(`admin/wallet/${this.props.match.params.id}/get_club_wallet_balance/`, {params: {page: 5}});
            this.setState({wallet: res.data, amount_spent: res.data.amount_spent})
        } catch (e) {

        }
    };

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async quarterlyChart(labels, dataset, is_empty) {
        var canvas = document.getElementById("myChart");
        var ctx = canvas.getContext('2d');
        let title = 'Add or buy asset to see your asset distribution chart here.';
        var options = {
            title: {
                display: true,
                text: title,
                position: 'top'
            },
            responsive: true,
            maintainAspectRatio: true,
            rotation: -0.7 * Math.PI,
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {
                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                            //Don't Display If Legend is hide or value is 0
                            if (dataset.data[i] != 0) {
                                // Display percent in another line, line break doesn't work for fillText
                                ctx.fillText(percent, model.x + x, model.y + y + 15);
                            }
                        }
                    });
                }
            },
            legend: {
                position: 'bottom'
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {

                        let label = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return ` ${label}: ₦${value.toLocaleString()}`

                    }
                }
            }
        };
        if (!is_empty) {
            delete options['title']
        }
        var oilData = {
            labels: labels,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        "#47C4AA",
                        "#23414D",
                        "#FB703E",
                        "#F2BF5E",
                        "#F28B50",
                        "#fcb8a9",
                        "#177f68",
                        "#41738b",
                        "#99d249",
                        "#d2fb3e",
                        "#3e57fb"
                    ]
                }]
        };

        var pieChart = new Chart(ctx, {
            type: 'pie',
            data: oilData,
            options: options
        });

    }

    getPieInfo = async () => {
        try {
            const res = await API.post(`admin/clubs/${this.props.match.params.id}/get_asset_info/`)
            let data = res.data;
            let labels = Object.keys(data), dataset = [];
            labels = labels.filter(item => item !== 'is_empty');
            labels.map(item => {
                dataset.push(data[item])
            })
            this.quarterlyChart(labels, dataset, data.is_empty)
        } catch (e) {
            // console.log(e)
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'club'}
                    title={this.state.club.name}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-sm-6 ml-0 pl-0">
                            <h5><Link to="/users"><span className="fa fa-chevron-left"/> {this.state.club.name}</Link></h5>
                        </div>
                        {this.context.user.is_superuser ? (
                            <div className="col-sm-6 mr-0 pr-0">
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-4'></div>
                                        <div className='col-6 mr-0 pr-0'>
                                            <div className='form-group'>
                                                <select name="switch_user" onChange={(e) => this.setState({switch_option: e.target.value})} className="form-control">
                                                    <option value="">-- Switch Club To --</option>
                                                    {this.state.club.club_type === 'twelve' ? "": <option value="twelve">Twelve</option>}
                                                    {this.state.club.club_type === 'tgic' ? "": <option value="tgic">TGIC</option>}
                                                    {this.state.club.club_type === 'twelveblack' ? "": <option value="twelveblack">Twelve Black</option>}
                                                    {this.state.club.club_type === 'fwp' ? "": <option value="fwp">FWP</option>}

                                                </select>
                                            </div>
                                            <small>Current Profile: <span className="text-uppercase" style={{"color": "#1F5277"}}>({this.state.club.club_type})</span></small>
                                        </div>
                                        <div className='col-2 ml-0 pl-0'>
                                            <button className="btn btn-primary" data-toggle="modal" data-target="#greenModal" style={{padding:"6px 12px"}} disabled={this.state.switch_option == '' ? true : false}>Switch</button>
                                        </div>
                                    </div>
                                </div>
                            </div>) : ''}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                            <button onClick={event => {
                                                document.getElementById('portfolio').scrollIntoView({'behavior': 'smooth'})
                                            }}
                                                    className="btn  btn-social m-b-5 btn-green mr-2"><i
                                                className="fa fa-folder-open"/> Portfolio
                                            </button>
                                            <button onClick={event => {
                                                document.getElementById('investments').scrollIntoView({'behavior': 'smooth'})
                                            }} className="btn  btn-social m-b-5 btn-green mr-2"><i
                                                className="fa fa-bar-chart"/> Investments
                                            </button>
                                            <button onClick={event => {
                                                document.getElementById('contributions').scrollIntoView({'behavior': 'smooth'})
                                            }} className="btn  btn-social m-b-5 btn-green mr-2"><i
                                                className="fa fa-coins"/> Contributions
                                            </button>
                                            <button onClick={event => {
                                                document.getElementById('activities').scrollIntoView({'behavior': 'smooth'})
                                            }} className="btn  btn-social m-b-5 btn-green mr-2"><i
                                                className="fa fa-newspaper-o"/> Activities
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="portfolio">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Club Portfolio</h4>
                                        </div>
                                        {(this.context.is_admin) ? (
                                            <div className="col-sm-6">
                                                <Link to={`/club/${this.props.match.params.id}/edit`}
                                                      className="btn  btn-social m-b-5 btn-orange float-right"><i
                                                    className="fa fa-edit"/> Edit Goals
                                                </Link>
                                                <Link to={`/manage-club/${this.props.match.params.id}`}
                                                      className="btn  btn-social m-b-5 btn-orange float-right mr-2"><i
                                                    className="fa fa-cog"/> Settings
                                                </Link>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 mx-auto justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5 className="text-orange"
                                                                                          style={{
                                                                                              fontWeight: 'bolder',
                                                                                              fontSize: '16px'
                                                                                          }}>
                                                {(parseInt(this.state.target) <= 0) && 'How are you doing with your goal? Taking action?'}
                                                {(parseInt(this.state.target) <= 39 && parseInt(this.state.target) >= 1) && 'Keep going. You are in it to win it!'}
                                                {(parseInt(this.state.target) <= 70 && parseInt(this.state.target) >= 40) && 'You are phenomenal, you are almost there. Keep going.'}
                                                {(parseInt(this.state.target) <= 99 && parseInt(this.state.target) >= 71) && 'You have taken this so seriously. Keep pushing.'}
                                                {(parseInt(this.state.target) === 100) && 'Congratulations. You have achieved your goal!'}
                                                {(parseInt(this.state.target) > 100) && 'You are on a roll. You are an overachiever!'}</h5>
                                            </div>
                                            <div
                                                data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                <CircularProgressbar
                                                    className={`mx-auto ${this.state.radial_colour} text-center`}
                                                    styles={{width: '70%'}}
                                                    value={parseInt(this.state.target).toFixed(2)}
                                                    text={`${parseInt(this.state.target)}%`}>
                                                </CircularProgressbar>
                                            </div>
                                            <br/><br/>
                                            <strong className="text-orange">{parseInt(this.state.target)}% of target
                                                net worth achieved.</strong>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5
                                                className="text-orange"
                                                style={{
                                                    fontWeight: 'bolder',
                                                    fontSize: '16px'
                                                }}>Breakdown of total assets</h5>
                                            </div>
                                            <div className="" style={{marginBottom: '2.5rem'}}></div>
                                            <div className="chart-container">
                                                <canvas id="myChart"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(this.state.total_networth).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Current Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(this.state.club.networth_goal).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Target Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(this.state.club.total_asset_value).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Assets</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(this.state.total_liability_value).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Liability</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="investments">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Club Investments</h4>
                                    {/*<Link to={`/club/${this.props.match.params.id}/investments`}*/}
                                    {/*      className="btn m-b-5 btn-orange float-right"> View All*/}
                                    {/*</Link>*/}
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-bar-chart"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total Amount Invested "
                                                               className="fa fa-info-circle text-right float-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span className="text-white">Total Investment</span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(this.state.total_investment).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-reply-all"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="How much you will receive from all your investments"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-white">Expected Return (ROI)</span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(this.state.total_expected_return).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-signal"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total amount you will receive from your investments"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span className="text-white">Interest</span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(this.state.total_interest).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-line-chart"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Percentage Interest gained from  your investments"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white">Percentage Interest</span>
                                                                <h2 className="text-white mb-0">{(this.state.total_percentage).toFixed(2)}%</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Unit</th>
                                                <th>ROI(%)</th>
                                                <th>Expected Return</th>
                                                <th>Purchased Date</th>
                                                <th>Maturity Date</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            {this.state.investments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.investment.title} by {item.investment.company}</td>
                                                    <td><NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={'₦'} displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={parseFloat(item.value).toFixed(2)}/>
                                                    </td>
                                                    <td>{parseInt(item.units)}</td>
                                                    <td>{item.roi}</td>
                                                    <td><NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={'₦'} displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={item.expected_return}/></td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td><Moment format="MMM D, Y">{item.maturity_date}</Moment></td>
                                                    <td>
                                                    <td>
                                                        {item.investment.id == 27 && excludeList.includes(this.getAdminEmail(this.state.club.club_members)) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                        </td>
                                                    </td>
                                                    {(item.investment.club_tag) ?
                                                        <td><Link
                                                            to={`/club/${this.state.club.id}/investment/${item.id}/history`}> <span
                                                            className="fa fa-list"/></Link></td> :
                                                        <td><span className="fa fa-ban"/></td>
                                                    }
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">Wallet</h4>
                                        </div>
                                        {/*<div*/}
                                        {/*    className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">*/}
                                        {/*    {(this.context.user.is_superuser) ? (*/}
                                        {/*        <React.Fragment>*/}
                                        {/*            <button data-toggle="modal" data-target="#fundModal"*/}
                                        {/*                    className="btn btn-orange m-b-5 mr-3">Fund*/}
                                        {/*                Wallet*/}
                                        {/*            </button>*/}
                                        {/*            <button data-toggle="modal" data-target="#withdrawModal"*/}
                                        {/*                    className="btn btn-orange m-b-5 mr-3">Withdrawal*/}
                                        {/*            </button>*/}
                                        {/*            <Link to={`/user/${this.props.match.params.id}/wallet`}*/}
                                        {/*                  className="btn m-b-5 btn-orange"> View All*/}
                                        {/*            </Link>*/}
                                        {/*        </React.Fragment>*/}
                                        {/*    ) : (*/}
                                        {/*        ''*/}
                                        {/*    )}*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-bar-chart"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Amount in user's wallet"
                                                               className="fa fa-info-circle text-right float-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-white"><strong>Current Balance</strong></span>
                                                                <h2 className="text-white mb-0">{Utils.Currency(this.state.wallet.current_balance)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-reply-all"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total amount that has been spent"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Total Amount Spent</strong></span>
                                                                <h2 className="text-white mb-0">{Utils.Currency(this.state.amount_spent)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Reference Number</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Balance</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                            {(this.state.wallet.history !== undefined) ? (this.state.wallet.history.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.ref_code}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.amount)}/>
                                                    </td>
                                                    <td>{(item.status.toLowerCase() === 'credit') ? (
                                                        <span className="badge bg-green m-b-5">Credit</span>
                                                    ) : (item.status.toLowerCase() === 'debit') ? (
                                                        <span className="badge bg-yellow m-b-5">Debit</span>
                                                    ) : (item.status.toLowerCase() === 'processing') ? (
                                                        <span
                                                            className="badge bg-dark m-b-5 text-white">Processing</span>
                                                    ) : (
                                                        <span className="badge badge-danger m-b-5">Failed</span>
                                                    )}
                                                    </td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.balance)}/>
                                                    </td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                    <td><Moment
                                                        format="HH:ss">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))) : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="contributions">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Members' Financial Contributions</h4>
                                    {/*<Link to={`/club/${this.props.match.params.id}/contributions`}*/}
                                    {/*      className="btn m-b-5 btn-orange float-right"> View All*/}
                                    {/*</Link>*/}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa fa-bar-chart"/>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white">Total Contributions</span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(this.state.total_deposits).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Depositor</th>
                                                <th>Amount</th>
                                                <th>Reference</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                            {this.state.contributions.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.user.first_name} {item.user.last_name}</td>
                                                    <td><NumberFormat renderText={value => <div>{value}</div>}
                                                                      prefix={'₦'} displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      value={item.amount}/>
                                                    </td>
                                                    <td>{item.ref_code}</td>
                                                    <td>
                                                        {(item.status.toLowerCase() === 'paid') ? (
                                                            <span
                                                                className="badge bg-green m-b-5">{item.status}</span>
                                                        ) : (item.status.toLowerCase() === 'cancelled') ? (
                                                            <span
                                                                className="badge bg-yellow m-b-5">{item.status}</span>
                                                        ) : (
                                                            <span
                                                                className="badge badge-danger m-b-5">{item.status}</span>
                                                        )}
                                                    </td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="activities">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">Club Member's Activities</h4>
                                    {/*<Link to={`/club/${this.props.match.params.id}/activities`}*/}
                                    {/*      className="btn m-b-5 btn-orange float-right"> View All*/}
                                    {/*</Link>*/}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 text-nowrap">
                                                    <tbody>
                                                    <tr>
                                                        <th>Action</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                    </tr>
                                                    {this.state.activities.map(item => (
                                                        <tr key={uuidv1()}>
                                                            <td>{item.action}</td>
                                                            <td><Moment format="MMM D Y">{item.created_at}</Moment>
                                                            </td>
                                                            <td><Moment format="HH:mm">{item.created_at}</Moment>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="members">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4 className="float-left">Member's Area</h4>
                                        </div>
                                        <div className="col-sm-6">
                                            <button data-toggle="modal" data-target="#uploadModal"
                                                    className="btn btn-primary float-right">Upload members
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Full Name</th>
                                                <th>Role</th>
                                            </tr>
                                            {this.state.members.map(item => (
                                                <tr role="row" key={uuidv1()}>
                                                    <td className="sorting_1">{item.user.first_name} {item.user.last_name}</td>
                                                    <td style={{textTransform: 'capitalize'}}>{item.user_role}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="greenModal" tabIndex="-1" role="dialog"
                             aria-labelledby="greenModalLabel">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="greenModalLabel">Switch to
                                    a <span
                                        className="text-capitalize font-weight-bold">{this.state.switch_option}</span> Club
                                </h5>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to switch this club to a <strong
                                className="text-capitalize">{this.state.switch_option} profile</strong>?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green mr-3"
                                        data-dismiss="modal">Close
                                </button>
                                <button type="button" onClick={event => this.handleSwitch(event)}
                                        className="btn btn-dark">Switch
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="messageModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="example-Modal3">Send Message to Group</h5>
                                <button onClick={event => this.setState({modal: 'none', message: ''})} type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={this.sendGroupMsg}>
                                <div className="modal-body">
                                    <div className="form-group mb-0">
                                        <label htmlFor="message-text"
                                               className="form-control-label">Message:</label>
                                        <textarea onChange={event => this.setState({message: event.target.value})}
                                                  required rows="10" className="form-control"
                                                  id="message-text"></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={event => this.setState({modal: 'none', message: ''})} type="button"
                                            className="btn btn-outline-green btn-sm" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-sm btn-orange">Send message <i
                                        className="fas fa-paper-plane"/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="uploadModal" tabIndex="-1" role="dialog"
                     aria-labelledby="uploadModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="whitelistModalLabel">Add email address</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.addEmail(event)}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>File</label>
                                        <input id="file" required type="file" className="form-control"
                                               name="file"/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-green mr-3"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-dark">Add</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ClubDashboard;
