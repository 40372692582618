import React, {useEffect, useState, useContext} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import API from '../../api';
import {hideLoader, toast, showLoader} from "../../_helpers/Loader";
import Moment from "react-moment";
import {Editor, bold, italic, underline,} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

let options = {
    options: ['inline', 'textAlign', 'fontSize', 'list', 'link', 'emoji', 'colorPicker', 'image', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
    },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    emoji: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
        ],
    },
    image: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: undefined,
        previewImage: false,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: {present: false, mandatory: false},
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
        undo: {className: undefined},
        redo: {className: undefined},
    },
};

const Mails = (props) => {
    const context = useContext(Context);
    const [mails, setMails] = useState([]);
    const [remove_item, setRemove_item] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [stateL, setStateL] = useState({
        subject: '',
        title: '',
        specific_user: '',
        all_user: true,
        exit: false,
        test_email: '',
        id: '',
        page: 1
    });

    useEffect(() => {
        getMails()
        return () => {
            console.log("")
        };
    }, []);

    const clear = (event) => {
        event.preventDefault();
        setStateL({
            editorState: EditorState.createEmpty(),
            id: '',
            subject: '',
            title: '',
            specific_user: '',
            all_user: true,
            thumbnail: '',
        })
    };

    const getMails = async()=> {
        try {
            let res = await API.get('admin/messages/');
            console.log(res.data, "res.data ==>")
            // setMails({...mails, mails: res.data});
        } catch (e) {
            // console.log(e.response);
        }
    }

    const process = async (event, method) => {
        event.preventDefault();
        let postData = {
            subject: stateL.subject,
            title: stateL.title,
            thumbnail: stateL.thumbnail,
            all_user: stateL.all_user,
            specific_user: stateL.specific_user,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        };
        if (postData.body.length < 5) {
            toast.info("Content too small")
        } else {
            showLoader();
            if (method === 'put') {
                try {
                    let res = await API.put('admin/messages/' + stateL.id + '/', postData);
                    await getMails();
                    (stateL.exit === true) && setStateL({...stateL, id: '', page: 1, exit: false});
                    hideLoader()
                } catch (e) {
                    // console.log(e.response);
                    hideLoader();
                }
            } else {
                try {
                    let res = await API.post('admin/messages/', postData);
                    await getMails();
                    (stateL.exit === true) && setStateL({...stateL, id: '', page: 1, exit: false});
                    hideLoader()
                } catch (e) {
                    // console.log(e.response);
                    hideLoader();
                }
            }
        }
    };

    const sendTest = async () => {
        try {
            let res = API.post(`admin/messages/${stateL.id}/send_test_mail/`, {email: stateL.test_email})
        } catch (e) {
            // console.log(e.response)
        }
    };

    const onEditorStateChange = (editorState) => {
        setEditorState({
            ...editorState,
            editorState
        });
    };

    const listPage = () => {
        return (
            <Layout context={context} active_menu={'mail'} title={'Mails'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>Mails</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            <div className='row'>
                                <div className='col-sm-6 float-right text-right pr-0'>
                                    
                                </div>
                                <div className='col-sm-6'>
                                    <button onClick={event => setStateL({...stateL, investment: {}, page: 2})}
                                            className="btn btn-green mr-2">Add New
                                        List
                                    </button>
                                    <button onClick={event => setStateL({...stateL, investment: {}, page: 2})}
                                            className="btn btn-green">Send Email
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Mailing List</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Title
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Recipient
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>No. Sent
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>No. Pending
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {mails.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1"><a onClick={event => {
                                                        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(item.body).contentBlocks)))
                                                        setStateL({
                                                            ...item,
                                                            id: item.id,
                                                            page: 2,
                                                        });
                                                    }} className="pointer">{item.title}</a></td>
                                                    <td className="sorting_1">{item.title}</td>
                                                    <td className="sorting_1">{item.title}</td>
                                                    <td className="sorting_1">{item.title}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog"
                         aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-orange">
                                    <h4 className="modal-title" id="deleteModalLabel">Important Info</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={event => deleteInvestment(event)}>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete <b>{remove_item}</b>.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-green mr-3"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-danger">Delete</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Layout>
        );
    }

    const addPage = () => {
        return (
            <Layout context={context} active_menu={'mail'} title={'Add Mails'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => setStateL({...stateL, page: 1})}><span className="fa fa-chevron-left"/> Mail
                            List</a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Create Mail</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event)}>
                                        <div className="form-group">
                                            <h4>Subject</h4>
                                            <input required value={stateL.subject}
                                                   onChange={event => setStateL({...stateL, subject: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Title</h4>
                                            <input required value={stateL.title}
                                                   onChange={event => setStateL({...stateL, title: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Thumbnail</h4>
                                            <input required value={stateL.thumbnail}
                                                   onChange={event => setStateL({...stateL,thumbnail: event.target.value})}
                                                   className="form-control" type="text"/>
                                            <br/>
                                            <img width="10%" src={stateL.thumbnail} alt=""/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Content</h4>
                                            <Editor
                                                editorState={editorState}
                                                toolbar={options}
                                                initialEditorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={stateL.all_user}
                                                       onChange={event => {
                                                           setStateL({...stateL, all_user: event.target.checked});
                                                           if (event.target.checked) {
                                                               setStateL({...stateL, specific_user: ''})
                                                           }
                                                       }}
                                                       type="checkbox" className="custom-control-input"
                                                       id="all"/>
                                                <label className="custom-control-label" htmlFor="all">All users</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <h4>Specific users</h4>
                                            <textarea disabled={stateL.all_user} value={stateL.specific_user}
                                                      onChange={event => setStateL({...stateL, specific_user: event.target.value.replace(/^\s+|\s+$/gm, '')})}
                                                      className="form-control"></textarea>
                                            <small>Enter email address of users separated by a comma</small>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green mr-3">Save</button>
                                            <button onClick={event => clear(event)}
                                                    className="btn btn-outline-dark">Clear
                                            </button>
                                        </div>
                                        <div className="mb-5"></div>
                                        <textarea style={{width: '100%'}}
                                                  disabled
                                                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    const editPage =() =>{
        return (
            <Layout context={context} active_menu={'mail'} title={'Add Mails'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => setStateL({...stateL, page: 1})}><span className="fa fa-chevron-left"/> Mail
                            List</a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Create Mail</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event, 'put')}>
                                        <div className="form-group">
                                            <h4>Subject</h4>
                                            <input required value={stateL.subject}
                                                   onChange={event => setStateL({...stateL, subject: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Title</h4>
                                            <input required value={stateL.title}
                                                   onChange={event => setStateL({...stateL, title: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Thumbnail</h4>
                                            <input required value={stateL.thumbnail}
                                                   onChange={event => setStateL({...stateL, thumbnail: event.target.value})}
                                                   className="form-control" type="text"/>
                                            <br/>
                                            <img width="10%" src={stateL.thumbnail} alt=""/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Content</h4>
                                            <Editor
                                                editorState={editorState}
                                                toolbar={options}
                                                initialEditorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={stateL.all_user}
                                                       onChange={event => {
                                                           setStateL({...stateL, all_user: event.target.checked});
                                                           if (event.target.checked) {
                                                               setStateL({...stateL, specific_user: ''})
                                                           }
                                                       }}
                                                       type="checkbox" className="custom-control-input"
                                                       id="all"/>
                                                <label className="custom-control-label" htmlFor="all">All users</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <h4>Specific users</h4>
                                            <textarea disabled={stateL.all_user} value={stateL.specific_user}
                                                      onChange={event => setStateL({...stateL, specific_user: event.target.value.replace(/^\s+|\s+$/gm, '')})}
                                                      className="form-control"></textarea>
                                            <small>Enter email address of users separated by a comma</small>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green mr-3">Save</button>
                                            <button onClick={event => clear(event)}
                                                    className="btn btn-outline-dark mr-3">Clear
                                            </button>
                                            <button onClick={event => setStateL({...stateL, exit: true})}
                                                    className="btn btn-orange">Save and Exit
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Test Email</label>
                                            <input onChange={event => setStateL({...stateL, test_email: event.target.value})}
                                                   type="email" className="col-4 form-control mb-3"/>
                                            <button type="button" onClick={event => sendTest(event)}
                                                    className="btn btn-orange">Send test mail
                                            </button>
                                            <br/>
                                            <small>If you made any changes, make sure you save before sending test
                                            </small>
                                        </div>
                                        <div className="mb-5"/>
                                        <textarea style={{width: '100%'}}
                                                  disabled
                                                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }


    if (stateL.page === 1) {
        console.log("heolly there molly")
        return listPage();
    } else if (stateL.page === 2) {
        return editPage();
    } else {
        return addPage();
    }
}

export default Mails;
