import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Moment from "react-moment";

import $ from 'jquery';
import ReactTooltip from "react-tooltip";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";

require('datatables');

class ManageFiles extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.userInput = React.createRef();

        this.state = {
            files: [],
            file_form: {
                receiver_option: "investment",
                users: [],
            },
            users: [],
            investments: [],
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getAdminFiles();
        await this.getInvestments();
        hideLoader();
    }

    getAdminFiles = async () => {
        try {
            const res = await API.get('admin/users/get_user_files/');
            this.setState({files: res.data})
        } catch (e) {
            // console.log(e.response)
        }
    }

    getInvestments = async () => {
        try {
            const res = await API.get('investments/get_simple_investments/')
            this.setState({investments: res.data})
        } catch (e) {

        }
    }

    handleFormAdd = async (event) => {
        event.preventDefault();
        const postData = this.state.file_form;
        showLoader();
        try {
            let res = await API.post('admin/users/upload_user_file/', postData);
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.setState({files: res.data, file_form: {receiver_option: "investment", users: []}})
            toast.success("File uploaded successfully")
            hideLoader();
        } catch (e) {
            toast.error("Something went wrong")
            hideLoader();
        }
    }

    imageUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    file_form: {...this.state.file_form, file: e.target.result}
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    handleAddUser = async (event) => {
        if (this.userInput.current.value !== "") {
            this.setState({
                file_form: {
                    ...this.state.file_form,
                    users: [...this.state.file_form.users, this.userInput.current.value]
                }
            })
            this.userInput.current.value = "";
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'manage-files'} title={'Child Account'}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-sm-6 ml-0 pl-0">
                            <h5>Manage Files</h5>
                        </div>
                        <div className="col-sm-6 mr-0 pr-0">
                            <button data-toggle="modal" data-target="#addModal"
                                    className="float-right btn btn-blue"> Add file
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-blue">
                                    <h4>File Lists</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Name of Investment
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Description
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Document Title
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Number of Response
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Datetime Created
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.files.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td>{(item.investment&&item.investment.title )|| item.filename}</td>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <span className="badge badge-green"><a className="text-white"
                                                                                               href={item.file}>{item.filename}</a></span>
                                                    </td>
                                                    <td>{parseFloat(item.number_of_upload).toLocaleString()}</td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td className="text-center">
                                                        <Link data-tip="View User" to={`/manage-files/${item.id}`}
                                                              className="btn btn-blue btn-sm mt-1 mr-1"><i
                                                            className="fa fa-eye ml-1"/>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.previousPage(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="addModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue">
                                <h5 className="modal-title" id="example-Modal3">Add Files</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.handleFormAdd(event)}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="">Document Title</label>
                                        <input type="text" required className="form-control"
                                               onChange={event => this.setState({
                                                   file_form: {
                                                       ...this.state.file_form,
                                                       filename: event.target.value
                                                   }
                                               })}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Description</label>
                                        <textarea rows="5" required className="form-control"
                                                  onChange={event => this.setState({
                                                      file_form: {
                                                          ...this.state.file_form,
                                                          description: event.target.value
                                                      }
                                                  })}/>
                                    </div>
                                    <div className="form-group">
                                        <label>File</label>
                                        <input required id="get-image"
                                               onChange={event => this.imageUpload(event)}
                                               type="file"
                                               className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="isNotDownloadable" className="mr-3">File Not Downloadable?</label>
                                        <input required id="isNotDownloadable"
                                            onChange={e => this.setState({
                                                file_form: {
                                                    ...this.state.file_form,
                                                    is_not_downloadable: e.target.checked
                                                }
                                            })}
                                            type="checkbox"
                                            className="form"
                                            checked={ this.state.file_form.is_not_downloadable == true}
                                            />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Receiver option</label> <br/>
                                        <label htmlFor="investment" className="mr-3">
                                            <input onChange={event => this.setState({
                                                file_form: {
                                                    ...this.state.file_form,
                                                    receiver_option: event.target.value
                                                }
                                            })} checked={(this.state.file_form.receiver_option === "investment")}
                                                   type="radio"
                                                   id="investment" name="option"
                                                   value="investment"/> Investment
                                        </label>

                                        <label htmlFor="user">
                                            <input onChange={event => this.setState({
                                                file_form: {
                                                    ...this.state.file_form,
                                                    receiver_option: event.target.value
                                                }
                                            })} checked={(this.state.file_form.receiver_option === "user")} type="radio"
                                                   id="user"
                                                   name="option" value="user"/> Users
                                        </label>
                                    </div>
                                    {(this.state.file_form.receiver_option === 'investment') ? (
                                        <div className="form-group">
                                            <label>Investment</label>
                                            <select onChange={event => this.setState({
                                                file_form: {
                                                    ...this.state.file_form,
                                                    investment: event.target.value
                                                }
                                            })} className="form-control" name="" id="">
                                                <option value="">--- Select an Option ---</option>
                                                {this.state.investments.map(item => (
                                                    <option value={item.id}>{item.title} {item.company}</option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className="form-group">
                                                <label>Investment</label>
                                                <select onChange={event => this.setState({
                                                    file_form: {
                                                        ...this.state.file_form,
                                                        investment: event.target.value
                                                    }
                                                })} className="form-control" name="" id="">
                                                    <option value="">--- Select an Option ---</option>
                                                    <option value={null}>Other</option>
                                                    {this.state.investments.map(item => (
                                                        <option value={item.id}>{item.title} {item.company}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group mb-3">
                                                    <input ref={this.userInput} type="email" className="form-control"
                                                           placeholder="Recipient's email"
                                                           aria-describedby="basic-addon2"/>
                                                    <div className="input-group-addon pointer"
                                                         onClick={event => this.handleAddUser(event)}><span
                                                        className="fa fa-plus"/></div>
                                                </div>
                                            </div>
                                            <div id="example_wrapper"
                                                 className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <table
                                                    className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                    role="grid" aria-describedby="example_info">
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                                            aria-controls="example" rowSpan="1" colSpan="1">Email
                                                        </th>
                                                        <th className="wd-15p sorting" tabIndex="0"
                                                            aria-controls="example"
                                                            rowSpan="1" colSpan="1">Action
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.file_form.users.map(item => (
                                                        <tr role="row" key={item}>
                                                            <td>{item}
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={event => this.setState({
                                                                    file_form: {
                                                                        ...this.state.file_form,
                                                                        users: this.state.file_form.users.filter(option => option !== item)
                                                                    }
                                                                })} className="btn btn-danger btn-sm mt-1 mr-1">
                                                                    <i className="fa fa-minus"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </React.Fragment>
                                    )}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit"
                                            className="btn btn-green">Upload
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(ManageFiles);
