import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip'
import $ from 'jquery'

require('malihu-custom-scrollbar-plugin');

class Layout extends React.Component {
    constructor(props) {
        super(props);

    }


    componentWillMount() {
        if (this.props.title) {
            let title = this.props.title;
            document.title = `Twelvevest Admin | ${title.charAt(0).toUpperCase() + title.slice(1)}`
        } else {
            document.title = `Twelvevest Admin`;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.title) {
            let title = this.props.title;
            document.title = `Twelvevest Admin | ${title.charAt(0).toUpperCase() + title.slice(1)}`
        } else {
            document.title = `Twelvevest Admin`;
        }
        $(".app-sidebar").mCustomScrollbar({
            theme: "minimal",
            autoHideScrollbar: true,
            scrollbarPosition: "outside"
        });
    }

    render() {
        const date_format = {month: 'short', year: 'numeric', day: 'numeric'};
        const date = new Date();
        const {pathname} = this.props.location;
        const pathName = pathname.replace('/', '');

        return (
            <div>
                <div id="app">
                    <ReactTooltip/>
                    <div className="main-wrapper">
                        <nav className="navbar navbar-expand-lg main-navbar">
                            <a className="header-brand">
                                <img src="/assets/img/logo.png" className="header-brand-img"/>
                            </a>

                            <div className="form-inline mr-auto"/>

                            <a
                                id="collapse-menu"
                                href="#"
                                data-toggle="sidebar"
                                className="nav-link nav-link-lg"
                            >
                                <i className="ion ion-navicon-round text-logo"/>
                            </a>

                            <ul className="navbar-nav navbar-right">
                                <li className="mr-5 mytime">
                                    <div className="nav-link notification-toggle nav-link-lg">
                                        <i className="fa fa-clock text-white"/>
                                        <strong>
                                            <Moment className="text-default" format="MMM D, Y">
                                                {date}
                                            </Moment>
                                        </strong>
                                    </div>
                                </li>
                                <li className="ml-5 mylogout">
                                    <Link
                                        to="/login"
                                        className="nav-link nav-link-lg full-screen-link"
                                    >
                                        <i
                                            className="fa fa-sign-out-alt text-default"
                                            id="fullscreen-button"
                                        />
                                        <strong className="text-primary text-default"> Logout</strong>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <aside className="app-sidebar mCustomScrollbar _mCS_1 mCS-autoHide side">
                            <div className="app-sidebar__user">
                                <div className="dropdown">
                                    <div className="nav-link pl-2 pr-2 leading-none d-flex">
                                        <a>
                                            <img
                                                alt="profile image"
                                                src={
                                                    this.props.context.user.profile_photo
                                                        ? this.props.context.user.profile_photo
                                                        : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'
                                                }
                                                className=" avatar-md rounded-circle"
                                            />
                                        </a>
                                        <span className="ml-2 d-lg-block">
                    <span className="app-sidebar__user-name mt-5"
                          data-tip={`${this.props.context.user.first_name} ${this.props.context.user.last_name}`}>
                      {this.props.context.user.first_name}{' '}
                        {this.props.context.user.last_name}
                    </span>
                    <br/>
                  </span>
                                    </div>
                                </div>
                            </div>

                            <ul className="side-menu">
                                <li className={`slide ${(this.props.active_menu === 'overview' || this.props.active_menu === 'twelve-overview' || this.props.active_menu === 'twelve-black-overview') ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-eye"/><span
                                        className="side-menu__label">Overview</span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        <li>
                                            <Link to="/overview"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'overview') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-users"/><span
                                                className="side-menu__label">TGIC Users</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/real-state-overview"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'twelve-overview') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-users disabled"/><span
                                                className="side-menu__label">Public Users</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/twelve-black-overview"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'twelve-black-overview') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-users disabled"/><span
                                                className="side-menu__label">TB Users</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/fwp-overview"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'fwp-overview') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-users disabled"/><span
                                                className="side-menu__label">FWP Users</span></Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={`slide ${(this.props.active_menu === 'user' || this.props.active_menu === 'subinvestor' || this.props.active_menu === 'whitelist' ) ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-users-cog"/><span
                                        className="side-menu__label">Accounts</span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        <li>
                                            <Link to="/users"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'user') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-users"/><span
                                                className="side-menu__label">Users</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/child-accounts"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'subinvestor') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-child"/><span
                                                className="side-menu__label">Child Account</span></Link>
                                        </li>
                                        {this.props.context.user.is_superuser ? (
                                            <React.Fragment>
                                                {/* <li>
                                                    <Link to="/manage-files"
                                                          className={`slide-item toggle_menu ${(this.props.active_menu === 'manage-files') ? 'active' : ''}`}><i
                                                        className="side-menu__icon fa fa fa-files-o"/><span
                                                        className="side-menu__label">Manage Files</span></Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/whitelist"
                                                          className={`slide-item toggle_menu ${(this.props.active_menu === 'whitelist') ? 'active' : ''}`}><i
                                                        className="side-menu__icon fa fa fa-list"/><span
                                                        className="side-menu__label">Whitelist</span></Link>
                                                </li>
                                                <li>
                                                    <Link to="/accreditation"
                                                          className={`slide-item toggle_menu ${(this.props.active_menu === 'accreditation') ? 'active' : ''}`}><i
                                                        className="side-menu__icon fa fa fa-list"/><span
                                                        className="side-menu__label">Accreditation</span></Link>
                                                </li>
                                                <li>
                                                    <Link to="/identification"
                                                          className={`slide-item toggle_menu ${(this.props.active_menu === 'identification') ? 'active' : ''}`}><i
                                                        className="side-menu__icon fa fa fa-list"/><span
                                                        className="side-menu__label">Identification</span></Link>
                                                </li>
                                            </React.Fragment>) : ''}
                                    </ul>
                                </li>
                                <li className={`slide ${(this.props.active_menu === 'wallet' || this.props.active_menu === 'otp') ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-wallet"/><span
                                        className="side-menu__label">Wallet</span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        <li>
                                            <Link to="/wallet"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'wallet') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-wallet"/><span
                                                className="side-menu__label">Wallet</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/otp"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'otp') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-phone"/><span
                                                className="side-menu__label">OTP</span></Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/wallet-withdrawal-requests"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'wallet-withdrawal-wallet') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-wallet"/><span
                                                className="side-menu__label">W.W. Requests</span></Link>
                                        </li> */}
                                    </ul>
                                </li>
                                {this.props.context.user.is_superuser ? (
                                    <li>
                                        <Link
                                            to="/partners"
                                            className={`side-menu__item toggle_menu ${
                                                this.props.active_menu === 'partner' ? 'active' : ''
                                            }`}
                                        >
                                            <i className="side-menu__icon fa fa-handshake"></i>
                                            <span className="side-menu__label">Partners</span>
                                        </Link>
                                    </li>
                                ) : ''}
                                <li>
                                    <Link
                                        to="/clubs"
                                        className={`side-menu__item toggle_menu ${
                                            this.props.active_menu === 'club' ? 'active' : ''
                                        }`}
                                    >
                                        <i className="side-menu__icon fa fa-user-friends"></i>
                                        <span className="side-menu__label">Clubs</span>
                                    </Link>
                                </li>
                                <li className={`slide ${(this.props.active_menu === 'investment' ||this.props.active_menu === 'manage-files') ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-users-cog"/><span
                                        className="side-menu__label">Investments</span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        <li>
                                            <Link to="/investments"
                                                className={`slide-item toggle_menu ${
                                                    this.props.active_menu === 'investment' ? 'active' : ''
                                                }`}><i className="side-menu__icon fa fa-piggy-bank"></i>
                                                    <span className="side-menu__label">Investment Lists</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/manage-files"
                                                    className={`slide-item toggle_menu ${(this.props.active_menu === 'manage-files') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa fa-files-o"/><span
                                                className="side-menu__label">Manage Files</span></Link>
                                        </li>
                                    </ul>
                                </li>
                                {this.props.context.user.is_superuser ? (
                                    <li>
                                        <Link
                                            to="/packages"
                                            className={`side-menu__item toggle_menu ${
                                                this.props.active_menu === 'package' ? 'active' : ''
                                            }`}
                                        >
                                            <i className="side-menu__icon fa fa-layer-group"></i>
                                            <span className="side-menu__label">Packages</span>
                                        </Link>
                                    </li>) : ''}
                                <li className={`slide ${(this.props.active_menu === 'mail' || this.props.active_menu === 'media' || this.props.active_menu === 'send-mail' || this.props.active_menu === 'recipient') ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-table"/><span
                                        className="side-menu__label">Mails</span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        {/* <li>
                                            <Link to="/mails"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'mail') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-mail-bulk"/><span
                                                className="side-menu__label">Mail List</span></Link>
                                        </li> */}
                                        <li>
                                            <Link to="/send-mails"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'send-mail') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-mail-bulk"/><span
                                                className="side-menu__label">Send Mail</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/recipients"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'recipient') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-mail-bulk"/><span
                                                className="side-menu__label">Recipients</span></Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/media"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'media') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-image"/><span
                                                className="side-menu__label">Media Files</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/quarter-mail-beta"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'media') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-area-chart"/><span
                                                className="side-menu__label">Quarter Mails</span></Link>
                                        </li> */}
                                    </ul>
                                </li>
                                <li className={`slide ${(this.props.active_menu === 'resources' || this.props.active_menu === 'resource') ? 'is-expanded' : ''}`}>
                                    <a className={`side-menu__item`}
                                       data-toggle="slide" href="#"><i
                                        className="side-menu__icon fa fa-bookmark"/><span
                                        className="side-menu__label">Resources</span><i
                                        className="angle fa fa-angle-right"/></a>
                                    <ul className="slide-menu">
                                        <li>
                                            <Link to="/resources"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'resource') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-bookmark"/><span
                                                className="side-menu__label">Manage Resources</span></Link>
                                        </li>
                                    </ul>
                                </li>
                                {this.props.context.user.is_superuser ? (
                                    <li className={`slide ${(this.props.active_menu === 'settings' || this.props.active_menu === 'settings') ? 'is-expanded' : ''}`}>
                                        <a className={`side-menu__item`}
                                           data-toggle="slide" href="#"><i
                                            className="side-menu__icon fa fa-cogs"/><span
                                            className="side-menu__label">Settings</span><i
                                            className="angle fa fa-angle-right"/></a>
                                        <ul className="slide-menu">
                                            <li>
                                                <Link to="/payment-methods"
                                                      className={`slide-item toggle_menu ${(this.props.active_menu === 'settings') ? 'active' : ''}`}><i
                                                    className="side-menu__icon fa fa-mail-bulk"/><span
                                                    className="side-menu__label">Currency Settings</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/roles-and-permission"
                                                      className={`slide-item toggle_menu ${(this.props.active_menu === 'roles-and-permission') ? 'active' : ''}`}><i
                                                    className="side-menu__icon fa fa-user-secret"/><span
                                                    className="side-menu__label">Roles & Permission</span></Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/wallet-withdrawal-settings"
                                                      className={`slide-item toggle_menu ${(this.props.active_menu === 'withdrawal-settings') ? 'active' : ''}`}><i
                                                    className="side-menu__icon fa fa-wallet"/><span
                                                    className="side-menu__label">Withdrawal Settings</span></Link>
                                            </li> */}
                                            {/* <li>
                                            <Link to="/media"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'media') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-image"/><span
                                                className="side-menu__label">Media Files</span></Link>
                                        </li>
                                        <li>
                                            <Link to="/quarter-mail-beta"
                                                  className={`slide-item toggle_menu ${(this.props.active_menu === 'media') ? 'active' : ''}`}><i
                                                className="side-menu__icon fa fa-area-chart"/><span
                                                className="side-menu__label">Quarter Mails</span></Link>
                                        </li> */}
                                        </ul>
                                    </li>) : ''}
                                <li>
                                    <Link to="/login" className="side-menu__item">
                                        <i className="side-menu__icon fa fa-sign-out-alt"/>
                                        <span className="side-menu__label">Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </aside>

                        <div className="app-content">{this.props.children}</div>

                        <footer className="main-footer">
                            <div className="text-center">Copyright &copy;Twelvevest 2020.</div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Layout);
