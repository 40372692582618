import React, {useState, useEffect} from 'react';

import {Modal, Accordion, Button} from 'react-bootstrap';


const UserSettings = (props) => {

  const formatResponse = (value) => {
    try {
      if (props.user&&props.user[value]){
        return props.user&&props.user[value];
      }
      return "N/A";
    } catch {
      return "N/A"
    }
  }

  return (<>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.user.first_name} {' '} {props.user.middle_name} {' '} {props.user.last_name} Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.user&&Object.keys(props.user).map(item => {
            if (!["otp", "otp_expiry", "id", "is_premium", "clubs", "user_subinvestor", "accounts", "completed_onboarding", "completed_first_investment", "is_superuser", "is_staff", "is_axamansard", "gbp", "display_gbp", "usd", "display_usd", "qualification_cost"].includes(item)){
              return (
                <p>{`${item.split("_").join(" ").toUpperCase()}: ${formatResponse(item)}`}</p>
              )
            }
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  </>)
}

export default UserSettings;
