import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from '../Layout';
import API from '../../api';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";
import $ from 'jquery';

class Packages extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            plans: [],
            plan: {},
            page: 1
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getPlans();
        hideLoader();
    }

    getPlans = async () => {
        try {
            let res = await API.get('plans/');
            this.setState({plans: res.data})
        } catch (e) {
        }
    };


    updatePlanPage = async (id) => {
        let get_plan = this.state.plans.filter(item => item.id === id)[0];
        this.setState({plan: get_plan, page: 2});
    };

    updatePlan = async (event) => {
        event.preventDefault();
        let postData = this.state.plan;
        showLoader();
        try {
            let res = await API.put('admin/subscription_packages/' + this.state.plan.id + '/', postData);
            this.getPlans();
            this.setState({page: 1, plan: {}});
            toast.success("Plan successfully updated");
            hideLoader();
        } catch (e) {
            // console.log(e.response);
            hideLoader();
        }
    };

    addPlanPage = async () => {
        let postData = {
            free_trial: false,
            access_imp: false,
            inbox_tool: false,
            recurring_debit_members: false,
            club_portfolio_management: false,
            club_incorporation: false,
        };
        this.setState({plan: postData, page: 3})
    };

    addPlan = async (event) => {
        event.preventDefault();
        let postData = this.state.plan;
        showLoader();
        try {
            let res = await API.post('admin/subscription_packages/', postData);
            this.getPlans();
            this.setState({page: 1, plan: {}});
            toast.success("Plan successfully added");
            hideLoader();
        } catch (e) {
            // console.log(e.response)
        }
    };

    listPage() {
        return (
            <Layout active_menu={'package'} context={this.context} title={'Packages'}>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6 pl-0">
                            <a> Packages</a>
                        </div>
                        <div className="col-sm-6 float-right text-right">

                            <button onClick={event => this.addPlanPage()} className="btn btn-dark"><span
                                className="fa fa-plus"/> Add Plan
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="text-center mb-4">Select a payment plan</h3>
                                    <div className="row">
                                        {this.state.plans.map(item => (
                                            <div className="col-sm-3">
                                                <div className="list-group">
                                                    <div href="#"
                                                         className="list-group-item list-group-item-action flex-column align-items-start bg-blue-gray text-blue">
                                                        <div className="d-sm-flex w-100 text-center bg">
                                                            <h4 className="mb-1">{item.name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-group mb-3">
                                                    <li className="list-group-item">{item.min_members} - {item.max_members} Members</li>
                                                    {(item.access_imp) ? (
                                                        <li className="list-group-item">Access to Investment
                                                            Marketplace</li>) : ''}
                                                    {(item.inbox_tool) ? (
                                                        <li className="list-group-item">Inbox Tool</li>) : ''}
                                                    {(item.club_portfolio_management) ? (
                                                        <li className="list-group-item">Club Portfolio
                                                            Management</li>) : ''}
                                                    {(item.club_incorporation) ? (
                                                        <li className="list-group-item">Club Incorporation</li>) : ''}
                                                    {(item).recurring_debit_members ? (
                                                        <li className="list-group-item">Recurring Debit for members</li>
                                                    ) : ''}
                                                    <li className="list-group-item">${item.monthly_amount} / Monthly
                                                    </li>
                                                    <li className="list-group-item">${item.annual_amount} / Yearly</li>
                                                </ul>
                                                <button onClick={event => this.updatePlanPage(item.id)}
                                                        className="btn btn-green btn-block"><strong>Update</strong>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    editPage() {
        return (
            <Layout active_menu={'package'} context={this.context} title={this.state.plan.name}>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6 pl-0">
                            <a onClick={event => this.setState({page: 1})}><span
                                className="fa fa-chevron-left"/> Packages</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Edit Plan</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.updatePlan(event)}>
                                        <div className="form-group">
                                            <label>Package Name</label>
                                            <input required className="form-control" value={this.state.plan.name}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           name: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Monthly Amount</label>
                                            <NumberFormat required className="form-control"
                                                          value={this.state.plan.monthly_amount}
                                                          thousandSeparator={true}
                                                          prefix={'$'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({
                                                    plan: {
                                                        ...this.state.plan,
                                                        monthly_amount: value
                                                    }
                                                })
                                            }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Annual Amount</label>
                                            <NumberFormat required className="form-control"
                                                          value={this.state.plan.annual_amount}
                                                          thousandSeparator={true}
                                                          prefix={'$'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({
                                                    plan: {
                                                        ...this.state.plan,
                                                        annual_amount: value
                                                    }
                                                })
                                            }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Minimum Members</label>
                                            <input required min="1" type="number" className="form-control"
                                                   value={this.state.plan.min_members}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           min_members: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Maximum Members</label>
                                            <input required min="1" type="number" className="form-control"
                                                   value={this.state.plan.max_members}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           max_members: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Free Trial (Month)</label>
                                            <input required min="1" type="number" className="form-control"
                                                   value={this.state.plan.free_trial}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           free_trial: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.access_imp}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               access_imp: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="market"/>
                                                <label className="custom-control-label" htmlFor="market">Access to
                                                    Investment Marketplace</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.inbox_tool}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               inbox_tool: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="inbox"/>
                                                <label className="custom-control-label" htmlFor="inbox">Inbox
                                                    Tool</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.recurring_debit_members}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               recurring_debit_members: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="recurring"/>
                                                <label className="custom-control-label" htmlFor="recurring">Recurring
                                                    Debit for members</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    defaultChecked={this.state.plan.club_portfolio_management}
                                                    onChange={event => this.setState({
                                                        plan: {
                                                            ...this.state.plan,
                                                            club_portfolio_management: event.target.checked
                                                        }
                                                    })}
                                                    type="checkbox" className="custom-control-input"
                                                    id="management"/>
                                                <label className="custom-control-label" htmlFor="management">Portfolio
                                                    Club Management</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.club_incorporation}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               club_incorporation: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="incorporation"/>
                                                <label className="custom-control-label" htmlFor="incorporation">Club
                                                    Incorporation</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-green btn-block">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    addPage() {
        return (
            <Layout active_menu={'package'} context={this.context} title={'Create New Plan'}>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6 pl-0">
                            <a onClick={event => this.setState({page: 1})}><span
                                className="fa fa-chevron-left"/> Packages</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Edit Plan</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.addPlan(event)}>
                                        <div className="form-group">
                                            <label>Package Name</label>
                                            <input required className="form-control" value={this.state.plan.name}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           name: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Monthly Amount</label>
                                            <NumberFormat required className="form-control"
                                                          value={this.state.plan.monthly_amount}
                                                          thousandSeparator={true}
                                                          prefix={'$'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({
                                                    plan: {
                                                        ...this.state.plan,
                                                        monthly_amount: value
                                                    }
                                                })
                                            }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Annual Amount</label>
                                            <NumberFormat required className="form-control"
                                                          value={this.state.plan.annual_amount}
                                                          thousandSeparator={true}
                                                          prefix={'$'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({
                                                    plan: {
                                                        ...this.state.plan,
                                                        annual_amount: value
                                                    }
                                                })
                                            }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Minimum Members</label>
                                            <input required min="1" type="number" className="form-control"
                                                   value={this.state.plan.min_members}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           min_members: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Maximum Members</label>
                                            <input required min="1" type="number" className="form-control"
                                                   value={this.state.plan.max_members}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           max_members: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Free Trial (Month)</label>
                                            <input required min="1" type="number" className="form-control"
                                                   value={this.state.plan.free_trial}
                                                   onChange={event => this.setState({
                                                       plan: {
                                                           ...this.state.plan,
                                                           free_trial: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.access_imp}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               access_imp: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="market"/>
                                                <label className="custom-control-label" htmlFor="market">Access to
                                                    Investment Marketplace</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.inbox_tool}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               inbox_tool: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="inbox"/>
                                                <label className="custom-control-label" htmlFor="inbox">Inbox
                                                    Tool</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.plan.recurring_debit_members}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               recurring_debit_members: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="recurring"/>
                                                <label className="custom-control-label" htmlFor="recurring">Recurring
                                                    Debit for members</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    defaultChecked={this.state.plan.club_portfolio_management}
                                                    onChange={event => this.setState({
                                                        plan: {
                                                            ...this.state.plan,
                                                            club_portfolio_management: event.target.checked
                                                        }
                                                    })}
                                                    type="checkbox" className="custom-control-input"
                                                    id="management"/>
                                                <label className="custom-control-label" htmlFor="management">Portfolio
                                                    Club Management</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input onLoad={this.se}
                                                       defaultChecked={this.state.plan.club_incorporation}
                                                       onChange={event => this.setState({
                                                           plan: {
                                                               ...this.state.plan,
                                                               club_incorporation: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox" className="custom-control-input"
                                                       id="incorporation"/>
                                                <label className="custom-control-label" htmlFor="incorporation">Club
                                                    Incorporation</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-green btn-block">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.listPage();
        } else if (this.state.page === 2) {
            return this.editPage();
        } else return this.addPage();
    }
}

export default Packages;
