import React, {Component} from 'react';
import Layout from '../Layout';
import {Context} from "../MyContext";
import {showLoader, hideLoader, toastr} from "../../_helpers/utils";
import API from '../../api';
import $ from 'jquery';

class Media extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getFiles();
        hideLoader()
    }

    async getFiles() {
        showLoader();
        try {
            let res = await API.get('admin/messages/get_media_files/');
            this.setState({files: res.data})
        } catch (e) {
            hideLoader();
        }
    }

    uploadImage = async (event) => {
        event.preventDefault();
        const postData = new FormData();
        postData.append('file', $($('#img-upload'))[0].files[0]);
        showLoader();
        try {
            let res = await API.post('admin/messages/upload_image/', postData, {headers: {'Content-Type': 'multipart/form-data'}});
            hideLoader();
            toastr.success("Image Successfully Uploaded");
        } catch (e) {
            hideLoader();
            // console.log(e.response);
        }
    };

    render() {
        return (
            <Layout context={this.context} active_menu={'media'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>Media Files</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            <button data-toggle='modal' data-target="#uploadModal" className="btn btn-green">Add New
                                Image
                            </button>
                        </div>
                    </div>

                    <div className="section-body card-columns gallery">
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.files.map(item => (
                                    <div className="galleryItem card">
                                        <img className="card-img-top" src={`http://res.cloudinary.com/py/${item.image}`}
                                             alt="gallery-img"
                                             data-text={`http://res.cloudinary.com/py/${item.image}`}/>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="uploadModal" tabIndex="-1" role="dialog"
                     aria-labelledby="uploadModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="uploadModalLabel">Upload Image File</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.uploadImage(event)} action="">
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="file">Upload Image</label>
                                        <input required accept="image/*" id="img-upload" type="file"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-success">Upload</button>
                                    <button type="button" className="btn btn-outline-dark" data-dismiss="modal">Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </Layout>
        );
    }
}

export default Media;