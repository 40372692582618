import React, {useContext, useState, useEffect} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import API from '../../api';
import {hideLoader, toast, showLoader} from "../../_helpers/Loader";
import Moment from "react-moment";
import {Editor, bold, italic, underline,} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Resources = (props) => {
    const [resources, setResources] = useState([]);
    const [remove_item, setRemoveItem] = useState("");
    const [page, setPage] = useState(1);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [resourceObj, setResourceObj] = useState({
        title: "",
        id: "",
        image: "",
        excerpt: "",
        body: "",
        tags: "",
        category: "",
        status: "PUBLISHED"
    });
    const [singleObj, setSingleObj] = useState({
        
    })

    const [catType, setCatType] = useState(null);
    const [categories, setCategories] = useState([])

    const context = useContext(Context);


    useEffect(() => {
        getResources();
        getCategories();
        return () => {
            console.log("")
        };
    }, []);

    const clear = (event) => {
        event.preventDefault();
        setResourceObj({
            id: '',
            tags: '',
            title: '',
            excerpt: '',
            status: '',
            tags: "",
            category: "",
        })
        setEditorState(EditorState.createEmpty())
    };

    const getResources = async() => {
        showLoader()
        try {
            let res = await API.get('admin/resources/');
            setResources(res.data);
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const getCategories = async() => {
        showLoader()
        try {
            let res = await API.get('admin/categories/');
            setCategories(res.data);
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const process = async (event, method) => {
        event.preventDefault();
        let postData = {
            user: context.user.id,
            tags: resourceObj.tags,
            title: resourceObj.title,
            image: resourceObj.image,
            status: resourceObj.status,
            excerpt: resourceObj.excerpt,
            category: resourceObj.category,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };
        console.log(postData, "postData>>>")
        if (postData.category == ""){
            console.log("postData.category", postData.category)
            toast.info("You have to choose a category")
            return;
        }
        if (postData.body.length < 5) {
            toast.info("Content too small")
        } else {
            showLoader();
            if (method === 'put') {
                try {
                    console.log(resourceObj, "===>")
                    let res = await API.put('admin/resources/' + resourceObj.id + '/', postData);
                    await getResources();
                    setPage(1)
                    setResourceObj({
                        ...resourceObj,
                        id: ''
                    })
                    getResources();
                    toast.success("Operation successful")
                    hideLoader()
                } catch (e) {
                    // console.log(e.response);
                    hideLoader();
                }
            } else {
                try {
                    let res = await API.post('admin/resources/', postData);
                    // await getResources();
                    console.log(resourceObj, "postData called")
                    setPage(1)
                    setResourceObj({
                        ...resourceObj,
                        id: ''
                    })
                    getResources();
                    toast.success("Operation successful")
                    hideLoader()
                } catch (e) {
                    // console.log(e.response);
                    hideLoader();
                }
            }
        }
    };

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };

    const getImageUrl = async (event) => {
        event.preventDefault()
        const res = await API.uploadFile("users/uploads/", event.target.files[0]);
        console.log(resourceObj, res.data.secure_url)
        setResourceObj({
            ...resourceObj,
            image: res.data.secure_url
        })
    }

    const uploadCallback = (file, callback) => {
        console.log(file);
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          console.log(reader);
          reader.onloadend = async () => {
            const res = await API.uploadFile("users/uploads/", file);
            console.log(res)
            // setValue("thumbnail", res.data.secure_url);
            resolve({ data: { link: res.data.secure_url } });
          };
          reader.readAsDataURL(file);
        });
    };

    let options = {
        options: ['inline', 'textAlign', 'fontSize', 'list', 'link', 'emoji', 'colorPicker', 'image', 'history'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
        },
        list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
        },
        colorPicker: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
        },
        textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            linkCallback: undefined
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        emoji: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            emojis: [
                '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                '✅', '❎', '💯',
            ],
        },
        image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: undefined,
            previewImage: false,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: {present: false, mandatory: false},
            defaultSize: {
                height: 'auto',
                width: 'auto',
            },
            uploadCallback: uploadCallback
        },
        history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['undo', 'redo'],
            undo: {className: undefined},
            redo: {className: undefined},
        },
    };

    const listPage = () => {
        return (
            <Layout context={context} active_menu={'resources'} title={'Resources'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>Resources</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            <button onClick={event => setPage(3)}
                                    className="btn btn-green">Add New
                                Resource
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Resources</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Title
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Excerpt
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {resources.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1">{item.title}</td>
                                                    <td className="sorting_1">{(item.excerpt).substring(0, 40)}...</td>
                                                    <td className="sorting_1">{item.status}</td>
                                                    <td>
                                                        <a onClick={event => {
                                                            setResourceObj({...item, id: item.id})
                                                            setPage(2)
                                                            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(item.body).contentBlocks)))

                                                        }} className="pointer btn btn-orange btn-sm mt-1 mr-1"><i className="fa fa-edit ml-1"/></a>
                                                        <a onClick={event => {
                                                            setResourceObj({...item, id: item.id})
                                                            setPage(4)
                                                        }} className="pointer btn btn-primary btn-sm mt-1 mr-1"><i className="fa fa-eye ml-1"/></a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog"
                         aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-orange">
                                    <h4 className="modal-title" id="deleteModalLabel">Important Info</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <form onSubmit={event => console.log("deleted this item")}>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete <b>{remove_item.title}</b>.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-green mr-3"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="submit" className="btn btn-danger">Delete</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    const addPage = () =>{
        return (
            <Layout context={context} active_menu={'resources'} title={'Add Resources'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => setPage({page: 1})}><span className="fa fa-chevron-left"/> Resources
                            </a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Create Resource</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event)}>
                                        <div className="form-group">
                                            <h4>Category  <small>(<i className="text-danger">Select or Add Category</i>)</small></h4>
                                            <select value={resourceObj?.category?.title}
                                                   onChange={event => {
                                                    if (event.target.value == "Add New Category"){
                                                        setCatType(event.target.value)
                                                    } else {
                                                        setResourceObj({...resourceObj, category: event.target.value})
                                                    }
                                                }} className="form-control" hidden={catType ? true: false}>
                                                    <option value="">Select or Add New Category</option>
                                                    {categories.length && categories.map(item => {
                                                        return (<option value={item?.title}>{item?.title}</option>)
                                                    })}
                                                    <option value="Add New Category">Add New Category</option>
                                            </select>
                                            
                                            {catType ? (<input name="category" value={resourceObj?.category?.title}
                                                   onChange={event => setResourceObj({...resourceObj, category: event.target.value})}
                                                   className="form-control" type="text" />) : ""}                                            
                                        </div>
                                        <div className="form-group">
                                            <h4>Title  <small>(<i className="text-danger">this is the title of the write-up</i>)</small></h4>
                                            <input maxLength={200} required value={resourceObj.title}
                                                   onChange={event => setResourceObj({...resourceObj, title: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Thumbnail <small><i  className="text-danger">(This is the display image of the article)</i></small></h4>
                                            <input onChange={event => getImageUrl(event)}
                                                   className="form-control" type="file"/>
                                            <br/>
                                            <img width="10%" src={resourceObj.image} alt=""/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Content</h4>
                                            <Editor
                                                editorState={editorState}
                                                toolbar={options}
                                                initialEditorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                            <small><i  className="text-danger">Body of the article should be here. images embed contents like video etc alike.</i></small>
                                        </div>
                                        <div className="form-group">
                                            <h4>Excerpt</h4>
                                            <textarea maxLength={100} required value={resourceObj.excerpt}
                                                   onChange={event => setResourceObj({...resourceObj, excerpt: event.target.value})}
                                                   className="form-control" type="text"></textarea>
                                            <small><i  className="text-danger">More like a fragement of the intro summarized</i></small>
                                        </div>
                                        <div className="form-group">
                                            <h4>Tags (optional)</h4>
                                            <input maxLength={200} value={resourceObj.tags}
                                                   onChange={event => setResourceObj({...resourceObj, tags: event.target.value})}
                                                   className="form-control" type="text"/>
                                                   <small><i  className="text-danger">Add tags separated by comma e.g profile, investment</i></small>
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" onChange={e => setResourceObj({
                                                ...resourceObj,
                                                status: e.target.value
                                            })} >
                                                <option value="PUBLISHED" selected={ resourceObj.status == "PUBLISHED" ? true : false }>Publish</option>
                                                <option value="DRAFT" selected={resourceObj.status == "DRAFT" ? true : false }>Draft</option>
                                            </select>
                                            <small><i  className="text-danger">Draft status means the users wont see the article until status changes to publish</i></small>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-green mr-3">Submit</button>
                                        </div>
                                        <div className="mb-5"></div>
                                        <textarea style={{width: '100%'}}
                                                  disabled
                                                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    const editPage = () =>{
        return (
            <Layout context={context} active_menu={'resource'} title={'Add Resources'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => setPage({page: 1})}><span className="fa fa-chevron-left"/> Resources
                            </a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Edit Resource</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event, 'put')}>
                                        <div className="form-group">
                                            <h4>Category  <small>(<i className="text-danger">Select or Add Category</i>)</small></h4>
                                            <select value={resourceObj?.category?.title}
                                                   onChange={event => {
                                                    if (event.target.value == "Add New Category"){
                                                        setCatType(event.target.value)
                                                    } else {
                                                        setResourceObj({...resourceObj, category: event.target.value})
                                                    }
                                                }} className="form-control" hidden={catType ? true: false}>
                                                    <option value="">Select or Add New Category</option>
                                                    {categories.length && categories.map(item => {
                                                        return (<option value={item?.title} selected={item?.title == resourceObj?.category?.title ? true : false}>{item.title}</option>)
                                                    })}
                                                    <option value="Add New Category">Add New Category</option>
                                            </select>
                                            
                                            {catType ? (<input name="category" value={resourceObj?.category?.title}
                                                   onChange={event => setResourceObj({...resourceObj, category: event.target.value})}
                                                   className="form-control" type="text" />) : ""}                                            
                                        </div>
                                        <div className="form-group">
                                            <h4>Title <small><i  className="text-danger">(this is the title of the write-up)</i></small></h4>
                                            <input required value={resourceObj.title}
                                                   onChange={event => setResourceObj({...resourceObj, title: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Thumbnail <small><i  className="text-danger">(This is the display image of the article)</i></small></h4>
                                            <input onChange={event => getImageUrl(event)}
                                                   className="form-control" type="file"/>
                                            <br/>
                                            <img width="10%" src={resourceObj.image} alt=""/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Content</h4>
                                            <Editor
                                                editorState={editorState}
                                                toolbar={options}
                                                initialEditorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                            <small><i  className="text-danger">Body of the article should be here. images embed contents like video etc alike.</i></small>
                                        </div>
                                        <div className="form-group">
                                            <h4>Excerpt</h4>
                                            <textarea maxLength={100} value={resourceObj.excerpt}
                                                   onChange={event => setResourceObj({...resourceObj, excerpt: event.target.value})}
                                                   className="form-control" type="text"></textarea>
                                            <small><i  className="text-danger">More like a fragement of the intro summarized</i></small>

                                        </div>
                                        <div className="form-group">
                                            <h4>Tags (optional)</h4>
                                            <input value={resourceObj.tags}
                                                   onChange={event => setResourceObj({...resourceObj, tags: event.target.value})}
                                                   className="form-control" type="text"/>
                                                   <small><i  className="text-danger">Add tags separated by comma e.g profile, investment</i></small>
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" onChange={e => setResourceObj({
                                                ...resourceObj,
                                                status: e.target.value
                                            })} >
                                                <option value="PUBLISHED" selected={ resourceObj.status == "PUBLISHED" ? true : false }>Publish</option>
                                                <option value="DRAFT" selected={resourceObj.status == "DRAFT" ? true : false }>Draft</option>
                                            </select>
                                            <small><i  className="text-danger">Draft status means the users wont see the article until status changes to publish</i></small>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green mr-3">Submit</button>
                                        </div>
                                        <div className="mb-5"/>
                                        <textarea style={{width: '100%'}}
                                                  disabled
                                                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    const detailPage = () =>{
        return (
            <Layout context={context} active_menu={'resource'} title={'Add Resources'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => {
                            setPage({page: 1})
                            clear(event)
                    }}><span className="fa fa-chevron-left"/> Resources
                        </a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Preview Resource</h4>
                                </div>
                                <div className="card-body">
                                    <div className='text-center py-2'><img src={resourceObj.image} className="img-fluid" alt={resourceObj.title} /></div>
                                    <div className='text-center'><h2>{resourceObj.title}</h2></div>
                                    <br/>
                                    <div className="mx-auto" dangerouslySetInnerHTML={{__html: resourceObj.body}} />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }


    if (page === 1) {
        return listPage();
    } else if (page === 2) {
        return editPage();
    } else if(page == 3) {
        return addPage();
    } else if(page == 4){
        return detailPage();
    } else {
        return listPage();
    }
}

export default Resources;
