import React, {Component} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import {CircularProgressbar} from "react-circular-progressbar";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {uuidv1} from 'uuid';
import API from '../../api';
import {hideLoader, showLoader} from "../../_helpers/Loader";
import excludeList from '../excludeList';

class ChildDetail extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            child: {},
            investments: [],
            total_investment: '',
            expect_return: '',
            percentage: '',
            roi: '',
            total_asset: '',
            total_liability: '',
            to_networth: '',
            fraction_networth: 0,
            target_networth: 0,
        }
    }

    async componentDidMount() {
        showLoader();
        if (this.props.match.params.id) {
            await this.getInvestment();
            await this.getChild();
        }
        hideLoader();
    }

    getChild = async () => {
        try {
            const res = await API.get(`admin/sub_investors/${this.props.match.params.id}/`);
            this.setState({child: res.data})
        }catch (e) {
        }
    }

    getInvestment = async () => {
        try {
            const res = await API.get(`admin/sub_investors/${this.props.match.params.id}/get_investments/`);
            let data = res.data
            let total = parseFloat(data.stats.total);
            let expected = parseFloat(data.stats.expected);
            let roi = expected - total
            let percentage = 100 - ((total / expected) * 100);
            let fraction_networth = parseFloat(data.stats.total_asset) / parseFloat(data.stats.target_networth) * 100;
            (fraction_networth < 0) && (this.setState({radial_colour: 'circle-danger'}));
            this.setState({
                total_investment: total,
                expected_return: expected,
                percentage: (percentage) ? percentage : 0,
                roi: roi,
                investments: data.orders,
                total_asset: data.stats.total_asset,
                total_liability: data.stats.total_liability,
                fraction_networth: fraction_networth,
                target_networth: data.stats.target_networth
            })
        } catch (e) {
        }
    }

    render() {
        return (
            <Layout active_menu={'subinvestor'} context={this.context}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb" style={{marginBottom: 0}}>
                        <h5>Child Dashboard</h5>
                    </div>

                    <div className="row" id="portfolio">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>{this.state.child.first_name}'s Portfolio</h4>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <h4>{this.state.child.first_name} {this.state.child.last_name}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 mx-auto justify-content-center text-center">
                                            <div
                                                data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                <CircularProgressbar
                                                    className={`mx-auto ${this.state.radial_colour} text-center`}
                                                    styles={{width: '70%'}}
                                                    value={parseInt(this.state.fraction_networth).toFixed(2)}
                                                    text={`${parseInt(this.state.fraction_networth)}%`}>
                                                </CircularProgressbar></div>
                                            <br/><br/>
                                            <strong className="text-orange">{parseInt(this.state.fraction_networth)}% of
                                                target
                                                net worth achieved.</strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(parseFloat(this.state.total_asset).toFixed(2)).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Current Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(parseFloat(this.state.target_networth).toFixed(2)).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Target Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(parseFloat(this.state.total_asset).toFixed(2)).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Assets</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            <strong>₦{parseFloat(parseFloat(this.state.total_liability).toFixed(2)).toLocaleString()}</strong>
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Liability</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">{this.state.child.first_name}'s Investments</h4>
                                        </div>
                                        {/*<div*/}
                                        {/*    className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">*/}
                                        {/*    <Link className="btn btn-orange m-b-5 mr-3"*/}
                                        {/*          to={`/sub-investor/${this.context.sub_investor_id}/marketplace`}>Find*/}
                                        {/*        Investments*/}
                                        {/*    </Link>*/}
                                        {/*    <Link to="/investments"*/}
                                        {/*          className="btn m-b-5 btn-orange"> View All*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-bar-chart"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total Amount Invested "
                                                               className="fa fa-info-circle text-right float-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-white"><strong>Total Investment</strong></span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(parseFloat(this.state.total_investment).toFixed(2)).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-reply-all"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Expected return on investment"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Expected ROI</strong></span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(parseFloat(this.state.expected_return).toFixed(2)).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-signal"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total amount you will receive from your investments"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Interest</strong></span>
                                                                <h2 className="text-white mb-0">₦{parseFloat(parseFloat(this.state.roi).toFixed(2)).toLocaleString()}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-line-chart"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Percentage Interest gained from  your investments"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Percentage Interest</strong></span>
                                                                <h2 className="text-white mb-0">{parseFloat(this.state.percentage).toFixed(2)}%</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Unit</th>
                                                <th>ROI(%)</th>
                                                <th>Expected Return</th>
                                                <th>Purchased Date</th>
                                                <th>Maturity Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            {this.state.investments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.investment.title}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={parseFloat(item.amount).toFixed(2)}/>
                                                    </td>
                                                    <td>{item.purchased_units}</td>
                                                    <td>{item.roi}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={parseFloat(item.expected_return).toFixed(2)}/></td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.maturity_date}</Moment>
                                                    </td>
                                                    <td>
                                                        {item.investment.id == 27 && excludeList.includes(item.sub_investor.user.email.toLowerCase()) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                    </td>
                                                    {(item.club_tag) ?
                                                        <td><span className="fa fa-list"/></td> :
                                                        <td><span className="fa fa-ban"/></td>
                                                    }
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ChildDetail;
