import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import {Context} from "../MyContext";
import API from '../../api';
import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import $ from 'jquery';

class FWPOverview extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            users: 0,
            investments: 0,
            clubs: 0,
            transactions: 0,
            amounts: 0,
            expected: 0,
            units: 0,
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getStats();
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        hideLoader();
    }

    getStats = async () => {
        try {
            let res = await API.get('admin/portfolios/get_user_stats/?user_type=fwp');
            this.setState({
                users: res.data.user_count,
                investments: res.data.investment_count,
                clubs: res.data.club_count,
                transactions: res.data.orders_count,
                amounts: res.data.amount_inv,
                expected: res.data.expected_return,
                units: res.data.unit_count
            })
            // console.log(res.data);
        } catch (e) {
            hideLoader();
            // console.log(">>", e.response)
        }
    };


    render() {
        return (
            <Layout context={this.context} active_menu={'fwp-overview'} title={'Overview'}>
                <div className="breadcrumb">
                    <h5>Overview</h5>
                </div>
                <div className="row" id="investment">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12">
                                        <h4 className="float-left">Stats</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row boxes">
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-group"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i data-tip="Total Users"
                                                           className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                {(this.state.users) ? this.state.users : 0}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>Total Users</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-bar-chart"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i
                                                            data-tip="Total Investments"
                                                            className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                {(this.state.investments) ? this.state.investments : 0}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>Listed Investments</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-users"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i
                                                            data-tip="Total Number of Clubs"
                                                            className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                {(this.state.clubs) ? this.state.clubs : 0}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>Clubs</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-hands-helping"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i
                                                            data-tip="Total Number of Transactions"
                                                            className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                {(this.state.transactions) ? this.state.transactions : 0}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>No. of Transactions</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-coins"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i
                                                            data-tip="Total Amount Invested"
                                                            className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                ₦
                                                                {parseFloat(
                                                                    parseFloat((this.state.amounts) ? this.state.amounts : 0).toFixed(2)
                                                                ).toLocaleString()}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>Total Invested Funds</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-mail-reply-all"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i
                                                            data-tip="Total Amount of Expected Return"
                                                            className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                ₦
                                                                {parseFloat(
                                                                    parseFloat((this.state.expected) ? this.state.expected : 0).toFixed(2)
                                                                ).toLocaleString()}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>Total Expected Returns</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-12 mx-auto">
                                        <div className="card bg-blue-gray text-blue">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6" style={{paddingLeft: 0}}>
                                                        <i className="text-blue fa fa-money-bill-wave"/>
                                                    </div>
                                                    <div
                                                        className="col-md-6 float-right text-right"
                                                        style={{paddingRight: 0}}
                                                    >
                                                        <i
                                                            data-tip="Total Number of Sold Units"
                                                            className="text-blue fa fa-info-circle text-right float-right"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mt-3 text-center">
                                                            <h2 className="text-blue mb-0">
                                                                {(this.state.units) ? this.state.units : 0}
                                                            </h2>
                                                            <span className="text-blue">
                              <strong>Total Units Sold</strong>
                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default FWPOverview;
