import React from 'react';
import {withRouter} from 'react-router-dom';
import API from '../../api';
import {Context} from "../MyContext";

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import $ from 'jquery';
import extractDataFromExcel from '../investments/utils';

require('bootstrap');

class Whitelist extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            whitelists: [],
            email: '',
            user_type: '',
            previous_page: null,
            next_page: null,
            count: null,
            current_page: null,
            total_pages: null,
            search_value: '',
            add_page: 1,
        }

    }

    async componentDidMount() {
        showLoader();
        await this.getWhitelist();
        hideLoader();
    }

    getWhitelist = async () => {
        try {
            let res = await API.get('admin/whitelists/');
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                whitelists: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
        } catch (e) {


        }
    };

    nextPage = async (page_num) => {
        showLoader();
        try {
            let res = await API.get('admin/whitelists/?page=' + page_num);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                whitelists: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    nextPageUrl = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                whitelists: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    searchFields = async (search_value) => {
        showLoader();
        try {
            let res = await API.get(`admin/whitelists/?search=${search_value}`);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                whitelists: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    addEmail = async (event) => {
        event.preventDefault();
        showLoader();
        if (this.state.add_page === 1) {
            let postData = {
                email: this.state.email.toLowerCase(),
                user_type: this.state.user_type.toLowerCase()
            };
            try {
                let res = await API.post('admin/whitelists/', postData);
                $('.close').click();
                $('.modal-backdrop').remove();
                this.setState({email: '', user_type: ''});
                toast.success("Added successfully")
                await this.getWhitelist();
                hideLoader()
            } catch (e) {
                hideLoader();
                if (e.response.status == 400) {
                    toast.error("User already exists")
                } else {
                    toast.error("Something went wrong")
                }
                await this.getWhitelist();
            }
        } else {
            // const file = await this.getBase64(event.target.file.files[0])
            const filex = event.target.file.files[0];
            let file = await extractDataFromExcel(filex);
            file = Object.values(file)[0]
            this.setState({payout_file: file, file_payout_page: 2})
            try {
                await API.post("admin/whitelists/upload_list/", {file});
                $('.close').click();
                $('.modal-backdrop').remove();
                this.setState({email: '', user_type: ''});
                let file_ele = document.getElementById("file")
                file_ele.value = ""
                toast.success("Added successfully. Note that emails will be added one at a time, so it could take some time.")
                hideLoader()
            } catch (e) {
                hideLoader();
                toast.error("Something went wrong")
            }
        }
    };

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('html, body').animate({scrollTop: 0}, 'fast');
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'whitelist'} title={'Whitelist'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5">
                            <h5>Whitelist</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            <a data-target="#whitelistModal" onClick={event => this.setState({'add_page': 1})}
                               data-toggle="modal" className="btn btn-green">Add to
                                Whitelist</a>
                            <a data-target="#whitelistModal" onClick={event => this.setState({'add_page': 2})}
                               data-toggle="modal" className="btn btn-green ml-3">Upload File to
                                Whitelists</a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Whitelist</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div id="example_filter" className="dataTables_filter"><label>Search:<input
                                                type="search" className="form-control form-control-sm" placeholder=""
                                                aria-controls="example"
                                                onKeyPress={event => {
                                                    (event.key === 'Enter') && this.searchFields(event.target.value)
                                                }}/></label>
                                            </div>
                                            <small>Press enter to search</small>
                                        </div>
                                    </div>
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Email
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>User Type
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Created At
                                                </th>
                                                {/* <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th> */}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.whitelists.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1">{item.email}</td>
                                                    <td className="sorting_1">{item.user_type.toUpperCase()}</td>
                                                    <td className="sorting_1">{item.created_at}</td>
                                                    {/* <td><span className="fa fa-eye"/></td> */}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPageUrl(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPageUrl(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="whitelistModal" tabindex="-1" role="dialog"
                     aria-labelledby="whitelistModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="whitelistModalLabel">Add email address</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.addEmail(event)}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        {(this.state.add_page === 1) ? (
                                            <React.Fragment>
                                                <label>Email</label>
                                                <input required type="email" className="form-control"
                                                       onChange={event => this.setState({email: event.target.value})}/>
                                                       <br/>
                                                <select className='form-control' onChange={((event) => {
                                                    this.setState({
                                                        user_type: event.target.value
                                                    })
                                                })}>
                                                    <option readOnly> Select UserType</option>
                                                    <option value="twelve">Twelve</option>
                                                    <option value="tgic">TGIC</option>
                                                    <option value="twelveblack">TwelveBlack</option>
                                                    <option value="fwp">FWP</option>
                                                </select>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <label>File</label>
                                                <input id="file" required type="file" className="form-control" name="file"/>
                                                <small>excel files only, csv, xls, xlsx; see below</small>
                                                <table style={{border: "solid 1px #000"}}>
                                                    <thead>
                                                        <th style={{border: "solid 1px #000"}}>email</th>
                                                        <th style={{border: "solid 1px #000"}}>user_type</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{border: "solid 1px #000"}}>blabla@gmail.com</td>
                                                            <td>one of (twelve, tgic, twelveblack, fwp)</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green mr-3"
                                            data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-dark">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(Whitelist);
