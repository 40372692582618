import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import uuidv1 from 'uuid';
import {Context} from "../MyContext";

class Partners extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            partners: [],
            search_value: '',
            page: 1,
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            company_name: '',
            bank: '',
            bank_name: '',
            account_number: '',
            bank_list: [],
            bank_name_check: '',
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getBanks();
        await this.getPartnerList();
        hideLoader();
    }

    async getBanks() {
        try {
            let res = await API.get('https://api.paystack.co/bank');
            this.setState({bank_list: res.data.data});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    }

    getPartnerList = async () => {
        try {
            let res = await API.get('admin/users/partners/');
            this.setState({partners: res.data});
        } catch (e) {


        }
    };

    editPartner = async (id) => {
        const get_info = this.state.partners.filter(item => item.id === id)[0];
        await this.setState({
            first_name: get_info.first_name,
            last_name: get_info.last_name,
            company_name: get_info.company_name,
            email: get_info.email,
            id: id
        });
        if (get_info.user_bank.length > 0) {
            await this.setState({
                account_number: get_info.user_bank[0].account_number,
                bank: get_info.user_bank[0].bank_name
            })
        }
        this.setState({page: 2});
    };

    updateDetails = async (event) => {
        event.preventDefault();
        let postData = {
            id: this.state.id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            company_name: this.state.company_name,
        };
        showLoader();
        try {
            let res = await API.post('admin/users/update_partner/', postData);
            toast.success("Updated Successfully");
            await this.getPartnerList();
            this.setState({page: 1});
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    verify = async (event) => {
        let postData = {
            account_number: this.state.account_number,
            bank_name: this.state.bank
        };
        showLoader();
        try {
            let res = await API.post("withdrawal_info/get_account_name/", postData);
            this.setState({bank_name_check: res.data.account_name.toLowerCase()});
            hideLoader();
        } catch (e) {
            if (e.response.status === 400) {
                toast.error("Details didn't return a valid information");
            }
            hideLoader();
        }
    };

    addDetails = async (event) => {
        event.preventDefault();
        let postData = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            company_name: this.state.company_name,
            email: this.state.email,
            bank_name: this.state.bank_name
        };
        if (this.state.account_number.length > 9) {
            postData['account_number'] = this.state.account_number
        }
        if (this.state.bank.length > 0) {
            postData['bank_code'] = this.state.bank
        }
        showLoader();
        try {
            let res = await API.post('admin/users/add_partner/', postData);
            await this.getPartnerList();
            this.setState({
                page: 1,
                first_name: '',
                last_name: '',
                email: '',
                company_name: '',
                account_number: '',
                bank: ''
            });
            hideLoader();
        } catch (e) {
            toast.error("Something went wrong");
        }
    };

    listPage() {
        return (
            <Layout active_menu={'partner'} context={this.context} title={'Partners'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5">
                            <h5>Partners</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            <button onClick={event => this.setState({
                                page: 3,
                                first_name: '',
                                last_name: '',
                                email: '',
                                company_name: '',
                                account_number: '',
                                bank: ''
                            })} className="btn btn-green"><i className="fa fa-user-plus"/> Add
                                Partner
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Partners List</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Company Rep
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Email
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.partners.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1">{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.company_name}</td>
                                                    <td>
                                                        <button onClick={event => this.editPartner(item.id)}
                                                                className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                            className="fa fa-edit ml-1"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    };

    editPage() {
        return (
            <Layout active_menu={'partner'} context={this.context} title={this.state.first_name}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Partners</a>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Edit Information</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.updateDetails(event)}>
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input className="form-control" value={this.state.first_name}
                                                   onChange={event => this.setState({first_name: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input className="form-control" value={this.state.last_name}
                                                   onChange={event => this.setState({last_name: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" value={this.state.email}
                                                   onChange={event => this.setState({email: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Name</label>
                                            <input className="form-control" value={this.state.company_name}
                                                   onChange={event => this.setState({company_name: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Bank</label>
                                            <select className="form-control" value={this.state.bank}
                                                    onChange={event => this.setState({bank: event.target.value})}>
                                                <option value="">---- Select an option ----</option>
                                                {this.state.bank_list.map(item => (
                                                    <option key={uuidv1()}
                                                            value={item.code}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input className="form-control" value={this.state.account_number}
                                                   onChange={event => this.setState({account_number: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green btn-block">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    addPage() {
        return (
            <Layout active_menu={'partner'} context={this.context} title={'Create New Partner'}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Partners</a>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Add Information</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.addDetails(event)}>
                                        <div className="form-group">
                                            <label>First Name <sup className="text-danger">*</sup></label>
                                            <input required className="form-control" value={this.state.first_name}
                                                   onChange={event => this.setState({first_name: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name <sup className="text-danger">*</sup></label>
                                            <input className="form-control" value={this.state.last_name}
                                                   onChange={event => this.setState({last_name: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Email <sup className="text-danger">*</sup></label>
                                            <input required type="email" className="form-control"
                                                   value={this.state.email}
                                                   onChange={event => this.setState({email: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Company Name <sup className="text-danger">*</sup></label>
                                            <input required className="form-control" value={this.state.company_name}
                                                   onChange={event => this.setState({company_name: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Bank</label>
                                            <select className="form-control" value={this.state.bank}
                                                    onChange={event => this.setState({
                                                        bank: event.target.value,
                                                        bank_name: event.target.options[event.target.selectedIndex].text
                                                    })}>
                                                <option value="">---- Select an option ----</option>
                                                {this.state.bank_list.map(item => (
                                                    <option key={uuidv1()}
                                                            value={item.code}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <div className="form-row">
                                                <div className="form-group col-sm-8">
                                                    <input className="form-control"
                                                           value={this.state.account_number}
                                                           onChange={event => this.setState({account_number: event.target.value})}/>
                                                </div>
                                                <div className="form-group col-sm-4">
                                                    <button type="button" onClick={event => this.verify(event)}
                                                            className="btn btn-orange">Verify
                                                    </button>
                                                </div>
                                            </div>
                                            <small
                                                style={{textTransform: 'capitalize'}}>{this.state.bank_name_check}</small>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green btn-block">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.listPage()
        } else if (this.state.page === 2) {
            return this.editPage()
        } else {
            return this.addPage()
        }
    }
}

export default withRouter(Partners);
