import React, {useState, useEffect, useContext} from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';
import {Context} from "../MyContext";

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import $ from 'jquery';
import extractDataFromExcel from '../investments/utils';
import Modal from '../elements/modal';
import ModalBulk from '../elements/modalBulk';

require('bootstrap');

const AccreditationList = () => {

  const [pagination, setPagination] = useState({});
  const [acredlists, setAcredlists] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [questions, setQuestionsData] = useState({});
  const [answer, setAnswerData] = useState({});
  const [objKey, setObjKey] = useState([]);
  const [status, setStatus] = useState("DECLINED")
  const [bulk, setBulk] = useState(null)
  const [actionText, setActionText] = useState(null)
  const [actionArgument, setActionArgument] = useState({
    action: null,
    id: null
  })
  let [selecting, setSelecting] = useState([])
//   let sample = null
  const context = useContext(Context);

    // let selecting = []
    const selectionFunction = (e, value) => {
        if (e.target.checked && !selecting.includes(value)){
            selecting.push(value)
            setSelecting(selecting)
            console.log(selecting)
        } else if (!e.target.checked){
            let selectedOnly = selecting.filter(item => !(item == value))
            selecting=selectedOnly
            setSelecting(selecting)
            console.log(selecting)
        }
    }

  const getAccreditationList = async (status=null) => {
    let url = 'admin/accreditation/'
    if (status){
        url = 'admin/accreditation/?status=' + status 
    }
    try {
        let res = await API.get(url);
        console.log(res)
        setPagination({
            next_page: res.data.next,
            previous_page: res.data.previous,
            total_pages: res.data.total_pages,
            current_page: res.data.current_page,
        });
        setAcredlists(res.data.results)
        console.log(res.data.results)
    } catch (e) {
    }
  };

  const getSegAcreditation = (value) => {
    setStatus(value)
    getAccreditationList(status)
  }

  const nextPage = async (page_num) => {
      showLoader();
      try {
          let res = await API.get(`admin/accreditation/?&status=${status}&page=${page_num}`);
          setPagination({
              next_page: res.data.next,
              previous_page: res.data.previous,
              total_pages: res.data.total_pages,
              current_page: res.data.current_page,
          });
          setAcredlists(res.data.results)
          hideLoader();
      } catch (e) {
          hideLoader();
      }
  };

  const nextPageUrl = async (page_url) => {
      showLoader();
      try {
          let res = await API.get(page_url + `&status=${status}`);
          setPagination({
              next_page: res.data.next,
              previous_page: res.data.previous,
              total_pages: res.data.total_pages,
              current_page: res.data.current_page,
          });
          setAcredlists(res.data.results)
          hideLoader();
      } catch (e) {
          hideLoader();
      }
  };

  const searchFields = async (search_value) => {
    showLoader();
    try {
        let res = await API.get(`admin/accreditation/?search=${search_value}&status=${status}`);
        setPagination({
            next_page: res.data.next,
            previous_page: res.data.previous,
            total_pages: res.data.total_pages,
            current_page: res.data.current_page,
        });
        setAcredlists(res.data.results)
        hideLoader();
    } catch (e) {
        hideLoader();
    }
  }

  const ActOnSubmission = async(value, id) => {
    showLoader();
    try {
        let postData = {
          action: value
        }
        let res = await API.post(`admin/accreditation/${id}/approval_or_decline/`, postData);
        setStatus("DECLINED")
        await getAccreditationList("DECLINED")
        toast.success("Operation Successful")
        hideLoader();
    } catch (e) {
        hideLoader();
        toast.error("Something went wrong")
    }
  }

  const BulkActOnSubmission = async(value) => {
    showLoader();
    try {
        let postData = {
          action: value,
          user_question_ids: selecting
        }

        console.log(postData, "postData>>")
        let res = await API.post(`admin/accreditation/bulk_approval_or_decline/`, postData);
        setStatus("DECLINED")
        await getAccreditationList("DECLINED")
        toast.success("Operation Successful")
        hideLoader();
    } catch (e) {
        hideLoader();
        console.log(e, "e-e")
        toast.error(e.response.data.detail)
    }
  }



  const singleDataq = (event, item) => {
    event.preventDefault();
    showLoader()
    console.log(item)
    setSingleData(item)
    if(item && item.answer){
        let ans = JSON.parse(item.answer)
        console.log(Object.keys(ans), "Object.keys(ans)")
        setObjKey(Object.keys(ans))
        console.log(ans, "ans")
        setAnswerData(ans)
    }

    if(item && item.question){
        let qs = JSON.parse(item.question)
        setQuestionsData(qs)
    }
    hideLoader()
  }

  const fileConfirmationQuestionAnswer = (value) => {
    let q, a, k, v;

    try{
        k = Object.keys(JSON.parse(value))[0]
        v = Object.values(JSON.parse(value))[0]
    } catch {
        k = Object.keys(value)[0]
        v = Object.values(value)[0]
    }
    if (k == "Send_a_verification_request_to_my_lawyer"){
        q = "Send a qualification request to my Lawyer or Sec-registered Institution"
        a = `/accreditation/response/${v}/response`
    } else if (k == "Upload_a_verified_status_letter_from_a_registered_SEC_institution_or_Lawyer"){
        q = "Upload a qualification status letter from a registered SEC-registered Institution or Lawyer"
        a = v
    } else if (k == "A_Professional_license_that_demonstrates_my_investment_knowledge"){
        q = "Professional license that demonstrates my investment knowledge"
        a = v
    } else if (k == "connectBank"){
        q = "Proof of income via Okra"
        a = `/accreditation/response/${v["customer_id"]}/income`
    }

    return (<>
        {q.replaceAll("_", " ")}<br/>
        <a target="_blank" href={a} >View Submission/Upload</a>
    </>)
  }

  useEffect(() => {
    getAccreditationList(status)
    return () => {
      console.log("hello")
    };
  }, []);

  return (
    <Layout context={context} active_menu={'accreditation'} title={'Accreditation'}>
        <div className="section">
                <div className="breadcrumb pr-0">
                    <div className="col-8 pl-0">
                        <h5>Qualification List</h5>
                    </div>
                    <div className="col-sm-4 float-right text-right pr-0">
                        <button type="button"
                            className="btn btn-green mr-2"
                            disabled={ ["DECLINED", null].includes(status) ? true : false }
                            onClick={e => {
                                setStatus("DECLINED")
                                getAccreditationList("DECLINED")
                                setSelecting([])
                            }}> Auto Declined
                        </button>
                        <button type="button"
                            className="btn btn-green mx-0"
                            disabled={ status != "DECLINED" ? true : false }
                            onClick={e => {
                                console.log("PARTIALLY")
                                setStatus("PARTIALLY")
                                getAccreditationList("PARTIALLY")
                                setSelecting([])
                            }}> Pre-Approved
                        </button>
                    </div>
                </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header bg-green">
                            <h4>Qualification List</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div id="example_filter" className="dataTables_filter"><label>Search:<input
                                        type="search" className="form-control form-control-sm" placeholder=""
                                        aria-controls="example"
                                        onKeyPress={event => {
                                            (event.key === 'Enter') && searchFields(event.target.value)
                                        }}/></label>
                                    </div>
                                    <small>Press enter to search</small>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <button className="btn btn-white float-right" data-toggle="modal" data-target="#confirmBulkModal" onClick={() => {
                                        // BulkActOnSubmission("delete")
                                        setBulk(true)
                                        setActionText("Do you want to Delete these records?")
                                        setActionArgument({action: "delete"})
                                    }}><span className="fa fa-trash text-danger"></span></button>
                                    <button className="btn btn-blue mx-1 float-right" data-toggle="modal" data-target="#confirmBulkModal" onClick={() => {
                                        // BulkActOnSubmission("approve")
                                        setBulk(true)
                                        setActionText("Do you want to Approve these records?")
                                        setActionArgument({action: "approve"})
                                    }}><i className="fa fa-check-circle"></i></button>
                                    <button className="btn btn-outline-blue mx-1 float-right" data-toggle="modal"  data-target="#confirmBulkModal" onClick={() => {
                                        // BulkActOnSubmission("decline")
                                        setBulk(true)
                                        setActionText("Do you want to Decline these records?")
                                        setActionArgument({action: "decline"})
                                    }}><span className="fa fa-times text-blue"></span></button>
                                </div>
                            </div>
                            <div id="example_wrapper"
                                 className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <table
                                    className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                    role="grid" aria-describedby="example_info">
                                    <thead>
                                    <tr role="row">
                                        <th className="wd-15p" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            style={{width: "30px"}}>Select
                                        </th>
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Email
                                        </th>
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Status
                                        </th>
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Score
                                        </th>
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Created At
                                        </th>
                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                            aria-controls="example" rowSpan="1" colSpan="1"
                                            aria-sort="ascending"
                                            aria-label="First name: activate to sort column descending"
                                            style={{width: "113px"}}>Action
                                        </th>
                                        {/* <th className="wd-15p sorting" tabIndex="0"
                                            aria-controls="example"
                                            rowSpan="1" colSpan="1"
                                            aria-label="Last name: activate to sort column ascending"
                                            style={{width: "113px"}}>Action
                                        </th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {acredlists && acredlists.map(item => (
                                        <tr role="row" key={item.id}>
                                            <td><input type="checkbox" className='form-control-sm' onChange={e => selectionFunction(e, item.id)} /></td>
                                            <td className="sorting_1"><Link to={`/user/${item.user.id}`} >{item.user.email}</Link></td>
                                            <td className="sorting_1">{item.user.accredited == null || item.user.accredited == "false" ? "NOT ATTENDED" : item.user.accredited == "PARTIALLY" ? "PRE-APPROVED" : item.user.accredited == "DECLINED" ?"DECLINED" : "APPROVED" }</td>
                                            <td className="sorting_1">{item.score}</td>
                                            <td className="sorting_1">{item.created_at}</td>
                                            <td className="sorting_1">
                                              <a className='mx-1 pointer' data-target="#accreditationModal
                                              "  onClick={(event) => {
                                                // console.log("we are here there and here")
                                                singleDataq(event, item)
                                              }} data-toggle="modal"><i className='fa fa-eye text-blue'/></a>
                                              <>
                                              <a className='btn btn-outline-blue mx-1' onClick={() => {
                                                // ActOnSubmission("decline", item.id)
                                                setActionText("Do you want to Decline this record?")
                                                setActionArgument({action: "decline", id: item.id})
                                              }} data-toggle="modal" data-target="#confirmModal"><i className='fa fa-times text-blue'/> Decline</a>
                                              <a className='btn btn-blue mx-1' data-toggle="modal" data-target="#confirmModal" onClick={() => {
                                                // ActOnSubmission("approve", item.id)
                                                setActionText("Do you want to Approve this record?")
                                                setActionArgument({action: "approve", id: item.id})
                                              }}><i className='fa fa-check-circle'/> Approve </a>
                                              </>
                                              <a href="#" className='mx-1' data-toggle="modal" data-target="#confirmModal" onClick={() => {
                                                // ActOnSubmission("delete", item.id)
                                                setActionText("Do you want to Delete this record?")
                                                setActionArgument({action: "delete", id: item.id})
                                              }}><i className='fa fa-trash text-danger'/></a>
                                            </td>
                                            {/* <td><span className="fa fa-eye"/></td> */}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex justify-content-center">
                                <ul className="pagination mt-3 mb-0">
                                    <li className={`${(pagination.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                        <a className="page-link"
                                           onClick={event => nextPageUrl(pagination.previous_page)}>‹</a>
                                    </li>
                                    {[...Array(pagination.total_pages)].map((item, key) => (
                                        <li className={`${((key + 1) === pagination.current_page) ? 'active' : ''} page-item`}>
                                            <a className="page-link"
                                               onClick={event => nextPage(key + 1)}>{key + 1}</a>
                                        </li>
                                    ))}
                                    <li className={`${(pagination.next_page !== null) ? '' : 'disabled'} page-item`}>
                                        <a className="page-link"
                                           onClick={event => this.nextPageUrl(pagination.next_page)}>»</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {singleData && singleData.user && singleData.answer && singleData.question ? 
        <div className="modal fade" id="accreditationModal" tabindex="-1" role="dialog"
              aria-labelledby="accreditationModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg accordion-m" role="document">
              <div className="modal-content">
                  <div className="modal-header bg-green">
                      <h4 className="modal-title" id="accreditationModalLabel">{singleData.user.first_name || ""} {singleData.user.last_name || ""} ({singleData.user.email}) | {singleData.score}</h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                      </button>
                  </div>
                  <div className="modal-body">
                      <>
                        <div id="accordion">
                        {objKey.map((item) => {
                            console.log(answer[item], "question answer")
                            console.log(questions[item], "questions[item]")
                            return (
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapseOne">
                                        {/* {questions && questions[item].label} */}
                                        {questions && questions[item].label}
                                        </a>
                                </div>
                                <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        { questions[item].name === "file_confirmation_question" ? (fileConfirmationQuestionAnswer(answer&&answer[item])) : (answer && answer[item].split("|")[0] || "Agreed") }
                                    </div>
                                </div>
                            </div>
                          )})}
                        </div>
                      </>
                  </div>
                  {/* {singleData.user.accredited == null ?  */}
                  <div className="modal-footer">
                      <button type="button" className="btn btn-outline-green mr-3"
                              data-dismiss="modal" onClick={() => ActOnSubmission("decline", singleData.id)}>Decline
                      </button>
                      <button type="submit" className="btn btn-dark" onClick={() => ActOnSubmission("approve", singleData.id)}>Approve</button>
                  </div>
              </div>
          </div>
        </div> : ""}
        { !bulk ?  
        (<Modal actionText={actionText} setActionText={setActionText} actionFunction={ActOnSubmission} actionArgument={actionArgument} size={"sm"}/> ):
         <ModalBulk actionText={actionText} setActionText={setActionText} actionFunction={BulkActOnSubmission} actionArgument={actionArgument} bulk={bulk} size={"sm"}/>}
    </Layout>
    );
}


export default withRouter(AccreditationList);
