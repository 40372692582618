import React, {Component} from 'react';
import {Link} from 'react-router-dom'

import {authReducer} from '../../reducers/authReducers'

import {showLoader, hideLoader, toast} from "../../_helpers/Loader";

import {userService} from '../../_services';
import API from '../../api'


import LOGO from '../../assets2/img/logo-white.png'
import {authHeader} from "../../_helpers";
import {Context} from "../MyContext";


class Login extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            email: '',
            password: '',
            submitted: false,
            loading: false,
            error: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        localStorage.clear();
        this.context.clearState();
        document.title = `Twelvevest Admin | Login`;
    }

    async handleSubmit(e) {

        e.preventDefault();

        this.setState({submitted: true});

        const email = this.state.email;
        const password = this.state.password;

        // stop here if form is invalid
        if (!(email && password)) {
            return;
        }
        showLoader();
        try {
            let res = await API.post(`admin-login/`, {email, password});
            if (res.status === 200) {
                localStorage.setItem('auth', JSON.stringify(res.data));
                API.set_header(authHeader());
                try {
                    let res2 = await API.get('users/');
                    if (!res2.data[0].is_staff){
                        localStorage.removeItem('auth');
                        toast.error("You do not have permission to login here")
                    } else {
                        localStorage.setItem('user', JSON.stringify(res2.data[0]));
                        this.context.resetState();
                        setTimeout(async timeout => {
                            await hideLoader();
                            this.props.history.push('/');
                        }, 2000)
                    }
                } catch (e) {
                    hideLoader();
                    //console.log(e.response);
                }
            }
        } catch (e) {
            // console.log(e);
            if (e.response.status === 400) {
                this.setState({error: e.response.data.non_field_errors})
            } else {
                hideLoader();
                //console.log(e.response)
            }
            hideLoader()
        }
    }

    render() {
        return (
            <div id="app">
                <section className="section section-2">
                    <div className="row">
                        <div className="single-page single-pageimage construction-bg cover-image">
                            <div className="row">
                                <div className="col-lg-6 login-image">
                                    <div className="log-wrapper text-center img-container">
                                        <img src={LOGO} width="50%"
                                             className="mb-2 mt-4 mt-lg-0 mb-5" alt="logo"/>
                                        <div className="overlay text-center">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="wrapper wrapper2">
                                        <form onSubmit={this.handleSubmit} className="card-body form-body"
                                              tabIndex="500">
                                            <h3 className="text-dark">Login</h3>
                                            <div className="mail text0left">
                                                <input required
                                                       onChange={event => this.setState({email: event.target.value})}
                                                       type="email" name="mail"/>
                                                <label>Email Address</label>
                                            </div>
                                            <div className="passwd text0left">
                                                <input required
                                                       onChange={event => this.setState({password: event.target.value})}
                                                       type="password" name="password"/>
                                                <label>Password</label>
                                            </div>
                                            {(this.state.error) ? (
                                                <p className="text-left text-danger">{this.state.error}</p>
                                            ) : ''}
                                            <div className="submit">
                                                <input type="submit" value="Login" className="btn btn-green btn-block"/>
                                            </div>
                                            <p className="mb-2"><Link to="/forget-password">Forgot Password</Link></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Login;
