import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip'
import {CSVLink, CSVDownload} from "react-csv";
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import * as FileSaver from 'file-saver';
import $ from 'jquery';
import NumberFormat from "react-number-format";
import XLSX from "xlsx";
import extractDataFromExcel from './utils';

const ExcelJS = require('exceljs');
const moment = require('moment');

class Overview extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            investment: {},
            orders: [],
            stats: {},
            ind_stats: {},
            sub_stats: {},
            club_stats: {},
            total_stats: {},
            total_amount: null,
            payment_method_stats: {},
            status: 'paid',
            buffer: [],
            csv_data: [],
            current_page: 1,
            num_pages: 1,
            getUrl: "get_investment_transactions_individuals",
            export: false,
            export_title: "Export File",
            current_order_for: 'individual',
            recurring_activity: [],
            initiate_modal: '',
            payout_page: 1,
            file_payout_page: 1,
            file_payment_page: 1,
            payout_file: "",
            user_id: null,
            user_mail: null,
            penalty_order_id: null,
            currency: null
        }
    }


    async componentDidMount() {
        showLoader();
        await this.getInvestment();
        await this.getInvestmentStats();
        await this.getOrders();
        ReactTooltip.rebuild();
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        hideLoader();
    }

    getInvestment = async () => {
        try {
            let res = await API.get('admin/investments/' + this.props.match.params.id + '/');
            console.log(res.data)
            this.setState({investment: res.data})
        } catch (e) {

        }
    };

    getInvestmentStats = async () => {
        let postData = {
            investment: this.props.match.params.id
        };
        try {
            const res = await API.post('admin/investor_orders/get_all_investment_stats/', postData);
            this.setState({
                stats: res.data.individual,
                ind_stats: res.data.individual,
                sub_stats: res.data.sub_investor,
                club_stats: res.data.club,
                total_stats: res.data.total_stats,
                payment_method_stats: res.data.payment_stats,
            })
        } catch (e) {
            // console.log(e.response)
        }
    }

    getOrders = async (value) => {
        let postData = {
            investment: this.props.match.params.id
        };
        try {
            let res = await API.post(`admin/investor_orders/${this.state.getUrl}/?page=${this.state.current_page}&status=paid`, postData);
            this.setState({
                current_order_for: (value) ? value : 'individual',
                orders: res.data.list,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
        } catch (e) {

        }
    };

    filter = async (status) => {
        if (status === 'null') {
            return false
        }
        showLoader();
        let postData = {
            investment: this.props.match.params.id
        };
        try {
            let res = await API.post(`admin/investor_orders/${this.state.getUrl}/?page=1&status=${status}`, postData);
            this.setState({
                orders: res.data.list,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            showLoader();
        }
    };

    nextPage = async (page) => {
        showLoader();
        let postData = {
            investment: this.props.match.params.id
        };
        try {
            let res = await API.post(`admin/investor_orders/${this.state.getUrl}/?page=${page}&status=${this.state.status}`, postData);
            this.setState({
                orders: res.data.list,
                status: res.data.status,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages
            });
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };


    handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await this.searchFields(e.target.value);
        }
    };

    searchFields = async (search_value) => {
        showLoader();
        let postData = {
            investment: this.props.match.params.id,
        };
        try {
            let res = await API.post(`admin/investor_orders/${this.state.getUrl}/?page=${1}&search=${search_value}`, postData);
            this.setState({
                orders: res.data.list,
                current_page: res.data.current_page,
                num_pages: res.data.num_pages,
                status: res.data.status
            });
            console.log(this.state.orders, "=======>")
            let myElement = document.getElementById('orders');
            let topPos = myElement.offsetTop;
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    exportFile = async () => {
        let postData = {
            investment: this.props.match.params.id,
            status: this.state.status,
            account_type: this.state.current_order_for
        };
        this.setState({export_title: "Processing..."});
        try {
            let res = await API.post('admin/investor_orders/get_all_transaction/', postData);
            this.processCsv(res.data);
        } catch (e) {
        }
        return true
    };

    processCsv = async (orders) => {
        let main_data = [];
        if (this.state.current_order_for === 'individual') {
            orders.map(item => {
                if (item.account_detail.length > 0) {
                    let account = item.account_detail[0];
                    main_data.push([item.id, item.investor.email, `${item.investor.first_name} ${item.investor.last_name}`, parseFloat(item.amount).toLocaleString(), item.ref_code, item.purchased_units, moment(item.created_at).format("YYYY-MM-DD"), item.maturity_date, item.status, account.account_name, account.account_number, account.bank_name])

                } else {
                    main_data.push([item.id, item.investor.email, `${item.investor.first_name} ${item.investor.last_name}`, parseFloat(item.amount).toLocaleString(), item.ref_code, item.purchased_units, moment(item.created_at).format("YYYY-MM-DD"), item.maturity_date, item.status, "", "", ""])

                }
            });
        } else if (this.state.current_order_for === 'child') {
            orders.map(item => {
                let account = item.sub_investor.account_detail[0];
                main_data.push([item.id, item.sub_investor.user.email, `${item.sub_investor.user.first_name} ${item.sub_investor.user.last_name}`, `${item.sub_investor.first_name} ${item.sub_investor.last_name}`, parseFloat(item.amount).toLocaleString(), item.ref_code, item.purchased_units, moment(item.created_at).format("YYYY-MM-DD"), item.maturity_date, item.status, account.account_name, account.account_number, account.bank_name])
            });
        } else if (this.state.current_order_for === 'club') {
            let empty_account = {account_number: '', account_name: '', bank_name: ''}
            orders.map(item => {
                let account = (item.account_detail !== undefined) ? ((item.account_detail.length > 0) ? item.account_detail[0] : empty_account) : empty_account;
                main_data.push([item.id, item.club_name, parseFloat(item.value).toLocaleString(), item.ref_code, parseInt(item.units), moment(item.created_at).format("YYYY-MM-DD"), item.maturity_date, item.status, account.account_name, account.account_number, account.bank_name])
            });
        }

        let workbook = new ExcelJS.Workbook();
        let title = this.state.investment.title;
        let worksheet = (this.state.current_order_for === 'individual') ? workbook.addWorksheet('Investors') : workbook.addWorksheet('Child Investors');
        if (this.state.current_order_for === 'individual') {
            worksheet.columns = [
                {header: 'id (always include)', key: 'id', width: 30, style: {numFmt: '@'}},
                {header: 'email', key: 'email', width: 30},
                {header: 'name', key: 'name', width: 30},
                {header: 'amount', key: 'amount', width: 20},
                {header: 'reference', key: 'reference', width: 45},
                {header: 'units', key: 'units', width: 10},
                {header: 'investment_date', key: 'investment_date', width: 25},
                {header: 'maturity_date', key: 'maturity_date', width: 15},
                {header: 'payment_status', key: 'payment_status', width: 15},
                {header: 'account_name', key: 'account_name', width: 25},
                {header: 'account_number', key: 'account_number', width: 15, style: {numFmt: '@'}},
                {header: 'bank_name', key: 'bank_name', width: 25},
            ];
        } else if (this.state.current_order_for === 'child') {
            worksheet.columns = [
                {header: 'id (always include)', key: 'id', width: 30, style: {numFmt: '@'}},
                {header: 'email', key: 'email', width: 30},
                {header: 'parent_name', key: 'parent_name', width: 30},
                {header: 'child_name', key: 'name', width: 30},
                {header: 'amount', key: 'amount', width: 20},
                {header: 'reference', key: 'reference', width: 45},
                {header: 'units', key: 'units', width: 10},
                {header: 'investment_date', key: 'investment_date', width: 25},
                {header: 'maturity_date', key: 'maturity_date', width: 15},
                {header: 'payment_status', key: 'payment_status', width: 15},
                {header: 'account_name', key: 'account_name', width: 25},
                {header: 'account_number', key: 'account_number', width: 15, style: {numFmt: '@'}},
                {header: 'bank_name', key: 'bank_name', width: 25},
            ];
        } else if (this.state.current_order_for === 'club') {
            worksheet.columns = [
                {header: 'id (always include)', key: 'id', width: 30, style: {numFmt: '@'}},
                {header: 'club', key: 'club', width: 30},
                {header: 'amount', key: 'amount', width: 20},
                {header: 'reference', key: 'reference', width: 45},
                {header: 'units', key: 'units', width: 10},
                {header: 'investment_date', key: 'investment_date', width: 25},
                {header: 'maturity_date', key: 'maturity_date', width: 15},
                {header: 'payment_status', key: 'payment_status', width: 15},
                {header: 'account_name', key: 'account_name', width: 25},
                {header: 'account_number', key: 'account_number', width: 15, style: {numFmt: '@'}},
                {header: 'bank_name', key: 'bank_name', width: 25},
            ];
        }
        title = (this.state.current_order_for === 'child') ? 'child ' + this.state.investment.title : (this.state.current_order_for === 'club') ? 'club' + this.state.investment.title : this.state.investment.title;
        worksheet.addRows(main_data);
        await workbook.xlsx.writeBuffer().then(function (data) {
            var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `${((title).toLowerCase()).split(" ").join("_")}.xlsx`);
        });
        this.setState({export_title: "Export File", export: false})
    };

    transactionPaid = async (id) => {
        showLoader();
        let postData = {
            transaction: id,
            order_type: this.state.current_order_for
        };
        try {
            let res = await API.post('admin/investor_orders/transaction_paid/', postData);
            toast.success("Successfully verified payment");
            await this.getInvestment();
            // await this.getOrders();
            await this.filter(this.state.status);
            ReactTooltip.rebuild();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong");
        }
    };

    deleteProcessing = async (event) => {
        let postData = {
            id: this.state.investment.id
        };
        showLoader();
        try {
            let res = API.post('admin/investor_orders/clear_pending_transactions/', postData);
            this.getOrders();
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success('Pending transactions successfully removed');
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong");
            // console.log(e.response)
        }
    };

    initiatePayout = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post('users/confirm_password/', {'password': event.target.password.value})
            $("#id_password").val("");
            try {
                const res2 = await API.post(`admin/investments/${this.state.investment.id}/initiate_payout/`);
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                toast.success("Initiation in progress. Kindly check back for completion status")
                await this.getInvestment();
                hideLoader();
            } catch (e) {
                hideLoader();
            }
        } catch (e) {
            hideLoader();
        }
    }

    initRefund = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post('users/confirm_password/', {'password': event.target.password.value})
            $("#id_password").val("");
            try {
                const res2 = await API.post(`admin/investments/${this.state.investment.id}/make_refund/`);
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                toast.success("Initiation in progress. Kindly check back for completion status");
                hideLoader();
            } catch (e) {
                hideLoader();
            }
        } catch (e) {
            toast.error("Wrong password");
            hideLoader();
        }
    }

    // updateTransaction = () =>{
    //     $scope.postData = $scope.transaction;
    //    $scope.postData.transaction = $scope.transaction.id;
    //
    //     Request.post('admin/investor_orders/update_transaction/', $scope.postData).then(function(res){
    //         $state.go('app.investment-orders',{'id':$scope.transaction.investment.id})
    //         Utill.showSuccess("Successfully updated transaction")
    //     }, function(res){
    //         Utill.showError('Something Went Wrong')
    //         $scope.errors = res.data;
    //
    //   })
    // }

    changeUserOrder = async (value) => {
        showLoader();
        await this.setState({
            getUrl: (value === 'child') ? 'get_investment_transactions_sub_investors' : (value === 'individual') ? 'get_investment_transactions_individuals' : 'get_investment_transactions_club',
            stats: (value === 'child') ? this.state.sub_stats : (value === 'individual') ? this.state.ind_stats : this.state.club_stats
        });
        await this.getOrders(value);
        hideLoader();
    };

    getRecurringPayment = async (event, id) => {
        showLoader();
        try {
            const res = await API.get(`admin/investor_orders/get_recurring_activity/?order_id=${id}`);
            hideLoader();
            this.setState({recurring_activity: res.data})
        } catch (e) {
            hideLoader();
            // console.log(e.response)
        }
    }

    makePayout = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post('users/confirm_password/', {'password': event.target.password.value})
            $("#id_password").val("");
            try {
                const res = await API.post(`admin/investments/${this.state.investment.id}/make_payout/`);
                await this.getInvestment();
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                toast.success('Payment sent. Kindly check back for individual transfer status');
                hideLoader();
            } catch (e) {
                hideLoader();
            }
        } catch (e) {

        }
    }

    singleInitiate = async (order_id) => {
        showLoader();
        try {
            const res = await API.post(`admin/investor_orders/initiate_single/`, {
                id: order_id,
                order_type: this.state.current_order_for
            });
            let orders = this.state.orders;
            orders.map((item, key) => {
                if (item.id === res.data.id) {
                    orders[key] = res.data
                }
            })
            this.setState({orders: orders})
            toast.success("Successful")
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    singlePayout = async (order_id) => {
        showLoader();
        try {
            const res = await API.post(`admin/investor_orders/initiate_single/`, {
                id: order_id,
                order_type: this.state.current_order_for
            });
            let orders = this.state.orders;
            orders.map((item, key) => {
                if (item.id === res.data.id) {
                    orders[key] = res.data
                }
            })
            this.setState({orders: orders})
            toast.success("Successful")
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    payoutStatus(data, id) {
        if (data === undefined) {
            return
        }
        if (data === null) {
            return (<span className="badge bg-yellow m-b-5 text-capitalize">None</span>)
        }
        if (data === 'none' || data === 'failed' || data === 'reversed') {
            if (data.status) {
                return (
                    <React.Fragment>
                        <span className="badge bg-green m-b-5 mr-2 text-capitalize">{data}</span>
                        <span onClick={event => this.singleInitiate(id)}
                              className="badge bg-dark m-b-5 text-capitalize pointer"><i className="text-blue fa fa-redo"/></span>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <span className="badge bg-danger m-b-5 mr-2 text-capitalize">Failed</span>
                        <span onClick={event => this.singleInitiate(id)}
                              className="badge bg-dark m-b-5 text-capitalize pointer"><i className="text-blue fa fa-redo"/></span>
                    </React.Fragment>
                )
            }
        } else {
            return (<span className="badge bg-green m-b-5 mr-2 text-capitalize">{data}</span>)
        }
    }

    multiplePayment(total_amount, payout_times, paid_times) {
        let payout = 0;
        if (paid_times < payout_times - 1) {
            payout = total_amount * ((parseFloat(this.state.investment.inv_roi) / payout_times) / 100)
        } else if (paid_times === payout_times - 1) {
            payout = (total_amount * ((parseFloat(this.state.investment.inv_roi) / payout_times) / 100)) + (total_amount)
        } else {
            payout = 0;
        }
        return parseFloat(payout).toLocaleString();
    }

    receiptStatus(data, id) {
        if (data === undefined) {
            return
        }
        if (data === null) {
            return (<React.Fragment>
                <span className="badge bg-yellow m-b-5 mr-2 text-capitalize">None</span>
                <span onClick={event => this.singleInitiate(id)}
                      className="badge bg-dark m-b-5 text-capitalize pointer"><i className="text-blue fa fa-redo"/></span>
            </React.Fragment>)
        }
        if (data.status !== undefined) {
            if (data.status) {
                return (
                    <React.Fragment>
                        <span className="badge bg-green m-b-5 mr-2 text-capitalize">Success</span>
                        <span onClick={event => this.singleInitiate(id)}
                              className="badge bg-dark m-b-5 text-capitalize pointer"><i className="text-blue fa fa-redo"/></span>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <span className="badge bg-danger m-b-5 mr-2 text-capitalize">Failed</span>
                        <span onClick={event => this.singleInitiate(id)}
                              className="badge bg-dark m-b-5 text-capitalize pointer"><i className="text-blue fa fa-redo"/></span>
                    </React.Fragment>
                )
            }
        }
    }

    toWallet = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            const res = await API.post('users/confirm_password/', {'password': event.target.password.value})
            $("#id_password2").val("");
            try {
                const res = await API.post(`admin/investments/${this.props.match.params.id}/to_wallet/`);
                toast.success("Payout initiated successfully");
                await this.getInvestment();
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                hideLoader();
            } catch (e) {
                toast.error("Investment not matured or something went wrong")
                hideLoader();
            }
        } catch (e) {
            hideLoader();
        }
    }

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    exportTransactionExcel = async (event) => {
        this.setState({export_title: 'Exporting...'})
        let req_data = {
            method: 'get',
            url: `admin/investments/${this.props.match.params.id}/export_transactions/`,
            responseType: "blob",
        }
        if (this.state.currency) {
            req_data = {
                method: 'get',
                url: `admin/investments/${this.props.match.params.id}/export_transactions/?currency=${this.state.currency}`,
                responseType: "blob",
            }
        }

        try {
            const res = await API.downloadData(req_data);
            var blob = new Blob([res.data], {type: res.headers['content-type']});
            var fileURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = `${this.state.investment.title}_by_${this.state.investment.company}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(fileURL);
            this.setState({export_title: 'Export File'})
        } catch (e) {
            this.setState({export_title: 'Export File'})
        }
    }


    fileToWallet = async (event) => {
        event.preventDefault();
        if (this.state.file_payout_page === 1) {
            // const file = await this.getBase64(event.target.payout_file.files[0])
            const filex = event.target.payout_file.files[0];
            let datax = await extractDataFromExcel(filex);
            console.log(datax, "datax")
            this.setState({payout_file: datax, file_payout_page: 2})
        } else {
            showLoader();
            try {
                const res = await API.post('users/confirm_password/', {'password': event.target.password.value})
                try {
                    const res = await API.post(`admin/investments/${this.props.match.params.id}/file_payout/`, {
                        password: $("#id_password3").val(),
                        file: this.state.payout_file
                    })
                    toast.success("Payout initiated successfully");
                    $('.modal').modal('hide');
                    $(document.body).removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    hideLoader();
                    $("#id_password3").val("");
                } catch (e) {
                    $('.modal').modal('hide');
                    $(document.body).removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    hideLoader();
                    toast.error("Something went wrong")
                    $("#id_password3").val("");
                }
            } catch (e) {
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                toast.error("Wrong password")
                hideLoader();
            }
        }
    }

    fileToPayment = async (event) => {
        event.preventDefault();
        if (this.state.file_payment_page === 1) {
            // const file = await this.getBase64(event.target.payment_file.files[0])
            const filex = event.target.payment_file.files[0];
            let datax = await extractDataFromExcel(filex);
            console.log(datax, "datax")
            this.setState({payout_file: datax, file_payment_page: 2})
        } else {
            showLoader();
            try {
                const res = await API.post('users/confirm_password/', {'password': event.target.password.value})
                try {
                    const res = await API.post(`admin/investments/${this.props.match.params.id}/transaction_upload/`, {
                        password: $("#id_password4").val(),
                        file: this.state.payout_file
                    })
                    this.setState({file_payment_page: 1})
                    $('.modal').modal('hide');
                    $(document.body).removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    toast.success("Payment successfully uploaded")
                    hideLoader();
                    $("#id_password4").val("");
                } catch (e) {
                    $('.modal').modal('hide');
                    $(document.body).removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    toast.error("Something went wrong")
                    hideLoader();
                    $("#id_password4").val("");
                }
            } catch (e) {
            }
            hideLoader()
        }
    }

    uploadPayment = async () => {
        try {
            const res = await API.uploadFile("admin/investor_order/file_payout/", $($('#payout-file'))[0].files[0]);

        } catch (e) {

        }

    }

    payoutNotification = async () => {
        showLoader();
        try {
            await API.post(`admin/investments/${this.props.match.params.id}/payout_notification/`);
            hideLoader();
            toast.success("Payout mail sent out successfully");
        } catch (e) {
            hideLoader();
        }
    }

    multiplePayout = async () => {
        try {
            await API.post(`admin/investments/${this.props.match.params.id}/multiple/`)
        } catch (e) {

        }
    }

    mergePayments = async () => {
        showLoader();
        try {
            const res = await API.post(`admin/investments/${this.props.match.params.id}/initiate_top_up/`, {})
            toast.success("Payout initiated successfully");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    performPenalty = async (e) => {
        e.preventDefault();
        showLoader();

        try {
            const res = await API.post(`admin/investor_orders/investment_penalty/`, {percentage: e.target.percentage.value, user_id: this.state.user_id, investment_order_id: this.state.penalty_order_id})
            console.log(res, "penalty")

            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success(res.data.details);
            hideLoader();
        } catch (e) {
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.error(e.response.details)
            hideLoader();
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'investment'} title={'Investment Orders'}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb row">
                        <h5 className='col-8'><Link to="/investments"><span className="text-blue fa fa-chevron-left"/> Investments</Link>
                        </h5>
                        <div className="col-4 pull-right">
                            <Link to={`/show-interested/${this.state.investment.id}`}  className='btn btn-primary pull-right'> See Interested</Link>
                        </div>
                    </div>
                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">Stats for {this.state.investment.title} for <span
                                                className="badge badge-green">{this.state.current_order_for}</span></h4>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            {(this.state.current_order_for !== 'individual') ? (
                                                <button onClick={event => this.changeUserOrder('individual')}
                                                        className="btn btn-dark mr-2"><i
                                                    className="text-blue fa fa-user"/> Individual
                                                </button>
                                            ) : ''}
                                            {(this.state.current_order_for !== 'child') ? (
                                                <button onClick={event => this.changeUserOrder('child')}
                                                        className="btn btn-dark mr-2"><i className="text-blue fa fa-child"/> Child
                                                </button>
                                            ) : ''}
                                            {(this.state.current_order_for !== 'club') ? (
                                                <button onClick={event => this.changeUserOrder('club')}
                                                        className="btn btn-dark"><i
                                                    className="text-blue fa fa-users"/> Club <sup>Coming</sup>
                                                </button>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div
                                                                className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.total_paid) ? this.state.stats.total_paid : 0)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>No. of Transactions</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {Utils.currencyToSym(this.state.investment.currency)}
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.total_funds) ? this.state.stats.total_funds : 0).toFixed(2)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Total Invested Funds</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {Utils.currencyToSym(this.state.investment.currency)}{parseFloat(
                                                                    parseFloat((this.state.stats.total_roi) ? this.state.stats.total_roi : 0).toFixed(2)
                                                                ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Total Expected Funds</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.total_units) ? this.state.stats.total_units : 0)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Total Units Sold</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {Utils.currencyToSym(this.state.investment.currency)}
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.total_ave) ? this.state.stats.total_ave : 0).toFixed(2)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Ave. Value of Transactions</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {Utils.currencyToSym(this.state.investment.currency)}
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.unconfirmed_funds) ? this.state.stats.unconfirmed_funds : 0).toFixed(2)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Unconfirmed Payments</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {Utils.currencyToSym(this.state.investment.currency)}
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.today) ? this.state.stats.today : 0).toFixed(2)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Total Invested Today (WIP)</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="text-blue fa fa-bar-chart"/>
                                                        </div>
                                                        <div
                                                            className="col-md-6 float-right text-right"
                                                            style={{paddingRight: 0}}
                                                        >
                                                            <i
                                                                data-tip="Total Amount Invested "
                                                                className="text-blue fa fa-info-circle text-right float-right"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <h2 className="text-blue mb-0">
                                                                    {parseFloat(
                                                                        parseFloat((this.state.stats.unconfirmed_units) ? this.state.stats.unconfirmed_units : 0)
                                                                    ).toLocaleString()}
                                                                </h2>
                                                                <span className="text-blue">
                              <strong>Unconfirmed Units Sold</strong>
                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <button data-toggle="modal" data-target="#totalModal"
                                                    className="btn btn-green mr-3">View total stats
                                            </button>
                                            {this.context.user.is_superuser && (
                                                <React.Fragment>
                                                    <button data-toggle="modal"
                                                            onClick={event => {
                                                                this.setState({initiate_modal: 'refund'});
                                                                $("#id_password").val("")
                                                            }}
                                                            data-target="#initiateModal"
                                                            className="btn btn-orange mr-3">Refund all
                                                    </button>
                                                    <button onClick={event => this.payoutNotification()}
                                                            className="btn btn-orange">Payout Notification mail
                                                    </button>
                                                    <button data-toggle="modal" data-target="#uploadModal"
                                                            onClick={event => this.setState({'file_payout_page': 1})}
                                                            className="btn btn-orange ml-3 float-right">Upload Payout
                                                    </button>
                                                    <button onClick={event => this.setState({file_payment_page: 1})}
                                                            data-toggle="modal" data-target="#uploadPaymentModal"
                                                            className="btn btn-green ml-3 float-right">Upload Investment
                                                        Transactions
                                                    </button>
                                                    <button onClick={event => this.setState({payout_page: 1})}
                                                            data-target="#payoutModal" data-toggle="modal"
                                                            className="btn btn-orange float-right">Payout
                                                    </button>
                                                    {(this.state.investment.payout_status !== 'done') ? (
                                                        <button data-toggle="modal"
                                                                onClick={event => this.toWallet(event)}
                                                                className="btn btn-orange mr-3">Send Payout To wallet
                                                        </button>
                                                    ) : ''}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" id="orders">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Transactions
                                        <div className="float-lg-right float-xl-right float-md-right">
                                            {(this.context.user.is_superuser) ? (
                                                <React.Fragment>
                                                    <button onClick={event => this.mergePayments(event)}
                                                            className="btn btn-orange"> Merge Payments
                                                    </button>
                                                    <button data-toggle="modal" data-target="#deleteModal"
                                                            className="btn btn-danger mr-3 ml-3">
                                                        Clear Pending Transactions
                                                    </button>
                                                </React.Fragment>
                                            ) : ''}
                                            <select className='btn mr-1 py-1' onChange={e => {
                                                this.setState({
                                                    currency: e.target.value == "Select" ? null : e.target.value
                                                })
                                            }}>
                                                <option value={null}>Select</option>
                                                <option value={"NGN"}>NGN</option>
                                                <option value={"GBP"}>GBP</option>
                                                <option value={"USD"}>USD</option>
                                            </select>
                                            <button onClick={event => this.exportTransactionExcel()}
                                                    className="btn btn-orange"><i
                                                className="text-blue fa fa-download"/> {this.state.export_title}</button>
                                        </div>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div id="example_filter" className="dataTables_filter"><label><input
                                                type="search" className="form-control form-control-sm"
                                                placeholder="Search"
                                                aria-controls="example"
                                                onKeyPress={event => this.handleSearch(event)}/></label>
                                            </div>
                                        </div>
                                        {(this.state.status !== 'null') ? (
                                            <div className="col-md-6 col-sm-12 float-right text-right">
                                                <div id="example_filter" className="dataTables_filter"><label>
                                                    <select value={this.state.status}
                                                            onChange={event => this.filter(event.target.value)}
                                                            name="" id="" className="form-control form-control-sm">
                                                        <option value="paid">Paid Transaction</option>
                                                        <option value="processing">Processing Transaction</option>
                                                    </select></label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-md-6 col-sm-12 float-right text-right">
                                                <div id="example_filter" className="dataTables_filter"><label>
                                                    <select onChange={event => this.filter(event.target.value)}
                                                            name="" id="" className="form-control form-control-sm">
                                                        <option value="null">---Select an option---</option>
                                                        <option value="paid">Paid Transaction</option>
                                                        <option value="processing">Processing Transaction</option>
                                                    </select></label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='text-primary'><h4><strong>Investment Status:</strong> {this.state.investment.status}</h4></div>
                                    <div id="example_wrapper table-responsive"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer table-responsive ">
                                        <table
                                            className="table table-striped table-bordered border-t0 table-responsive-xl text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "15px"}}>S/N
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Investor
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Amount
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Reference
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "px"}}>Units/Tokens
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Investment Date
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Maturity
                                                </th>
                                                {(this.state.investment.club_tag) ? (
                                                    <React.Fragment>
                                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                                            aria-controls="example" rowSpan="1" colSpan="1"
                                                            aria-sort="ascending"
                                                            aria-label="First name: activate to sort column descending"
                                                            style={{width: "113px"}}>Months Paid
                                                        </th>
                                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                                            aria-controls="example" rowSpan="1" colSpan="1"
                                                            aria-sort="ascending"
                                                            aria-label="First name: activate to sort column descending"
                                                            style={{width: "113px"}}>Next Pay Date
                                                        </th>
                                                        <th className="wd-15p sorting_asc" tabIndex="0"
                                                            aria-controls="example" rowSpan="1" colSpan="1"
                                                            aria-sort="ascending"
                                                            aria-label="First name: activate to sort column descending"
                                                            style={{width: "113px"}}>Payment Option
                                                        </th>
                                                    </React.Fragment>
                                                ) : ''}
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Payout
                                                </th>
                                                {(this.state.investment.multiple_payout) ? (
                                                    <th className="wd-15p sorting_asc" tabIndex="0"
                                                        aria-controls="example" rowSpan="1" colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="First name: activate to sort column descending"
                                                        style={{width: "113px"}}>Payout Times
                                                    </th>
                                                ) : ''}
                                                {/*<th className="wd-15p sorting_asc" tabIndex="0"*/}
                                                {/*    aria-controls="example" rowSpan="1" colSpan="1"*/}
                                                {/*    aria-sort="ascending"*/}
                                                {/*    aria-label="First name: activate to sort column descending"*/}
                                                {/*    style={{width: "113px"}}>Receipt*/}
                                                {/*</th>*/}
                                                {this.context.user.is_superuser && (
                                                    <th className="wd-15p sorting" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Last name: activate to sort column ascending"
                                                        style={{width: "113px"}}>Action
                                                    </th>
                                                )}
                                                {this.context.user.is_superuser && (
                                                    <th className="wd-15p sorting" tabIndex="0"
                                                        aria-controls="example"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Last name: activate to sort column ascending"
                                                        style={{width: "113px"}}>#
                                                    </th>
                                                )}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.orders.map((item, key) => (
                                                <tr role="row" key={item.id}>
                                                    <td>{((this.state.current_page - 1) * 30) + (key + 1)}</td>
                                                    <td className="sorting_1">
                                                        {(this.state.current_order_for === 'individual') ? (
                                                            <Link
                                                                to={`/user/${item.investor.id}`}>{item.investor.first_name} {item.investor.last_name}</Link>
                                                        ) : ((item.sub_investor) ? (
                                                            <Link
                                                                to={`/child/${item.sub_investor.id}`}>{item.sub_investor.first_name} {item.sub_investor.last_name} ({item.sub_investor.user.email})</Link>
                                                        ) : (
                                                            <Link
                                                                to={`/club/${item.club}`}>{item.club_name}</Link>
                                                        ))}
                                                    </td>
                                                    <td data-tip={`${Utils.currencyToSym(item.currency)}${parseFloat(item.amount_ngn).toLocaleString()}`}>
                                                        {(this.state.current_order_for === 'club') ?
                                                            Utils.Currency(item.value, item.currency)
                                                            : Utils.Currency(item.amount, item.currency)}
                                                    </td>
                                                    <td data-tip={item.ref_code} style={{
                                                        overflow: 'hidden',
                                                        maxWidth: '20ch',
                                                        textOverflow: 'ellipsis'
                                                    }}>
                                                        {item.ref_code}                                                 {"hello hello"}
                                                    </td>
                                                    <td>
                                                        {(this.state.current_order_for === 'club') ?
                                                            Utils.NumberDecimal(item.units)
                                                            : Utils.NumberDecimal(item.purchased_units)}
                                                    </td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td><Moment format="MMM D, Y">{item.maturity_date}</Moment></td>
                                                    {(this.state.investment.club_tag) ? (
                                                        <React.Fragment>
                                                            <td>                                                {item.total_paid}
                                                            </td>
                                                            <td><Moment format="MMM D, Y">{item.next_pay_date}</Moment></td>
                                                            <td>{item.payment_option}</td>
                                                        </React.Fragment>
                                                    ) : ''}
                                                    <td> 
                                                        {(item.status.toLowerCase() === 'paid') ? (
                                                            <React.Fragment>
                                                            <span
                                                                className="badge bg-green m-b-5 text-capitalize mr-2">{item.status}</span>
                                                                {(this.state.investment.club_tag) ? (
                                                                    <span
                                                                        onClick={event => this.getRecurringPayment(event, item.id)}
                                                                        data-toggle="modal" data-target="#receiptModal"
                                                                        className="badge bg-green m-b-5 text-capitalize pointer">View Receipts</span>
                                                                ) : ''}
                                                            </React.Fragment>

                                                        ) : ((item.status.toLowerCase() === 'processing') ? (
                                                            <span
                                                                className="badge bg-yellow m-b-5 text-capitalize">{item.status}</span>
                                                        ) : '')}
                                                    </td>
                                                    <td>{this.payoutStatus(item.payout_status, item.id)}</td>
                                                    {(this.state.investment.multiple_payout) ? (
                                                        <td>{item.payout_times}</td>
                                                    ) : ''}
                                                    {this.context.user.is_superuser && (
                                                        <React.Fragment>
                                                            {(this.state.status !== 'paid') ? (
                                                                <td>
                                                                    {(item.status.toLowerCase() === 'processing') ? (
                                                                        <div>
                                                                <span onClick={event => this.transactionPaid(item.id)}
                                                                      className="btn btn-green btn-sm mt-1 mr-1"><i
                                                                    className="text-blue fa fa-check ml-1"/>
                                                            </span>
                                                                            <span
                                                                                className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                                                className="text-blue fa fa-edit ml-1"/>
                                                            </span>
                                                                        </div>
                                                                    ) : ((item.status.toLowerCase() === 'paid') ? (
                                                                        ''
                                                                    ) : '')}
                                                                </td>
                                                            ) : (
                                                                <td>
                                                                    {this.context.user.is_superuser && (
                                                                        <React.Fragment>
                                                                            {(item.payout_status === 'paid') ? (
                                                                                <span
                                                                                    className="btn btn-green btn-sm mr-1 disabled">Pay to wallet</span>
                                                                            ) : (
                                                                                <span
                                                                                    onClick={event => this.singleInitiate(item.id)}
                                                                                    className="btn btn-green btn-sm mr-1">Pay to wallet</span>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                                </td>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                    <td>
                                                    {this.context.user.is_superuser && item.investment.club_tag !== null ? 
                                                        (
                                                            <span data-toggle="modal" data-target="#penaltyModal" onClick={e => this.setState({
                                                                user_id: item.investor.id,
                                                                user_mail: item.investor.email,
                                                                penalty_order_id: item.id
                                                            })}
                                                                className="btn btn-danger btn-sm mr-1 "> Deduct Penalty</span>
                                                        ) : ("")}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.current_page > 1) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.current_page - 1)}>‹</a>
                                            </li>
                                            {[...Array(this.state.num_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.current_page === this.state.num_pages) ? 'disabled' : ''} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.current_page + 1)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.context.user.is_superuser) ? (
                    <React.Fragment>
                        <div className="modal fade" id="initiateModal" tabIndex="-1" role="dialog"
                             aria-labelledby="initiateModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h5 className="modal-title" id="initiateModalLabel"> Are
                                            you sure?</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <form action="" method="post"
                                          onSubmit={event => this.state.initiate_modal === 'receipt' ? this.initiatePayout(event) : (this.state.initiate_modal === 'payout') ? this.makePayout(event) : this.initRefund(event)}>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="">Enter Password:</label>
                                                <input id="id_password" name="password" required type="password"
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-orange">Continue
                                            </button>
                                            <button type="button" className="btn btn-outline-dark"
                                                    data-dismiss="modal">Close
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog"
                             aria-labelledby="deleteModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h5 className="modal-title" id="deleteModalLabel"><i
                                            className="text-blue fa fa-info-circle"/> Are
                                            you sure?</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="mb-0 text-orange">Note this action cannot be reversed.</p>
                                        <p className="">Make sure you have done all reconciliation on this
                                            investment.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" onClick={event => this.deleteProcessing(event)}
                                                className="btn btn-orange">Continue
                                        </button>
                                        <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="penaltyModal" tabIndex="-1" role="dialog"
                             aria-labelledby="penaltyModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h5 className="modal-title" id="penaltyModalLabel"> Do you want to penalized: {this.state.user_mail} ?</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => this.setState({
                                                        user_id: null,
                                                        user_mail: null,
                                                        penalty_order_id: null
                                                    })}>  
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <form action="" method="post"
                                          onSubmit={event => this.performPenalty(event)}>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="">Enter Percentage of Investment amount to decuct:</label>
                                                <input id="id_percentage" name="percentage" required type="number" step="any"
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-orange">Continue
                                            </button>
                                            <button type="button" className="btn btn-outline-dark"
                                                    data-dismiss="modal" onClick={e => this.setState({
                                                        user_id: null,
                                                        user_mail: null,
                                                        penalty_order_id: null
                                                    })}>Close
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : ''}
                <div className="modal fade" id="totalModal" tabIndex="-1" role="dialog"
                     aria-labelledby="totalModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="deleteModalLabel"><i
                                    className="text-blue fa fa-file-text"/> Total Stats</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <dl className="row mx-auto">
                                    <dt className="col-sm-6 text-right"><strong>No. of transactions</strong></dt>
                                    <dd className="col-sm-6">{this.state.total_stats.total_paid}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total invested funds</strong>
                                    </dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.total_stats.total_funds).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total expected funds</strong></dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.total_stats.total_roi).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total units sold</strong></dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.total_stats.total_units).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Ave value of transactions </strong>
                                    </dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.total_stats.total_ave).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Unconfirmed payments </strong></dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.total_stats.unconfirmed_funds).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Unconfirmed Units Sold </strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.total_stats.unconfirmed_units).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total invested Today (WIP) </strong>
                                    </dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.total_stats.today).toLocaleString()}</dd>

                                    <hr className="col-sm-6"/>

                                    <dt className="col-sm-6 text-right"><strong>Total wallet transaction </strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.payment_method_stats.wallet_count).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total paystack transaction </strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.payment_method_stats.paystack_count).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total monnify transaction </strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.payment_method_stats.monnify_count).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total wallet units</strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.payment_method_stats.wallet_units).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total paystack units</strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.payment_method_stats.paystack_units).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total monnify units</strong>
                                    </dt>
                                    <dd className="col-sm-6">{parseFloat(this.state.payment_method_stats.monnify_units).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total wallet amount</strong>
                                    </dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.payment_method_stats.wallet_total).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total paystack amount</strong>
                                    </dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.payment_method_stats.paystack_total).toLocaleString()}</dd>

                                    <dt className="col-sm-6 text-right"><strong>Total monnify amount</strong>
                                    </dt>
                                    <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.payment_method_stats.monnify_total).toLocaleString()}</dd>

                                </dl>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="receiptModal" tabIndex="-1" role="dialog"
                     aria-labelledby="receiptModallLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="receiptModalLabel"><i
                                    className="text-blue fa fa-file-text"/> Receipt </h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="example_wrapper table-responsive"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <table
                                        className="table table-striped table-responsive table-bordered border-t0 table-responsive-xl text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Amount
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Reference
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Total Paid
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Amount Left
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Date Paid
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.recurring_activity.map((item, key) => (
                                            <tr role="row" key={item.id}>
                                                <td>{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(item.amount).toLocaleString()}</td>
                                                <td data-tip={item.ref_code}>{item.ref_code}</td>
                                                <td>{parseFloat(parseFloat(item.amount) * (key + 1)).toLocaleString()}</td>
                                                <td>{Utils.currencyToSym(this.state.investment.currency)}{(item.order_amount - parseFloat(item.amount) * (key + 1)).toLocaleString()}</td>
                                                <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="uploadPaymentModal" tabIndex="-1" role="dialog"
                     aria-labelledby="uploadModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title"
                                    id="uploadModalLabel"> {(this.state.file_payment_page !== 1) ? 'Payment File' : 'Are you sure you want to upload payment? This action is irreversible.'}</h4>
                                <button onClick={event => {
                                    this.setState({file_payment_page: 1});
                                    $("#id_password4").val('')
                                }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form action="" method="post" onSubmit={event => this.fileToPayment(event)}
                                  encType="multipart/form-data">
                                {(this.state.file_payment_page === 1) ? (
                                    <div className="modal-body">
                                        <label>File</label>
                                        <input name="payment_file" required id="payment-file" type="file"
                                               className="form-control"/>
                                    </div>
                                ) : (
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="">Enter Password:</label>
                                            <input id="id_password4" name="password" required type="password"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                )}
                                <div className="modal-footer">
                                    <button onClick={event => {
                                        this.setState({file_payment_page: 1});
                                        $("#id_password4").val('');
                                    }} className="btn btn-outline-dark" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-green">Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="uploadModal" tabIndex="-1" role="dialog"
                     aria-labelledby="uploadModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title"
                                    id="uploadModalLabel"> {(this.state.file_payout_page !== 1) ? 'Payout File' : 'Are you sure you want to initiate payout? This action is irreversible.'}</h4>
                                <button onClick={event => {
                                    this.setState({file_payout_page: 1});
                                    $("#id_password3").val('')
                                }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form action="" method="post" onSubmit={event => this.fileToWallet(event)}
                                  encType="multipart/form-data">
                                {(this.state.file_payout_page === 1) ? (
                                    <div className="modal-body">
                                        <label>File</label>
                                        <input name="payout_file" required id="payout-file" type="file"
                                               className="form-control"/>
                                        <small>File must be csv</small>
                                    </div>
                                ) : (
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="">Enter Password:</label>
                                            <input id="id_password3" name="password" required type="password"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                )}
                                <div className="modal-footer">
                                    <button onClick={event => {
                                        this.setState({file_payout_page: 1});
                                        $("#id_password3").val('');
                                    }} className="btn btn-outline-dark" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-green">Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="payoutModal" tabIndex="-1" role="dialog"
                     aria-labelledby="payoutModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title"
                                    id="payoutModalLabel"> {(this.state.payout_page === 1) ? 'Payout Information' : 'Are you sure you want to initiate payout? This action is irreversible.'}</h4>
                                <button onClick={event => {
                                    this.setState({payout_page: 1});
                                    $("#id_password2").val('')
                                }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form action="" method="post" onSubmit={event => this.toWallet(event)}>
                                {(this.state.payout_page === 1) ? (
                                    <div className="modal-body">
                                        {(this.state.investment.investment_type === 'real estate fund') ? (
                                            <div className="form-group">
                                                <label htmlFor="">Enter total amount to be paid out</label>
                                                <NumberFormat className="form-control" value={this.state.total_amount}
                                                              thousandSeparator={true}
                                                              prefix={'₦'}
                                                              onValueChange={(values) => {
                                                                  const {value} = values;
                                                                  this.setState({
                                                                      total_amount: value
                                                                  })
                                                              }}/>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                {(this.state.investment.multiple_payout) ? (
                                                    <h5>Amount to be
                                                        paid: <strong>{(this.state.investment.currency === "NGN") ? "₦" : "$"}{this.multiplePayment(this.state.total_stats.total_funds, this.state.investment.payout_times, this.state.investment.paid_times)}</strong>
                                                    </h5>
                                                ) : (
                                                    <h5>
                                                        Amount to be
                                                        paid: <strong>{(this.state.investment.currency === "NGN") ? "₦" : "$"}{parseFloat(this.state.total_stats.total_roi).toLocaleString()}</strong>
                                                    </h5>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {(this.state.investment.multiple_payout) ? (
                                            (this.state.investment.payout_dates).map((item, key) => (
                                                <ul className="list-group">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        {item}
                                                        <button onClick={event => this.setState({payout_page: 2})}
                                                                disabled={!((key + 1) > this.state.investment.paid_times)}
                                                                className="btn badge-primary badge-pill">{!((key + 1) > this.state.investment.paid_times) ? 'Completed' : 'Initiate Payout'}
                                                        </button>
                                                    </li>
                                                </ul>
                                            ))
                                        ) : (
                                            <button className="btn badge-primary badge-pill"
                                                    onClick={event => this.setState({payout_page: 2})}>Next
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="">Enter Password:</label>
                                            <input id="id_password2" name="password" required type="password"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                )}

                                <div className="modal-footer">
                                    <button onClick={event => {
                                        this.setState({payout_page: 1});
                                        $("#id_password2").val('');
                                    }} className="btn btn-outline-dark" data-dismiss="modal">Close
                                    </button>
                                    {(this.state.payout_page === 2) ? (
                                        <button type="submit" className="btn btn-green">Continue</button>
                                    ) : ''}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Overview;
