import React, { useEffect, useState, useContext } from "react";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";

const UserInvestOrder = (props) => {
  const context = useContext(Context);
  const [investment, setInvestment] = useState({});
  const [payments, setPayments] = useState([]);
  const [amount_paid, setAmount_paid] = useState(0);
  const [reauthorise_attempt, setReauthorise_attempt] = useState(0);

  const getStats = async () => {
    try {
        let res = await API.get('admin/investor_orders/' + props.match.params.id + '/get_single_investment_details/');
        console.log(res, ":::res")
        if(res.data){
            setInvestment(res.data)
        }
    } catch (e) {
        console.log(e, "===>")
        toast.error("Something Went Wrong");
        // console.log(e.response);
    }
};

  const getInvestment = async () => {
    showLoader()
    try {
        let res = await API.get(`admin/investor_orders/get_recurring_activity/?order_id=${props.match.params.id}`);
        console.log(res.data, "::: job :::")
        setPayments(res.data)
    } catch (e) {
        // console.log(e.response);
    }
    hideLoader()
  }

  useEffect(() => {
    getStats();
    getInvestment();
    return () => {
      console.log("")
    };
  }, []);


  return (
    <Layout context={context} active_menu={'user'} title={'Investments'}>
      <div className="section">
          <div className="breadcrumb">
              <Link to="/"> <span className="text-blue fa fa-chevron-left"/> Dashboard</Link>
          </div>
          <div className="row" id="investment">
              <div className="col-md-12">
                <div className="card">
                  <div
                      className="card-header">
                      <h4 className="float-left">{investment?.investment?.title} by {investment?.investment?.company} <small><em>{investment?.investment?.in_trust ? `This investment is held in Trust by ${investment?.investment?.trustee}`: ""}</em></small></h4>
                      <div className="float-right">
                          {['real estate fund', 'risevest', 'rise vest'].includes(investment?.investment?.investment_type) || ['real estate fund', 'risevest', 'rise vest'].includes(investment?.investment?.partner?.company_name.toLowerCase())  ? '' : (
                          <button
                              disabled={(reauthorise_attempt !== 0)}
                              data-toggle="modal" data-target="#authoriseModal"
                              className="btn btn-main float-right"><i
                              className="text-blue fa fa-credit-card"/> Reauthorise
                          </button>)}

                          {['risevest', 'rise vest'].includes(investment?.investment?.partner?.company_name.toLowerCase()) && new Date(investment?.investment?.closing_date) > new Date()  && investment?.payout_status != "paid" ? (
                          <button
                              disabled={(new Date(investment?.investment?.closing_date) < new Date())}
                              data-toggle="modal" data-target="#liquidate"
                              className="btn btn-main ml-2"><i
                              className="text-blue fa fa-credit-card"/> Liquidate
                          </button>):("")}
                      </div>
                  </div>             
                  <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="text-blue fa fa-bar-chart"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Total Amount Invested</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.currencyToSym(investment.currency)}{parseFloat(investment.amount || 0).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="text-blue fa fa-reply-all"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-blue"><strong>Expected Return (ROI)</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.currencyToSym(investment.currency)}{parseFloat(investment.expected_return || 0).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="text-blue fa fa-reply-all"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-blue"><strong>Return (ROI)%</strong></span>
                                                <h2 className="text-blue mb-0">{parseFloat(investment.roi || 0).toLocaleString() * parseFloat(investment.purchased_units || 0)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="text-blue text-blue fa fa-signal"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-blue"><strong>Amount Paid</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.currencyToSym(investment.currency)}{parseFloat(amount_paid || 0).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="text-blue fa fa-line-chart"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                                        <span
                                                                            className="text-blue"><strong>Amount to Pay</strong></span>
                                                <h2 className="text-blue mb-0">₦{(parseFloat(investment.amount) - parseFloat(amount_paid)).toLocaleString()}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="table-responsive">
                        <div id="example_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table
                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Name
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Amount
                                            </th>
                                            <th className="wd-20p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Position: activate to sort column ascending"
                                                style={{width: "231.4px"}}>Reference Number
                                            </th>
                                            <th className="wd-20p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Position: activate to sort column ascending"
                                                style={{width: "231.4px"}}>{['real estate fund', 'risevest', 'rise vest'].includes(investment?.investment?.investment_type) ? "Payout Date" : "Date Paid"}
                                            </th>
                                            <th className="wd-20p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Position: activate to sort column ascending"
                                                style={{width: "231.4px"}}>Status
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {console.log(investment?.investment, "job", investment)}
                                            {payments.length > 0 ? (
                                                payments.map(item => (
                                                    <tr key={item.id}>
                                                        <td>{investment?.investment?.title} by {investment?.company}</td>
                                                        <td>{Utils.currencyToSym(investment?.currency)}{parseFloat(item.amount).toLocaleString()}</td>
                                                        <td>{item.ref_code}</td>
                                                        <td><Moment
                                                            format="MMM D, Y">{item.created_at}</Moment>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : investment?.investment?.payout_dates.length > 0 ?  (
                                                investment?.investment?.payout_dates.map((item, index) => {
                                                    if ((investment?.investment?.payout_dates.length - 1) === index){
                                                        return(
                                                            <tr key={item.id}>
                                                                <td>{investment?.investment?.title} by {investment?.investment?.company}</td>
                                                                <td>{Utils.currencyToSym(investment.currency)}{parseFloat(investment.amount * parseFloat((investment?.investment?.payout_date_percentages[index] / 100)) + parseFloat(investment.amount)).toLocaleString()}</td>
                                                                <td>{item.ref_code}</td>
                                                                <td><Moment
                                                                    format="MMM D, Y">{investment?.investment?.payout_dates[index]}</Moment>
                                                                </td>
                                                                <td>{investment?.paid_times == index + 1 ? "Paid": "Not Paid"}</td>
                                                            </tr>
                                                        ) 
                                                    } else{
                                                        return(
                                                            <tr key={item.id}>
                                                                <td>{investment?.investment?.title} by {investment?.investment?.company}</td>
                                                                <td>{Utils.currencyToSym(investment.currency)}{parseFloat(investment.amount * parseFloat((investment?.investment?.payout_date_percentages[index] / 100))).toLocaleString()}</td>
                                                                <td>{item.ref_code}</td>
                                                                <td><Moment
                                                                    format="MMM D, Y">{investment?.investment?.payout_dates[index]}</Moment>
                                                                </td>
                                                                <td>{investment?.paid_times == index + 1 ? "Paid": "Not Paid"}</td>
                                                            </tr>
                                                        )
                                                    }})
                                            ) : ("")}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default UserInvestOrder;