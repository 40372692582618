import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../Layout";
import API from '../../api'
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey} from "../../env";
import {saveAs} from 'file-saver';

const PaystackPop = window.PaystackPop;

class Wallet extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            wallet_history: [],
            club_wallet_history: [],
            balance: 0,
            spent: 0,
            loaded: 0,
            payout: 0,
            current_page: 1,
            num_pages: 0,
            export_title: "Export",
            start_date: null,
            end_date: null,
            view: "individual"
        };
    }

    async componentDidMount() {
        showLoader();
        await this.getWalletStats();
        await this.getClubWalletStats()
        hideLoader();
    }

    getWalletStats = async () => {
        showLoader()
        let url = 'admin/wallet/get_wallet_stats/'
        if (this.state.start_date && this.state.end_date){
            url = `admin/wallet/get_wallet_stats/?start_date=${this.state.start_date}&end_date=${this.state.end_date}`
        }
        try {
            let res = await API.get(url);
            this.setState({
                balance: res.data.total_current_balance,
                spent: res.data.spent,
                loaded: res.data.loaded,
                payout: res.data.manual + res.data.payout,
                wallet_history: res.data.result,
                num_pages: res.data.num_pages
            })
        } catch (e) {
        }
        hideLoader()
    };

    getClubWalletStats = async () => {
        showLoader()
        let url = 'admin/wallet/get_clubwallet_stats/'
        if (this.state.start_date && this.state.end_date){
            url = `admin/wallet/get_clubwallet_stats/?start_date=${this.state.start_date}&end_date=${this.state.end_date}`
        }
        try {
            let res = await API.get(url);
            this.setState({
                club_balance: res.data.total_current_balance,
                club_spent: res.data.spent,
                club_loaded: res.data.loaded,
                club_payout: res.data.manual + res.data.payout,
                club_wallet_history: res.data.result,
                club_num_pages: res.data.num_pages
            })
        } catch (e) {
        }
        hideLoader()
    };

    handleSearch = async (event) => {
        showLoader();
        try {
            let res = await API.get(`admin/wallet/get_wallet_stats/?search=${event.target.value}`);
            this.setState({
                balance: res.data.total_current_balance,
                spent: res.data.spent,
                loaded: res.data.loaded,
                payout: res.data.manual + res.data.payout,
                wallet_history: res.data.result,
                num_pages: res.data.num_pages
            });
            document.getElementById('table-section').scrollIntoView();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error('Something went wrong')
        }
    };

    handleClubSearch = async (event) => {
        showLoader();
        try {
            let res = await API.get(`admin/wallet/get_clubwallet_stats/?search=${event.target.value}`);
            this.setState({
                club_balance: res.data.total_current_balance,
                club_spent: res.data.spent,
                club_loaded: res.data.loaded,
                club_payout: res.data.manual + res.data.payout,
                club_wallet_history: res.data.result,
                club_num_pages: res.data.num_pages
            });
            document.getElementById('table-section').scrollIntoView();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error('Something went wrong')
        }
    };

    getPageData = async (page) => {
        showLoader();
        let url = `admin/wallet/get_wallet_stats/?page=${page}`
        if (this.state.start_date || this.state.end_date){
            url = `admin/wallet/get_wallet_stats/?page=${page}start_date=${this.state.start_date}&end_date=${this.state.end_date}`
        }
        try {
            let res = await API.get(`admin/wallet/get_wallet_stats/?page=${page}`);
            this.setState({
                balance: res.data.total_current_balance,
                spent: res.data.spent,
                loaded: res.data.loaded,
                payout: res.data.manual + res.data.payout,
                wallet_history: res.data.result,
                num_pages: res.data.num_pages,
                current_page: page
            });
            document.getElementById('table-section').scrollIntoView();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong")
        }
    };

    getClubPageData = async (page) => {
        showLoader();
        let url = `admin/wallet/get_clubwallet_stats/?page=${page}`
        if (this.state.start_date || this.state.end_date){
            url = `admin/wallet/get_clubwallet_stats/?page=${page}start_date=${this.state.start_date}&end_date=${this.state.end_date}`
        }
        try {
            let res = await API.get(`admin/wallet/get_clubwallet_stats/?page=${page}`);
            this.setState({
                club_balance: res.data.total_current_balance,
                club_spent: res.data.spent,
                club_loaded: res.data.loaded,
                club_payout: res.data.manual + res.data.payout,
                club_wallet_history: res.data.result,
                num_pages: res.data.num_pages,
                current_page: page
            });
            document.getElementById('table-section').scrollIntoView();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong")
        }
    };

    paginationHelper(num, current) {
        let count = [];
        if (num > 8) {
            if (current < num - 10) {
                count = [1, 2, 3, '...'];
                for (var k = current; k <= current + 10; k++) {
                    (!count.includes(k)) && count.push(k)
                }
            } else {
                count = [1, 2, 3, '...'];
                for (var k = current - 10; k <= num; k++) {
                    (!count.includes(k)) && count.push(k)
                }
            }

        } else {
            for (var i = 1; i <= num; i++) {
                count.push(i)
            }
        }
        return count
    }

    exportFile = async () => {
        if (!this.state.start_date || !this.state.end_date){
            this.setState({export_title: 'Exporting...'})
            const req_data = {
                method: 'get',
                url: `admin/wallet/export_log/?all=all`,
                responseType: "blob",
            }
            try {
                const res = await API.downloadData(req_data);
                var blob = new Blob([res.data], {type: res.headers['content-type']});
                var fileURL = URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = 'wallet_transactions.xlsx';
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.setState({export_title: 'Export'})
            } catch (e) {
                console.log(e, "our errors")
                toast.error("No record for the selected dates")
                this.setState({export_title: 'Export'})
            }
        } else {
            this.setState({export_title: 'Exporting...'})
            const req_data = {
                method: 'get',
                url: `admin/wallet/export_log/?start_date=${this.state.start_date}&end_date=${this.state.end_date}`,
                responseType: "blob",
            }
            try {
                const res = await API.downloadData(req_data);
                var blob = new Blob([res.data], {type: res.headers['content-type']});
                var fileURL = URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = 'wallet_transactions.xlsx';
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.setState({export_title: 'Export'})
                this.setState({
                    start_date: null,
                    end_date: null
                })
            } catch (e) {
                toast.error("No record for the selected dates")
                this.setState({export_title: 'Export'})
            }
        }
    }

    individualWallet = () => {
        return ( 
        <div className="row">
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="float-left">Individual Wallet Stats</h4>
                </div>
                <div className="card-body" id="table-section">
                    <div className="row">
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 pointer"
                             onClick={event => this.props.history.push("/wallet/balances")}>
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-wallet text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                <span
                                                    className="text-blue"><strong>Total Current Balance</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.balance)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-money text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                        <span
                                                            className="text-blue"><strong>Total Amount Spent</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.spent)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-money text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                        <span
                                                            className="text-blue"><strong>Total Amount Credited</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.loaded)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-money text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                        <span
                                                            className="text-blue"><strong>Total Credited by Admin</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.payout)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div id="example_filter" className="dataTables_filter col-sm-12 col-md-6 col-lg-6"><label><input
                            type="search" className="form-control form-control-sm"
                            placeholder="Search"
                            aria-controls="example"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    this.handleSearch(event)
                                }
                            }}/></label>
                        </div>
                        <div className='dataTables_filter col-sm-12 col-md-6 col-lg-6'>
                            <div className='row text-right pull-right'>
                                <div className='col-lg-9'>
                                    <div className="input-group">
                                        <input type="date" className="form-control form-control-sm py-1" title='Start date' onChange={e => {
                                                    this.setState({
                                                        start_date: e.target.value
                                                    })
                                                }} />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" style={{padding: "0px"}}>-</span>
                                        </div>
                                        <input type="date" className="form-control form-control-sm py-1" title='End date' onChange={e => {
                                                    this.setState({
                                                        end_date: e.target.value
                                                    })
                                                }} />
                                        <button onClick={e => this.getWalletStats()} className='btn btn-warning formcontrol formcontrol-sm mr-0 ml-1' style={{"line-height": "20px"}}>Go</button>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <button disabled={!(this.state.export_title === 'Export')}
                                        onClick={event => this.exportFile()}
                                        className="btn btn-blue float-right" style={{"line-height": "20px"}}>
                                        <i className="fa fa-download" /> {this.state.export_title}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                            <tbody>
                            <tr>
                                <th>Users</th>
                                <th>Reference Number</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Balance</th>
                                <th>Date &#8212; Time</th>
                            </tr>
                            {this.state.wallet_history.map(item => (
                                <tr key={item.id}>
                                    <td>{item.user}</td>
                                    <td>{item.ref_code}</td>
                                    <td>{Utils.Currency(item.amount)}</td>
                                    <td>{(item.status.toLowerCase() === 'credit') ? (
                                        <span className="badge bg-green m-b-5">Credit</span>
                                    ) : (item.status.toLowerCase() === 'debit') ? (
                                        <span className="badge bg-yellow m-b-5">Debit</span>
                                    ) : (item.status.toLowerCase() === 'processing') ? (
                                        <span className="badge bg-dark m-b-5 text-white">Processing</span>
                                    ) : (
                                        <span className="badge badge-danger m-b-5">Failed</span>
                                    )}
                                    </td>
                                    <td>{Utils.Currency(item.balance)}</td>
                                    <td><Moment format="MMM D, Y &#8212; HH:mm">{item.created_at}</Moment>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className="card-footer">
                    <div className="d-flex justify-content-center">
                        <ul className="pagination mt-3 mb-0">
                            <li className={`${(this.state.current_page !== 1) ? '' : 'disabled'} page-item`}>
                                <a className="page-link"
                                   onClick={event => this.getPageData(this.state.current_page - 1)}>‹</a>
                            </li>
                            {this.paginationHelper(this.state.num_pages, this.state.current_page).map(item => (
                                <li className={`${((item) === this.state.current_page) ? 'active' : ''} page-item`}>
                                    {(item === '...') ? (
                                        <a className="page-link disabled">{item}</a>
                                    ) : (
                                        <a className="page-link"
                                           onClick={event => this.getPageData(item)}>{item}</a>
                                    )}
                                </li>
                            ))}
                            <li className={`${(this.state.current_page !== this.state.num_pages) ? '' : 'disabled'} page-item`}>
                                <a className="page-link"
                                   onClick={event => this.getPageData(this.state.current_page + 1)}>»</a>
                            </li>
                            <span className="my-auto ml-3 mr-3"> Upto </span>
                            <li className={`${(this.state.current_page !== this.state.num_pages) ? '' : 'disabled'} page-item`}>
                                <a className="page-link"
                                   onClick={event => this.getPageData(this.state.num_pages)}>{this.state.num_pages}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )}

    clubWallet = () => {
        return ( 
        <div className="row">
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="float-left">Club Wallet Stats</h4>
                </div>
                <div className="card-body" id="table-section">
                    <div className="row">
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 pointer"
                             onClick={event => this.props.history.push("/wallet/balances")}>
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-wallet text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                <span
                                                    className="text-blue"><strong>Total Current Balance</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.club_balance)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-money text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                        <span
                                                            className="text-blue"><strong>Total Amount Spent</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.club_spent)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-money text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                        <span
                                                            className="text-blue"><strong>Total Amount Credited</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.club_loaded)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card bg-blue-gray text-blue">
                                <div className="card-body">
                                    <div className="row">
                                        <i className="fa fa-money text-blue"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3 text-center">
                                                        <span
                                                            className="text-blue"><strong>Total Credited by Admin</strong></span>
                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.club_payout)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-2">
                        <div id="example_filter" className="dataTables_filter col-sm-12 col-md-6 col-lg-6"><label><input
                            type="search" className="form-control form-control-sm"
                            placeholder="Search"
                            aria-controls="example"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    this.handleClubSearch(event)
                                }
                            }}/></label>
                        </div>
                        <div className='dataTables_filter col-sm-12 col-md-6 col-lg-6 pb-1'>
                            <div className='row text-right pull-right'>
                                <div className='col-lg-9'>
                                    <div className="input-group">
                                        <input type="date" className="form-control form-control-sm py-1" title='Start date' onChange={e => {
                                                    this.setState({
                                                        start_date: e.target.value
                                                    })
                                                }} />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" style={{padding: "0px"}}>-</span>
                                        </div>
                                        <input type="date" className="form-control form-control-sm py-1" title='End date' onChange={e => {
                                                    this.setState({
                                                        end_date: e.target.value
                                                    })
                                                }} />
                                        <button onClick={e => this.getClubWalletStats()} className='btn btn-warning formcontrol formcontrol-sm mr-0 ml-1' style={{"line-height": "20px"}}>Go</button>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <button 
                                    disabled={!(this.state.export_title === 'Export')} 
                                    onClick={event => this.exportFile()} className="btn btn-blue float-right" style={{"line-height": "20px"}}><i className="fa fa-download"/> 
                                        {this.state.export_title}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                            <tbody>
                            <tr>
                                <th>Club Name</th>
                                <th>Reference Number</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Balance</th>
                                <th>Date &#8212; Time</th>
                            </tr>
                            {this.state.club_wallet_history.map(item => (
                                <tr key={item.id}>
                                    <td>{item.club_name}</td>
                                    <td>{item.ref_code}</td>
                                    <td>{Utils.Currency(item.amount)}</td>
                                    <td>{(item.status.toLowerCase() === 'credit') ? (
                                        <span className="badge bg-green m-b-5">Credit</span>
                                    ) : (item.status.toLowerCase() === 'debit') ? (
                                        <span className="badge bg-yellow m-b-5">Debit</span>
                                    ) : (item.status.toLowerCase() === 'processing') ? (
                                        <span className="badge bg-dark m-b-5 text-white">Processing</span>
                                    ) : (
                                        <span className="badge badge-danger m-b-5">Failed</span>
                                    )}
                                    </td>
                                    <td>{Utils.Currency(item.balance)}</td>
                                    <td><Moment format="MMM D, Y &#8212; HH:mm">{item.created_at}</Moment>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className="card-footer">
                    <div className="d-flex justify-content-center">
                        <ul className="pagination mt-3 mb-0">
                            <li className={`${(this.state.club_current_page !== 1) ? '' : 'disabled'} page-item`}>
                                <a className="page-link"
                                   onClick={event => this.getClubPageData(this.state.club_current_page - 1)}>‹</a>
                            </li>
                            {this.paginationHelper(this.state.club_num_pages, this.state.club_current_page).map(item => (
                                <li className={`${((item) === this.state.club_current_page) ? 'active' : ''} page-item`}>
                                    {(item === '...') ? (
                                        <a className="page-link disabled">{item}</a>
                                    ) : (
                                        <a className="page-link"
                                           onClick={event => this.getClubPageData(item)}>{item}</a>
                                    )}
                                </li>
                            ))}
                            <li className={`${(this.state.club_current_page !== this.state.club_num_pages) ? '' : 'disabled'} page-item`}>
                                <a className="page-link"
                                   onClick={event => this.getClubPageData(this.state.club_current_page + 1)}>»</a>
                            </li>
                            <span className="my-auto ml-3 mr-3"> Upto </span>
                            <li className={`${(this.state.club_current_page !== this.state.club_num_pages) ? '' : 'disabled'} page-item`}>
                                <a className="page-link"
                                   onClick={event => this.getClubPageData(this.state.club_num_pages)}>{this.state.club_num_pages}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )}

    render() {
        return (
            <Layout active_menu={'wallet'} context={this.context} title={'Wallet Log'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>Wallet</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            {this.context.user.is_superuser && (
                                <button type="button"
                                        className="btn btn-green mx-2"
                                        onClick={e => this.setState({view: "individual"})}> Individual
                                </button>
                            )}
                            <button type="button" onClick={e => this.setState({view: "club"})}
                                    className="btn btn-green">Club
                            </button>
                        </div>
                    </div>
                </div>
                { this.state.view == "individual"? this.individualWallet() : this.clubWallet()}
            </Layout>
        );
    }
}

export default Wallet;
