import React from 'react';
import {BrowserRouter, Switch, Route, Redirect, withRouter} from 'react-router-dom'

import IdleTimer from 'react-idle-timer'
import {toast} from './_helpers/Loader';

import uuidv1 from 'uuid';

import {Context, MyContext} from './components/MyContext'

import Overview from './components/dashboard/Overview';
import RealOverview from './components/dashboard/RealOverview';
import TwelveBlackOverview from './components/dashboard/TwelveBlack';
import FWPOverview from './components/dashboard/FWP';
import Login from './components/account/Login';
import Whitelist from './components/account/Whitelist';
import Wallet from './components/account/Wallet';
import Otp from './components/account/Otp';
import WalletBalance from './components/account/WalletBalance';
import Investments from './components/investments/Investments';
import InvestOrder from './components/investments/InvestOrder';
import Clubs from './components/clubs/Clubs';
import Packages from './components/clubs/Packages';
import Partners from './components/partners/Partners';
import Users from './components/users/Users';
import Child from './components/users/Child';
import UserDetail from './components/users/UserDetail';
import UserInvestments from './components/users/UserInvestments';
import UserWallet from './components/users/UserWallet';
import ChildDetail from './components/users/ChildDetail';
import ForgetPassword from './components/account/ForgotPassword';
import QuarterMail from './components/mail/QuarterMail';
import Mails from "./components/mail/Mails";
import SendMail from "./components/mail/SendMail";
import Recipient from "./components/mail/Recipient";
import Media from "./components/mail/Media";
import ClubDashboard from './components/clubs/ClubDashboard';
import ManageFiles from './components/users/ManageFiles';
import ManageFilesDetail from './components/users/ManageFilesDetail';
import PayOutPage from './components/settings/payout';
import WalletWithdrawal from './components/settings/withdrawalApproval';
import ListRequests from './components/withdrawal_requests/List';

import {middleware} from './_helpers/auth-header'
import {userService} from "./_services";
import { Roles } from './components/settings/roles/roles';
import Accreditation from './components/account/Accreditation';
import AccredidationResponse from './components/account/AccredidationResponses';
import UserInvestOrder from './components/investments/UserInvestmentOrder';
import Resources from './components/resources/index';
import InterestedUsers from './components/investments/InvestInterest';
import OverviewInterestedClub from './components/investments/investInterestClub';
import { CreateRolePermission } from './components/settings/roles/createRolePage';
import Identification from './components/account/Identification';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route  {...rest} render={(props) => (
        middleware(rest, Component, props)
            ? <Component {...props} />
            : <Redirect to='/login'/>
    )}/>
);

class App extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
    }

    _onIdle(e) {
        toast.info("App is logging off due to inactivity. You can login again.");
        setTimeout(() => {
            userService.logout();
            window.location.reload();
        }, 6000);
    }


    render() {
        return (
            <MyContext>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={900000}/>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/forget-password" component={ForgetPassword}/>
                        <PrivateRoute exact path="/whitelist" component={Whitelist}/>
                        <PrivateRoute exact path="/mails" component={Mails}/>
                        <PrivateRoute exact path="/send-mails" component={SendMail}/>
                        <PrivateRoute exact path="/recipients" component={Recipient}/>
                        <PrivateRoute exact path="/media" component={Media}/>
                        <PrivateRoute exact path="/quarter-mail-beta" component={QuarterMail}/>
                        <PrivateRoute exact path="/wallet" component={Wallet}/>
                        <PrivateRoute exact path="/otp" component={Otp}/>
                        <PrivateRoute exact path="/wallet/balances" component={WalletBalance}/>
                        <PrivateRoute exact path="/investments" component={Investments}/>
                        <PrivateRoute exact path="/clubs" component={Clubs}/>
                        <PrivateRoute exact path="/club/:id" component={ClubDashboard}/>
                        <PrivateRoute exact path="/club/:id/show-committed-members/:investment_id" component={OverviewInterestedClub}/>
                        <PrivateRoute exact path="/packages" component={Packages}/>
                        <PrivateRoute exact path="/partners" component={Partners}/>
                        <PrivateRoute exact path="/investments-orders/:id" component={InvestOrder}/>
                        <PrivateRoute exact path="/show-interested/:id" component={InterestedUsers}/>
                        <PrivateRoute exact path="/users" component={Users}/>
                        <Route exact path="/child-accounts" component={Child}/>
                        <PrivateRoute exact path="/user/:id" component={UserDetail}/>
                        <PrivateRoute exact path="/manage-files" component={ManageFiles}/>
                        <PrivateRoute exact path="/manage-files/:id" component={ManageFilesDetail}/>
                        <PrivateRoute exact path="/user/:id/investments" component={UserInvestments}/>
                        <PrivateRoute exact path="/user-investment/:id/history" component={UserInvestOrder}/>
                        <PrivateRoute exact path="/user/:id/wallet" component={UserWallet}/>
                        <PrivateRoute exact path="/child/:id" component={ChildDetail}/>
                        <PrivateRoute exact path="/real-state-overview" component={RealOverview}/>
                        <PrivateRoute exact path="/twelve-black-overview" component={TwelveBlackOverview}/>
                        <PrivateRoute exact path="/fwp-overview" component={FWPOverview}/>
                        <PrivateRoute exact path="/payment-methods" component={PayOutPage}/>
                        <PrivateRoute exact path="/wallet-withdrawal-settings" component={WalletWithdrawal}/>
                        <PrivateRoute exact path="/wallet-withdrawal-requests" component={ListRequests}/>
                        <PrivateRoute exact path="/roles-and-permission" component={Roles}/>
                        <PrivateRoute exact path="/roles-and-permission/create" component={CreateRolePermission}/>
                        <PrivateRoute exact path="/accreditation" component={Accreditation}/>
                        <PrivateRoute exact path="/accreditation/response/:responseId/:type" component={AccredidationResponse}/>
                        <PrivateRoute exact path="/identification" component={Identification}/>
                        <PrivateRoute exact path="/resources" component={Resources}/>
                        <PrivateRoute exact path="*" component={Overview}/>
                    </Switch>
                </BrowserRouter>
            </MyContext>
        )
    }
}

export default withRouter(App);
