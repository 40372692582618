import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Moment from "react-moment";

import $ from 'jquery';
import ReactTooltip from "react-tooltip";
import {Context} from "../MyContext";
import Chart from "chart.js";
import "./users.css"

require('datatables');

class Users extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            email: '',
            previous_page: null,
            next_page: null,
            count: null,
            current_page: null,
            total_pages: null,
            search_value: '',
            chart_option: 'all',
            asset_data: {},
            total: 0,
            export_title: "Export users list",
            wallet_lock_stat: { wallet: 0, cwallet: 0, walletul: 0, cwalletul: 0},
            wallet_status: null,
            segmented_search: false,
            segmented_all_users: false,
            top_50: false,
            min_amount: 0,
            max_amount: 1000000,
            table_title: "Users List"
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getUserList();
        await this.getWalletLockedStat();
        this.getAssetStats('all');

        hideLoader();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.rebuild();
    }

    getUserList = async () => {
        try {
            let res = await API.get('admin/users/');
            let res2 = await API.get('admin/users/user_worth_update/')
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
        } catch (e) {
        }
    };

    getSegmentedUserList = async (e) => {
        e.preventDefault();
        showLoader();
        this.setState({
            segmented_search: true,
            segmented_all_users: false,
            top_50: false
        })
        try {
            let res = await API.get(`admin/users/users_segment_search/?min_amount=${this.state.min_amount}&max_amount=${this.state.max_amount}`);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            this.setState({segmented_search: true, table_title: `Users with Porfolio between ${this.state.min_amount} - ${this.state.max_amount}`})
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    getTop50PortfolioList = async (e) => {
        e.preventDefault();
        this.setState({
            segmented_search: false,
            segmented_all_users: false,
            top_50: true
        })
        showLoader();
        try {
            let res = await API.get(`admin/users/users_segment_search/?top50=top50`);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            this.setState({
                table_title: "Top 50 Based on Porfolio Size"
            })
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    getAllUserBasedOnPortfolioList = async (e) => {
        e.preventDefault();
        this.setState({
            segmented_all_users: true,
            segmented_search: false,
            top_50: false
        })
        showLoader();
        try {
            let res = await API.get(`admin/users/users_segment_search/?all=all`);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            this.setState({
                table_title: "All User Based on Segmentation"
            })
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    getWalletLockedStat = async () => {
        try {
            let res = await API.get('admin/wallet/count_wallet_locked/');
            this.setState({
                wallet_lock_stat:{ 
                    wallet: res.data.wallet_locked, 
                    cwallet: res.data.cwallet_locked,
                    walletul: res.data.wallet_unlocked,
                    cwalletul: res.data.cwallet_unlocked
                }
            });
        } catch (e) {
        }
    };

    nextPage = async (page_num) => {
        showLoader();
        if(!this.state.segmented_search  && !this.state.segmented_all_users){
            try {
                let res = await API.get('admin/users/?page=' + page_num);
                this.setState({
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    users: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                });
                await $("html, body").animate({scrollTop: $("#user-list").offset().top}, "slow");
                hideLoader();
            } catch (e) {
                hideLoader();
            }
        } else if(this.state.segmented_search  && !this.state.segmented_all_users) {
            try {
                let res = await API.get(`admin/users/users_segment_search/?min_amount=${this.state.min_amount}&max_amount=${this.state.max_amount}&page=${page_num}`);
                this.setState({
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    users: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                });
                await $("html, body").animate({scrollTop: $("#user-list").offset().top}, "slow");
                hideLoader();
            } catch (e) {
                hideLoader();
            }
        } else if(!this.state.segmented_search  && this.state.segmented_all_users) {
            try {
                let res = await API.get(`admin/users/users_segment_search/?all=all&page=${page_num}`);
                this.setState({
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    users: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                });
                await $("html, body").animate({scrollTop: $("#user-list").offset().top}, "slow");
                hideLoader();
            } catch (e) {
                hideLoader();
            }
        }

    };

    previousPage = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: $("#user-list").offset().top}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    searchFields = async (val) => {
        showLoader();
        let query = val;
        if(val == 'Locked'){
            query = "True"
        } else if(val == 'Unlocked' ) {
            query = "False"
        } else {
            query = val
        }
        try {
            let url = `admin/users/?search=${query}`;
            let res = await API.get(url);
            await this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
            // console.log(e)
        }
    };

    deactivateAccount = async (id) => {
        try {
            let res = await API.post()
        } catch (e) {

        }
    };

    handleSearch = async (e) => {
        this.setState({
            segmented_search: false
        })
        if (e.key === 'Enter') {
            await this.searchFields(e.target.value);
        }
    };

    getAssetStats = async (option) => {
        try {
            const res = await API.get('admin/portfolios/get_all_portfolio_stats/?option=' + option);
            const data = res.data;
            let labels = Object.keys(data), dataset = [];
            labels = labels.filter(item => item !== 'is_empty');
            let total = 0;
            labels.map(item => {
                dataset.push(data[item])
                total = total + data[item];
            })
            this.setState({asset_data: data, total: total})
            this.quarterlyChart(labels, dataset, data.is_empty)
        } catch (e) {
            hideLoader();
        }
    }

    quarterlyChart(labels, dataset, is_empty) {
        $("#myChart").remove();
        $(".chart-container").append(`<canvas id="myChart"/>`);
        var canvas = document.getElementById("myChart");
        canvas.style.width = '110%';
        var ctx = canvas.getContext('2d');
        let title = 'No data found';
        var options = {
            title: {
                display: true,
                text: title,
                position: 'top'
            },
            legend: {
                position: 'bottom'
            },
            responsive: true,
            maintainAspectRatio: true,
            rotation: -0.7 * Math.PI,
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {

                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                            //Don't Display If Legend is hide or value is 0
                            if (dataset.data[i] != 0) {
                                // Display percent in another line, line break doesn't work for fillText
                                ctx.fillText(percent, model.x + x, model.y + y + 15);
                            }
                        }
                    });
                }
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {

                        let label = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return ` ${label}: ₦${value.toLocaleString()}`

                    }
                }
            }
        };
        if (!is_empty) {
            delete options['title']
        }
        var oilData = {
            labels: labels,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        "#47C4AA",
                        "#23414D",
                        "#FB703E",
                        "#F2BF5E",
                        "#F28B50",
                        "#fcb8a9",
                        "#177f68",
                        "#41738b",
                        "#99d249",
                        "#d2fb3e",
                        "#3e57fb"
                    ]
                }]
        };

        var pieChart = new Chart(ctx, {
            type: 'pie',
            data: oilData,
            options: options
        });

        pieChart.update()

    }

    setChart = async (event) => {
        showLoader();
        this.getAssetStats(event.target.value)
        await this.setState({chart_option: event.target.value});
        hideLoader();
    }

    exportUserList = async () => {
        this.setState({export_title: 'Exporting...'})
        console.log(this.state.segmented_all_users, this.state.segmented_search, this.state.top_50)
        let url;
        if(!this.state.segmented_all_users && !this.state.segmented_search && !this.state.top_50){
            url = 'admin/users/export_user_list/'
        } else if (this.state.segmented_search){
            url = `admin/users/export_user_list/?max_amount=${this.state.max_amount}&min_amount=${this.state.min_amount}`
        } else if (this.state.segmented_all_users){
            url = `admin/users/export_user_list/?all=all`
        } else if (this.state.top_50){
            url = `admin/users/export_user_list/?top50=top50`
        }
        
        const req_data = {
            method: 'get',
            url: url,
            responseType: "blob",
        }
        toast.info("Your request is processing. This may take sometime")
        try {
            const res = await API.downloadData(req_data);
            var blob = new Blob([res.data], {type: res.headers['content-type']});
            var fileURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = 'users_list.xlsx';
            a.click();
            window.URL.revokeObjectURL(fileURL);
            this.setState({export_title: 'Export users list'})
            this.setState({top50: false, segmented_all_users: false, segmented_search: false})
        } catch (e) {
            this.setState({export_title: 'Export users list'})
        }

    }

    exportUserSegmentationList = async () => {
        this.setState({
            export_title: 'Exporting...',
        })
        const req_data = {
            method: 'get',
            url: 'admin/users/export_user_list/',
            responseType: "blob",
        }
        toast.info("Your request is processing. This may take sometime")
        try {
            const res = await API.downloadData(req_data);
            var blob = new Blob([res.data], {type: res.headers['content-type']});
            var fileURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = 'users_list.xlsx';
            a.click();
            window.URL.revokeObjectURL(fileURL);
            this.setState({export_title: 'Export users list'})
        } catch (e) {
            this.setState({export_title: 'Export users list'})
        }
    }

    makeAdmin = async (id) => {
        showLoader();
        try {
            const res = await API.post(`admin/users/${id}/switch_admin_role/`);
            const updatedUsers = this.state.users.map(item => {
                if (item.id === id) {
                    return res.data
                } else {
                    return item
                }
            })
            this.setState({users: updatedUsers})
            hideLoader()
        } catch (e) {
            toast("Something went wrong")
            hideLoader()
        }
    }

    //change wallet status
    changeWallet = async (e, obj, index, status) => {
        e.preventDefault();
        showLoader();
        try {
            if (obj !== "all"){
                const res = await API.post(`admin/wallet/lock_wallets/`, {
                    status: status, user_id: obj.id
                });
                if (res.status == 200 || res.status == 201) {
                    const updatedUser = this.state.users.map((item, key) => {
                        if (key === index && item.id === obj.id) {
                            item.wallet_locked = !obj.wallet_locked;
                        }
                        return item;
                    });
    
    
                    this.setState((prevState) => ({
                        ...prevState,
                        users: updatedUser,
                    }));

                    if (status == "lock_wallet"){
                        this.setState((prevState) =>  ({
                            wallet_lock_stat:{ 
                                ...this.state.wallet_lock_stat,
                                wallet: this.state.wallet_lock_stat.wallet + 1, 
                                walletul: this.state.wallet_lock_stat.walletul - 1,
                            }
                        }))
                    } else {
                        this.setState((prevState) =>  ({
                            wallet_lock_stat:{ 
                                ...this.state.wallet_lock_stat,
                                wallet: this.state.wallet_lock_stat.wallet - 1, 
                                walletul: this.state.wallet_lock_stat.walletul + 1,
                            }
                        }))
                    }
                    
    
                    // this.handleRefresh();
    
                    toast.success(res.data.message);
                }
            } else {
                const res = await API.post(`admin/wallet/lock_wallets/`, {
                    status: status, user_id: "all"
                });
                if (res.status == 200 || res.status == 201) {    
                    toast.success(res.data.message);
                    window.location.reload();
                }
            }

        } catch (error) {
            toast.error(error)
        }
        hideLoader();
    };

    //password verify
    passwordVery = async (e) => {
        e.preventDefault();
        showLoader();
        try{
            const res = await API.post('users/confirm_password/', {'password': e.target.password.value})
            $("#id_password").val("");
            try{
                this.changeWallet(e, "all", 1, "unlock")
                $('.modal').modal('hide');
                $(document.body).removeClass('modal-open');
                $('.modal-backdrop').remove();
                hideLoader();
            } catch(e){
                if (e.response.status === 400) {
                    toast.error(e.response.data.detail || e.response.data.message)
                } else {
                    hideLoader();
                    //console.log(e.response)
                }
                hideLoader()
            }
        } catch(e){
            if (e.response.status === 400) {
                toast.error("Password Incorrect")
            } else {
                hideLoader();
                //console.log(e.response)
            }
            hideLoader()
        }

    }

    noWalletToast = async() => {
       toast.error("This user has no wallet")
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'user'} title={'Users'}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6">
                            <h5>Users</h5>
                        </div>
                    </div>

                    <div className="row" id="asset-stats">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">Users Asset Summary</h4>
                                        </div>
                                        <div className="col-sm-6">
                                            <select value={this.state.chart_option}
                                                    onChange={event => this.setChart(event)}
                                                    className="form-control col-sm-12 col-md-3 float-right" name=""
                                                    id="">
                                                <option value="all">All</option>
                                                <option value="tgic">TGIC</option>
                                                <option value="twelve">Twelve</option>
                                                <option value="twelveblack">Twelve Black</option>
                                                <option value="fwp">FWP</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5
                                                className="text-blue"
                                                style={{
                                                    fontWeight: 'bolder',
                                                    fontSize: '16px'
                                                }}>Breakdown of total assets</h5>
                                            </div>
                                            <div className="" style={{marginBottom: '2.5rem'}}></div>
                                            <div className="chart-container">
                                                <canvas id="myChart"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Cash at Hand
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Cash at Hand'] !== undefined ? parseFloat(this.state.asset_data['Cash at Hand']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Real Estate
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Real Estate'] !== undefined ? parseFloat(this.state.asset_data['Real Estate']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Agro Tech
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Agro Tech'] !== undefined ? parseFloat(this.state.asset_data['Agro Tech']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Treasury Bills
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Treasury Bills'] !== undefined ? parseFloat(this.state.asset_data['Treasury Bills']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Mutual Bonds
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Mutual Bonds'] !== undefined ? parseFloat(this.state.asset_data['Mutual Bonds']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Bonds
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Bonds'] !== undefined ? parseFloat(this.state.asset_data['Bonds']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Micro Credit
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Micro Credit'] !== undefined ? parseFloat(this.state.asset_data['Micro Credit']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Private Equity Stake
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Private Equity Stake'] !== undefined ? parseFloat(this.state.asset_data['Private Equity Stake']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Pension Fund
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Pension Fund'] !== undefined ? parseFloat(this.state.asset_data['Pension Fund']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Fixed Deposit
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Fixed Deposit'] !== undefined ? parseFloat(this.state.asset_data['Fixed Deposit']).toLocaleString() : 0}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Savings
                                                    <span
                                                        className="badge badge-primary badge-pill">₦{this.state.asset_data['Savings'] !== undefined ? parseFloat(this.state.asset_data['Savings']).toLocaleString() : 0}</span>
                                                </li>
                                                <li style={{'fontSize': '1.25rem', 'fontWeight': 700}}
                                                    className="list-group-item d-flex justify-content-between align-items-center">
                                                    Total Net worth
                                                    <span
                                                        className="">₦{parseFloat(this.state.total).toLocaleString()}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-3">
                        <div className="row" id="user-list">
                            <div className="col-md-8">
                                <h4 className='text-left text-bold text-weight-800'><strong>Users Segmentation</strong></h4>
                                <div className='text-left'>
                                    <form className='row' onSubmit={e => this.getSegmentedUserList(e)}>
                                        <div class="form-group col-md-4">
                                            <label for="name" class="control-label">Min Amount</label>
                                            <input type="number" class="form-control" id="name" min="0" placeholder="minimum range" onChange={e => this.setState({
                                                min_amount: e.target.value
                                            })}/>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="name" class="control-label">Max Amount</label>
                                            <input type="number" class="form-control" id="name" min="1000" placeholder="max range" onChange={e => this.setState({
                                                max_amount: e.target.value
                                            })}/>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label for="name" class="control-label"></label>
                                            <button type="button" name="filter" type="submit" id="segment_filter" class="btn btn-green btn-block btn-lg" style={{backgroundColor: "#254e56"}}>Filter</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <button className="btn btn-primary btn-lg m-1 float-right" onClick={e => {
                                    window.location.reload();
                                }} style={{backgroundColor: "#254e56"}}>Clear Filters</button>
                                <button className="btn btn-primary btn-lg m-1 float-right" onClick={e => this.getTop50PortfolioList(e)} style={{backgroundColor: "#254e56"}}>Top 50</button>
                                <button className="btn btn-primary btn-lg m-1 float-right" onClick={e => {
                                    this.getAllUserBasedOnPortfolioList(e)
                                }} style={{backgroundColor: "#254e56"}}>All Users</button>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="user-list">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>{ this.state.table_title }</h4>
                                        </div>
                                        <div className="col-sm-6">
                                            <button onClick={event => {
                                                this.exportUserList(event)
                                            }}
                                                    className="btn btn-orange float-right mx-1">{this.state.export_title}
                                            </button>
                                            <button onClick={e => this.changeWallet(e, "all", 1, "lock_wallet")}
                                                    className="btn btn-danger float-right mx-1"><i className="fas fa-lock text-white"></i> Lock All Wallets
                                            </button>
                                            <button data-toggle="modal"
                                                    onClick={event => {
                                                        $("#id_password").val("")
                                                    }}
                                                    data-target="#initiateModal"
                                                    className="btn btn-secondary float-right"><i className="fas fa-unlock text-white" id="unlock_wallet"></i> Unlock All Wallets
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="input-group">
                                                <label class="mr-3">
                                                    Search:<input type="text" class="form-control select-custom" 
                                                    placeholder="Press enter to search" onKeyPress={event => this.handleSearch(event)}/>
                                                </label>
                                                <label class="mr-3">
                                                    Wallet Filter:
                                                    <select
                                                    type="search" className="form-control select-custom" 
                                                    placeholder=""
                                                    aria-controls="example"
                                                    onChange={e => this.searchFields(e.target.value)}>
                                                        <option></option>
                                                        <option>Locked</option>
                                                        <option>Unlocked</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div id="example_filter" className="dataTables_filter  float-right"><strong>Wallets: </strong><label className="text-primary fas fa-unlock mx-2"> ({this.state.wallet_lock_stat.walletul + this.state.wallet_lock_stat.cwalletul} Unlocked)</label><label className="text-danger fas fa-lock"> ({this.state.wallet_lock_stat.wallet + this.state.wallet_lock_stat.cwallet} Locked)</label>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer table-responsive">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Full Name
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Email
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Role
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Category
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "100px"}}>Target Networth(NGN)
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "100px"}}>Annual Income(NGN)
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "100px"}}>Naira Networth(NGN)
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "100px"}}>GBP Networth(NGN)
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "100px"}}>USD Networth(NGN)
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Join Date
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Wallet Status
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.users.map((item, index) => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1">{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        {(!item.is_staff) && (
                                                            <span className="badge bg-green ml-1 mr-1">user</span>)}
                                                        {(item.is_staff) && (
                                                            <span className="badge bg-orange ml-1 mr-1">staff</span>)}
                                                        {(item.is_superuser) && (
                                                            <span
                                                                className="badge bg-danger ml-1 mr-1">superadmin</span>)}
                                                    </td>
                                                    <td>
                                                        {(item.is_active) && (
                                                            <span className="badge bg-green">active</span>)}
                                                        {(!item.is_active) && (
                                                            <span className="badge bg-danger">inactive</span>)}
                                                    </td>
                                                    {/* <td>
                                                        {(item.user_type === 'twelve') ? (
                                                            <span className="badge badge-green">TGIC</span>
                                                        ) : (
                                                            <span className="badge badge-orange">Project10000</span>
                                                        )}
                                                    </td> */}
                                                    <td>
                                                        {item.user_type?.toUpperCase()}
                                                    </td>
                                                    <td>{parseFloat(item.target_networth).toLocaleString()}</td>
                                                    <td>{parseFloat(item.earnings).toLocaleString()}</td>
                                                    <td>{parseFloat(item.user_networth_ngn).toLocaleString()}</td>
                                                    <td>{parseFloat(item.user_networth_gbp).toLocaleString()}</td>
                                                    <td>{parseFloat(item.user_networth_usd).toLocaleString()}</td>
                                                    <td><Moment format="MMM D, Y">{item.date_joined}</Moment></td>
                                                    <td>
                                                        <Link data-tip="View User" to={`/user/${item.id}`}
                                                              className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                            className="fa fa-eye ml-1"/>
                                                        </Link>
                                                        <button disabled data-tip="Deactivate User"
                                                                className="btn btn-danger btn-sm mt-1 mr-1">
                                                            <i className="fa fa-user-slash"/>
                                                        </button>
                                                        {(!item.is_staff && !item.is_superuser) && (
                                                            <span onClick={event => this.makeAdmin(item.id)}
                                                                  className="btn btn-sm badge badge-orange">Make Admin</span>
                                                        )}
                                                        {(item.is_staff && !item.is_superuser) && (
                                                            <span onClick={event => this.makeAdmin(item.id)}
                                                                  className="btn btn-sm badge badge-orange">Remove Admin</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.wallet_locked ? <a onClick={(e) => {
                                                            this.changeWallet(e, item, index, "unlock")
                                                        }} className="fas fa-2x fa-lock text-primary"></a> : item.wallet_locked == false ? <a  onClick={ item.wallet_locked != null ? (e) => {
                                                            this.changeWallet(e, item, index, "lock_wallet")
                                                        }: (e) => this.noWalletToast()}  className="text-primary fas fa-2x fa-unlock text-primary"></a> : <p className="fas fa-2x fa-lock" style={{color: "#d7d4ce"}}></p> }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.previousPage(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* modal */}
                {(this.context.user.is_superuser) ? (
                    <React.Fragment>
                        <div className="modal" id="initiateModal" tabIndex="-1" role="dialog"
                            aria-labelledby="initiateModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h5 className="modal-title" id="initiateModalLabel"> Are
                                            you sure?</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    
                                    <form action="" method="post"
                                        onSubmit={e => this.passwordVery(e)}>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="">Enter Password:</label>
                                                <input id="id_password" name="password" required type="password"
                                                    className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-orange">Continue
                                            </button>
                                            <button type="button" className="btn btn-outline-dark"
                                                    data-dismiss="modal">Close
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog"
                            aria-labelledby="deleteModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h5 className="modal-title" id="deleteModalLabel"><i
                                            className="fa fa-info-circle"/> Are
                                            you sure?</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="mb-0 text-orange">Note this action cannot be reversed.</p>
                                        <p className="">Make sure you have done all reconciliation on this
                                            investment.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" onClick={event => this.deleteProcessing(event)}
                                                className="btn btn-orange">Continue
                                        </button>
                                        <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : ''}
            </Layout>
        );
    }
}

export default withRouter(Users);
