import {Context} from "../components/MyContext";

export function authHeader() {
    // return authorization header with basic auth credentials
    let auth = JSON.parse(localStorage.getItem('auth'));

    if (auth) {
        console.log(auth, "okl");
        return { 'Authorization': 'JWT ' + auth.token };
    } else {
        return false;
    }
}


function getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
}

function adminRole() {
    const user = getUserDetails();
    if(user === null){
        return false
    }else{
        let keys = Object.keys(user);
        if(keys.includes("is_superuser") || keys.includes("is_staff")){
            return {is_superuser: user.is_superuser, is_staff: user.is_staff}
        }else{
            return false
        }
    }
}


const staffViews = [
    '/',
    '*',
    '/whitelist',
    '/mails',
    '/media',
    '/quarter-mail-beta',
    '/wallet',
    '/wallet/balances',
    '/clubs',
    '/club/:id',
    '/users',
    '/child-accounts',
    '/user/:id',
    '/user/:id/investments',
    '/user/:id/wallet',
    '/child/:id',
    '/investments',
    '/investments-orders/:id',
    '/real-state-overview',
]


export function middleware(rest, Component, props) {
    const admin = adminRole();
    if(!admin){
        return false
    }
    if(admin.is_superuser){
        return true
    }else{
        if(admin.is_staff){
            console.log(staffViews, props.match.path)
            return staffViews.includes(props.match.path);
        }else{
            return false
        }
    }
}
