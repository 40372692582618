import React, {useState, useEffect, useContext} from "react"
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import API from '../../api';
import {Context} from "../MyContext";
import Layout from '../Layout';
import _ from 'lodash';

const AccreditationResponse = (props) => {
  //type is either income or response
  let type = props.match.params.type,
  responseId = props.match.params.responseId

  const [requestResponse, setResquestResponse] = useState(null)
  const [income, setIncome] = useState(null)
  const context = useContext(Context);

  const getRequestResponse = async () => {
    showLoader();
    try {
        let res = await API.get(`admin/accreditation/${responseId}/get_accreditation_response_object/`);
        setResquestResponse(res.data)
        hideLoader();
    } catch (e) {
        hideLoader();
    }
  }

  const getIncomeHistory = async () => {
    showLoader();
    try {
        let res = await API.post(`admin/accreditation/call_okra_to_process_income/`, {"customer_id": responseId});
        console.log(res.data?.data, "res.data, res.data")
        setIncome(res.data?.data)
        hideLoader();
    } catch (e) {
        console.log(e)
        toast.error("Could not fetch income details")
        hideLoader();
    }
  }

  useEffect(() => {
    if (type === "response"){
      getRequestResponse()
    } else {
      getIncomeHistory()
    }
    return () => {
      console.log()
    };
  }, []);

  const displayResponse = (value) => {
    if (value && value !== ""){
      let val = _.replace(_.trim(value), /'/g, '"')
      console.log(val.toString(), JSON.parse(_(val).value()), "val house of paper")
      return JSON.parse(_(val).value())
      // } catch {
      //   return JSON.parse(val)
      // } finally {
      //   return val
      // }
    } else {
      return null
    }

  }

  return (
    <Layout context={context} active_menu={'accreditation'} title={'Accreditation'}>
      <div className="section">
        <div className="card">
        {type === "response" ? 
          <div className="py-5 mx-5">
            {/* {"username":"Ajibola Olayanju","status":"True","response":"{'role': 'A representative of a SEC registered institution', 'evaluation_method': 'Net-Worth', 'license_number': 'dsffs kfs', 'license_jurisdiction': 'kdflskffsfs', 'additional_notes': 'we are who we are'}","role":"Investment Advisor (SEC Registered)","email":"danielrogers020@yahoo.com","created_at":"2022-12-10T02:34:30.232670Z"} */}
            <h5><b>Laywer/Sec Adviser Response</b></h5>
            <p><b>Name: </b><br/>{requestResponse?.username}</p>
            <p><b>Email: </b><br/>{requestResponse?.email}</p>
            <p><b>Submitted: </b><br/>{requestResponse?.status === "True" ? "Yes" : "No"}</p>
            <p><b>Role: </b><br/>{requestResponse?.role}</p>
            {displayResponse(requestResponse?.response) ? 
            <>
            <p><b>Uploaded Letter: </b><br/> <a href={displayResponse(requestResponse?.response)?.lawyer_upload} target={"_blank"}>{displayResponse(requestResponse?.response)?.lawyer_upload}</a></p>
            {/* <p><b>Evaluation Method: </b><br/>{displayResponse(requestResponse?.response)?.evaluation_method}</p>
            <p><b>License  Number: </b><br/>{displayResponse(requestResponse?.response)?.license_number}</p>
            <p><b>License Jurisdiction: </b><br/>{displayResponse(requestResponse?.response)?.license_jurisdiction}</p>
            <p><b>Additional Notes: </b><br/>{displayResponse(requestResponse?.response)?.additional_notes}</p> */}
            </>: ""}

          </div> : 
          <div className="py-5 mx-5">
            <h5><b>Okra Response</b></h5>
            <p>
              <ul>
                {income && income !== undefined ? 
                income&&income.customer === responseId ? (<>
                  <li><b>Customer</b><br/>{income?.customer}</li>
                  <li><b>Confidence</b> <br/>{income?.confidence}</li>
                  <li><b>Current Year</b><br/>{income?.current_year}</li>
                  <li><b>Last Year Income</b> <br/>{income?.last_year_income}</li>
                  <li><b>Last Two Years Income</b> <br/>{income?.last_two_years_income}</li>
                  <li><b>Projected Yearly Income</b><br/>{income?.projected_yearly_income}</li>
                  <li><b>Max Number of Overlapping Income Stream</b> <br/>{income?.max_number_of_overlapping_income_streams}</li>
                  <li><b>Last Two Years To This Year Projected Income</b> <br/>{income?.last_two_years_to_this_year_projected_income}</li>
                  <li>Streams: <br/><div className="table responsive text-center"><table>
                    <thead>
                      {/* <th>Account</th> */}
                      <th>Avg Monthly Income</th>
                      <th>Days</th>
                      <th>Income Type</th>
                      <th>Monthly Income</th>
                      <th>Number of Months</th>
                      <th>Projected Months</th>
                    </thead><tbody>{income?.streams.length > 0 ? (
                    income?.streams.map(item =>
                      <tr>
                        {/* <td>{item.account}</td> */}
                        <td>{item.avg_monthly_income}</td>
                        <td>{item.days}</td>
                        <td>{item.income_type}</td>
                        <td>{item.monthly_income}</td>
                        <td>{item.number_of_months}</td>
                        <td>{item.projected_months}</td>
                      </tr>
                            
                    )
                  ) : ""}</tbody></table></div></li>
                  </>) : "" : <li><b>No Okra Data Collected</b></li>}
              </ul>
            </p>
          </div>
        }
        </div>
      </div>
    </Layout>
  )
}

export default AccreditationResponse;