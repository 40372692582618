import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../Layout";
import API from '../../api'
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey} from "../../env";

const PaystackPop = window.PaystackPop;

class Wallet extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            wallet_history: [],
            wallet_page_info: {}
        };
    }

    async componentDidMount() {
        showLoader();
        await this.getWalletStats();
        hideLoader();
    }

    getWalletStats = async () => {
        try {
            let res = await API.get('admin/wallet/get_main_balance/');
            this.setState({
                wallet_page_info: res.data,
                wallet_history: res.data.results,
                num_pages: res.data.total_pages,
                current_page: res.data.current_page
            })
        } catch (e) {
        }
    };

    handleSearch = async (event) => {
        showLoader();
        try {
            let res = await API.get(`admin/wallet/get_main_balance/?search=${event.target.value}`);
            this.setState({
                wallet_page_info: res.data,
                wallet_history: res.data.results,
                num_pages: res.data.total_pages,
                current_page: res.data.current_page
            });
            document.getElementById('table-section').scrollIntoView();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error('Something went wrong')
        }
    };

    getPageData = async (page) => {
        showLoader();
        try {
            let res = await API.get(`admin/wallet/get_main_balance/?page=${page}`);
            this.setState({
                wallet_page_info: res.data,
                wallet_history: res.data.results,
                num_pages: res.data.total_pages,
                current_page: page
            });
            document.getElementById('table-section').scrollIntoView();
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Something went wrong")
        }
    };

    paginationHelper(num, current) {
        let count = [];
        if (num > 8) {
            if (current < num - 10) {
                count = [1, 2, 3, '...'];
                for (var k = current; k <= current + 10; k++) {
                    (!count.includes(k)) && count.push(k)
                }
            } else {
                count = [1, 2, 3, '...'];
                for (var k = current - 10; k <= num; k++) {
                    (!count.includes(k)) && count.push(k)
                }
            }

        } else {
            for (var i = 1; i <= num; i++) {
                count.push(i)
            }
        }
        return count
    }

    render() {
        return (
            <Layout active_menu={'wallet'} context={this.context} title={'Wallet Log'}>
                <div className="section">
                    <div className="breadcrumb">
                         <h5><Link to="/wallet"><span className="fa fa-chevron-left"/> Wallet</Link></h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="float-left">Wallet Balances</h4>
                            </div>
                            <div className="card-body" id="table-section">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div id="example_filter" className="dataTables_filter"><label><input
                                            type="search" className="form-control form-control-sm"
                                            placeholder="Search"
                                            aria-controls="example"
                                            onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                    this.handleSearch(event)
                                                }
                                            }}/></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                        <tbody>
                                        <tr>
                                            <th>Email</th>
                                            <th>Name</th>
                                            <th>Balance</th>
                                            <th>Date &#8212; Time</th>
                                        </tr>
                                        {this.state.wallet_history.map(item => (
                                            <tr key={item.id}>
                                                <td>{item.user.email}</td>
                                                <td>{`${item.user.first_name} ${item.user.last_name}`}</td>
                                                <td>{Utils.Currency(item.current_balance)}</td>
                                                <td><Moment format="MMM D, Y &#8212; HH:mm">{item.modified}</Moment>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="card-footer">
                                <div className="d-flex justify-content-center">
                                    <ul className="pagination mt-3 mb-0">
                                        <li className={`${(this.state.current_page !== 1) ? '' : 'disabled'} page-item`}>
                                            <a className="page-link"
                                               onClick={event => this.getPageData(this.state.current_page - 1)}>‹</a>
                                        </li>
                                        {this.paginationHelper(this.state.num_pages, this.state.current_page).map(item => (
                                            <li className={`${((item) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                {(item === '...') ? (
                                                    <a className="page-link disabled">{item}</a>
                                                ) : (
                                                    <a className="page-link"
                                                       onClick={event => this.getPageData(item)}>{item}</a>
                                                )}
                                            </li>
                                        ))}
                                        <li className={`${(this.state.current_page !== this.state.num_pages) ? '' : 'disabled'} page-item`}>
                                            <a className="page-link"
                                               onClick={event => this.getPageData(this.state.current_page + 1)}>»</a>
                                        </li>
                                        <span className="my-auto ml-3 mr-3"> Upto </span>
                                        <li className={`${(this.state.current_page !== this.state.num_pages) ? '' : 'disabled'} page-item`}>
                                            <a className="page-link"
                                               onClick={event => this.getPageData(this.state.num_pages)}>{this.state.num_pages}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Wallet;
