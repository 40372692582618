import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import uuidv1 from 'uuid';
import {Context} from "../MyContext";

class QuarterMail extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            email: '',
        }
    }

    sendMail = async (event) => {
        event.preventDefault();
        showLoader()
        try {
            const res = await API.post('admin/assets/send_report_to_user/', {'email': this.state.email});
            toast.success("Email sent")
            hideLoader();
        } catch (e) {
            // console.log(e.response)
            hideLoader()
        }
    }

    render() {
        return (
            <Layout active_menu={'media'} context={this.context} title={'Quarter Reports'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5">
                            <h5>Test</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Partners List</h4>
                                </div>
                                <div className="card-body">
                                    <form action="" onSubmit={event => this.sendMail(event)}>
                                        <label htmlFor="" className="form-label">Send Quarter mail:</label>
                                        <input onChange={event => this.setState({email: event.target.value})} required
                                               type="email" className="form-control"/>
                                        <button className="btn btn-green mt-3">Send</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withRouter(QuarterMail);
