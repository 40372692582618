import HoldOn from 'react-hold-on';
import toastr from 'toastr';


var options = {
    theme: "sk-cube-grid",
    message: 'Twelvevest',
    backgroundColor: "#1847B1",
    textColor: "white"
};

var showLoader = function () {
    HoldOn.open(options);
};

var hideLoader = function () {
    HoldOn.close()
};

export {showLoader, hideLoader, toastr}