import React, {useEffect, useState, useContext} from 'react';
import {hideLoader, showLoader, toast} from "../../../_helpers/Loader";
import Layout from "../../Layout";
import {Context} from "../../MyContext";
import Moment from "react-moment";
import API from '../../../api';

export const SingleRoles = (props) => {
    const context = useContext(Context)
    const [userData, setUserData] = useState(props?.role?.users || props?.role || [])
    // console.log(props)

    const unassignUser = async(email) => {
      if (!props?.role?.id){
        toast.error("to remove a super admin use the user list")
        return;
      }
      let assign = {
        email: email,
        group_id: props.role.id
      }
      showLoader();
      try {
          const res = await API.post('admin/roles/unassign_user_to_a_group/', assign);
          console.log(res)
          toast.success(res.data.details);
          hideLoader();
          window.location.reload();
      } catch (err) {
          hideLoader();
          toast.error("operation was not successful");
      }
    }
    // const switchApproval = async (e) => {
    //     e.preventDefault();
    //     showLoader();
    //     try {
    //         const res = await API.post('admin/wallet/let_admin_approve_withdrawals/', {});
    //         console.log(res)
    //         if (res.status == 200) {
    //             // setApproval(res.data.approval ? "yes" : "no")
    //         }
    //         window.location.reload();
    //         hideLoader();
    //         toast.success(res.data.detail);
    //     } catch (err) {
    //         hideLoader();
    //         toast.error("could not update the settings");
    //     }
    // }

    // const getAllRoles = async () => {
    //     showLoader();
    //     try {
    //         const res = await API.get('admin/roles/');
    //         console.log(res)
    //         if (res.status == 200) {
    //             console.log(res.data)
    //             setUserRoleList(res.data)
    //         }
    //         hideLoader();
    //     } catch (err) {
    //         hideLoader();
    //     }
    // }

    // const getSuperUser = async () => {
    //     showLoader()
    //     try {
    //         const res = await API.get('admin/roles/get_superusers/');
    //         console.log(res)
    //         if (res.status == 200) {
    //             console.log(res.data)
    //             setUserSuperUsersList(res.data)
    //         }
    //         hideLoader();
    //     } catch (err) {
    //         hideLoader();
    //     }
    // }

    useEffect(() => {
        // getAllRoles();
        // getSuperUser();
        
        return () => {
            console.log("")
        };
    }, []);

    return (

        <>
            <table
                className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                role="grid" aria-describedby="example_info">
                <thead>
                <tr role="row">
                    <th className="wd-15p sorting_asc" tabIndex="0"
                        aria-controls="example" rowSpan="1" colSpan="1"
                        aria-sort="ascending"
                        aria-label="First name: activate to sort column descending"
                        style={{width: "200px"}}>Name
                    </th>
                    <th className="wd-15p sorting" tabIndex="0"
                        aria-controls="example"
                        rowSpan="1" colSpan="1"
                        aria-label="Last name: activate to sort column ascending"
                        style={{width: "200px"}}>Email
                    </th>
                    <th className="wd-15p sorting" tabIndex="0"
                        aria-controls="example"
                        rowSpan="1" colSpan="1"
                        aria-label="Last name: activate to sort column ascending"
                        style={{width: "250px"}}>Action
                    </th>
                </tr>
                </thead>
                <tbody>
                {userData.map(item => (
                    <tr role="row" key={item.id}>
                        <td className="sorting_1">{item.first_name}{" "}{item.last_name}</td>
                        <td className="sorting_1">{item.email}</td>
                        <td>
                            <button
                                onClick={event => console.log(event)}
                                className="btn btn-orange btn-sm mt-1 mr-1"><i
                                className="fa fa-pencil ml-1"/> Change Role
                            </button>
                            <button
                                onClick={event => unassignUser(item.email)}
                                className="btn btn-orange btn-sm mt-1 mr-1"><i
                                className="fa fa-pencil ml-1"/> Unassign Role
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}