import React, {useEffect, useState, useContext} from 'react';
import {hideLoader, showLoader, toast} from "../../../_helpers/Loader";
import Layout from "../../Layout";
import {Context} from "../../MyContext";
import Moment from "react-moment";
import API from '../../../api';
import {SingleRoles} from './userUnderRoles';
import { Link } from 'react-router-dom';

export const Roles = () => {
    const [userRoleList, setUserRoleList] = useState([]);
    const [userSuperUsersList, setUserSuperUsersList] = useState([]);
    // const [singleRoles, setSingleRoles] = useState([]);
    const [showSingle, setShowSingle] = useState(false);
    const [singleItem, setSingleItem] = useState(false);
    const [modalPermList, setModalPermList] = useState([]);

    const [assign, setAssign] = useState({
        email: null,
        group_id: null
    })

    const context = useContext(Context)

    const usersWithRoles = () => {
        console.log("===-----===")
        return (
        <div>
            {"hELLO wORLD"}
        </div>
        )
    }

    const assignRole = async (e) => {
        e.preventDefault();
        console.log(assign, "assign-ok")
        if (!(assign.email) || !(assign.group_id)){
            toast.error("enter email and choose a group")
        }
        showLoader();
        try {
            const res = await API.post('admin/roles/assign_user_to_a_group/', assign);
            console.log(res)
            if (res.status == 200) {
                console.log("")
            }
            toast.success(res.data.details);
            hideLoader();
            window.location.reload();
        } catch (err) {
            hideLoader();
            toast.error("operation was not successful");
        }
    }

    const getAllRoles = async () => {
        showLoader();
        try {
            const res = await API.get('admin/roles/');
            console.log(res)
            if (res.status == 200) {
                console.log(res.data)
                setUserRoleList(res.data)
            }
            hideLoader();
        } catch (err) {
            hideLoader();
        }
    }

    const getSuperUser = async () => {
        showLoader()
        try {
            const res = await API.get('admin/roles/get_superusers/');
            console.log(res)
            if (res.status == 200) {
                console.log(res.data)
                setUserSuperUsersList(res.data)
            }
            hideLoader();
        } catch (err) {
            hideLoader();
        }
    }

    useEffect(() => {
        getAllRoles();
        getSuperUser();
        
        return () => {
            console.log("")
        };
    }, []);

    return (
        <Layout context={context} active_menu={'roles-and-permission'}>
            <div className="section">
                <div className="breadcrumb pr-0">
                    <div className="col-6 pl-0">
                        <h5>{showSingle ? (<i className="fa fa-chevron-left text-blue" aria-hidden="true" onClick={(e) => 
                        setShowSingle(false)                            
                        }></i>): ""}{" "}{"Roles & Permissions"}</h5>
                    </div>
                    <div className="col-6 text-right pl-0 row">
                        <div className="col-6 text-right pl-0"></div>
                        <div className="col-3 text-right pl-0">
                            <Link className="btn btn-primary btn-lg" to={"/roles-and-permission/create"} onClick={e => console.log("Create New Role")}> Create New Role</Link>
                        </div>
                        <div className="col-3 text-right pl-0">
                            <button className="btn btn-primary btn-lg" data-toggle="modal" data-target="#assignRoleModal" onClick={e => console.log("Assign Role to User")}> Assign Role to User</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h4>Roles and Count</h4>
                        </div>
                        <div className="card-body">
                        <div id="example_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer">
                        { !showSingle ? (
                            <table
                                className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                role="grid" aria-describedby="example_info">
                                <thead>
                                <tr role="row">
                                    <th className="wd-15p sorting_asc" tabIndex="0"
                                        aria-controls="example" rowSpan="1" colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="First name: activate to sort column descending"
                                        style={{width: "113px"}}>Name
                                    </th>
                                    <th className="wd-15p sorting" tabIndex="0"
                                        aria-controls="example"
                                        rowSpan="1" colSpan="1"
                                        aria-label="Last name: activate to sort column ascending"
                                        style={{width: "113px"}}>Level
                                    </th>
                                    <th className="wd-15p sorting" tabIndex="0"
                                        aria-controls="example"
                                        rowSpan="1" colSpan="1"
                                        aria-label="Last name: activate to sort column ascending"
                                        style={{width: "113px"}}>Description
                                    </th>
                                    <th className="wd-15p sorting_asc" tabIndex="0"
                                        aria-controls="example" rowSpan="1" colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="First name: activate to sort column descending"
                                        style={{width: "113px"}}>Number of Users
                                    </th>
                                    <th className="wd-15p sorting" tabIndex="0"
                                        aria-controls="example"
                                        rowSpan="1" colSpan="1"
                                        aria-label="Last name: activate to sort column ascending"
                                        style={{width: "113px"}}>Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr role="row" key={-1}>
                                    <td className="sorting_1"><Link to={"#"} data-toggle="modal" data-target="#veiwPermissions" onClick={e => {
                                            setModalPermList([{"name": "All permissions and more"}])
                                        }}>{"SuperAdmin"}{}</Link></td>
                                    <td className="sorting_1">{"Super Admin"}</td>
                                    <td className="sorting_1"><Link to={"#"} data-toggle="modal" data-target="#veiwPermissions" onClick={e => {
                                            setModalPermList([{"name": "All permissions and more"}])
                                        }}>{"All Permissions"}</Link></td>
                                    <td className="sorting_1">{userSuperUsersList?.length}</td>
                                    <td>
                                        <button
                                            onClick={event => {
                                                setShowSingle(true)
                                                setSingleItem(userSuperUsersList)
                                            }}
                                            className="btn btn-orange btn-sm mt-1 mr-1"><i
                                            className="fa fa-eye ml-1"/> View Users
                                        </button>
                                    </td>
                                </tr>
                                {userRoleList.map(item => (
                                    <tr role="row" key={item.id}>
                                        <td className="sorting_1"><Link to={"#"} data-toggle="modal" data-target="#veiwPermissions" onClick={e => {
                                            setModalPermList(item.permissions)
                                        }}>{item.name}</Link></td>
                                        <td className="sorting_1">{item.name}</td>
                                        <td className="sorting_1"><Link to={"#"} onClick={e => {
                                            setModalPermList(item.permissions)
                                        }} data-toggle="modal" data-target="#veiwPermissions">{"Selected Permissions"}</Link></td>
                                        <td className="sorting_1">{item.user_count}</td>
                                        <td>
                                            <button
                                                onClick={event => {
                                                    setShowSingle(true)
                                                    setSingleItem(item)
                                                }}
                                                className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                className="fa fa-eye ml-1"/> View Users
                                            </button>
                                            <Link
                                                to={"/roles-and-permission/create?edit="+item.id}
                                                className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                className="fa fa-pencil ml-1"/> Edit Role
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : <SingleRoles role={singleItem} />}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="assignRoleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="assignRoleModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-green">
                            <h4 className="modal-title" id="assignRoleModalLabel"> Assign Role To User </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="modal-body">
                            <div className="form-group">
                                <label htmlFor="">Enter User Email</label>
                                <input required type="email" className="form-control" onChange={e=>{
                                    setAssign({
                                        ...assign,
                                        email: e.target.value
                                    })
                                }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Select Role To Assign</label>
                                <select className="form-control" required onChange={e =>{
                                    console.log(e.target.value)
                                    setAssign({
                                        ...assign,
                                        group_id: e.target.value
                                    })
                                }}>
                                    <option>Choose</option>
                                    {userRoleList.map((item) => {return (<option value={item.id}>{item.name}</option>)})}
                                </select>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button className="btn btn-green" onClick={e => {
                                assignRole(e)
                            }}>Assign</button>
                            <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="veiwPermissions" tabIndex="-1" role="dialog"
                     aria-labelledby="veiwPermissionsLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-green">
                            <h4 className="modal-title" id="veiwPermissionsLabel"> Permissions </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {modalPermList.map(item => (<>
                                <li>{item.name}</li>
                            </>))}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}