import React, {useEffect, useState, useContext} from 'react';
import Layout from "../Layout";
import {Context} from "../MyContext";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import API from '../../api';
import {hideLoader, toast, showLoader} from "../../_helpers/Loader";
import Moment from "react-moment";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import extractDataFromExcel from '../investments/utils';

const Recipients = (props) => {
    const context = useContext(Context);
    const [mailList, setMailList] = useState([]);
    const [detailList, setDetailList] = useState([]);
    const [investments, setInvestments] = useState([]);
    const [remove_item, setRemove_item] = useState('');
    const [mode, setMode] = useState("create");
    const [stateL, setStateL] = useState({
        name: '',
        investment_id: false,
        email_from: '',
        emailList: '',
        file: '',
        page: 1
    });

    useEffect(() => {
        saveList()
        getInvestments()
        return () => {
            console.log("")
        };
    }, []);

    const saveList = async()=> {
        showLoader()
        try {
            let res = await API.get('admin/messages/mailing_list/', );
            console.log(res.data, "res.data ==>")
            setMailList(res.data);
        } catch (e) {
            // console.log(e.response);
        }
        hideLoader()
    }

    const getInvestments = async () => {
        showLoader()
        try {
            let res = await API.get('admin/investments/', );
            setInvestments(res.data);
          } catch (e) {
              // console.log(e.response);
          }
          hideLoader()
    }

    const getInvestors = async (id) => {
        showLoader()
        try {
            let res = await API.post('admin/messages/get_investors_list/', {investment_id: id});
            console.log(res.data)
            setStateL({...stateL, emailList: (res.data).join()});
          } catch (e) {
              // console.log(e.response);
          }
          hideLoader()
    }

    const csvProcess = async (file) => {
        console.log(file)
        try{
            // if (file.name.includes(".csv")){
            if (file){
                let datax = await extractDataFromExcel(file);
                let emList = []
                if (datax["sheet1"]){
                    datax["sheet1"].forEach(el => {
                        emList.push(`${el.name}|${el.email}`)
                    });
                } else {
                    datax["investors"].forEach(el => {
                        emList.push(`${el.name}|${el.email}`)
                    });
                }

                setStateL({
                    ...stateL,
                    emailList: emList.join()
                })
            } else {
                toast.error("Only csv format is allowed")
            }
        } catch(e){
            // console.log(e)
        }
    }

    const process = async (event, method) => {
        event.preventDefault();
        let postData = {
            name: stateL.name,
            email_from: stateL.email_from,
            email_list: stateL.emailList,
        };

        console.log(postData, "postData", mode, mode == "edit")
        showLoader();
        if (mode == "edit") {
            postData.investment_id = stateL.id 
            try {
                let res = await API.put('admin/messages/save_mailing_list/', postData);
                setStateL({...stateL, id: '', page: 1});
                await saveList();
                hideLoader()
            } catch (e) {
                console.log(e.response);
                hideLoader();
            }
        } else {
            try {
                let res = await API.post('admin/messages/save_mailing_list/', postData);
                toast.success(res.data.details||"List saved")
                setStateL({...stateL, id: '', page: 1});
                await saveList();
                // setStateL({...stateL, id: '', page: 1, exit: false});
                hideLoader()
            } catch (e) {
                toast.error("Error saving list")
                hideLoader();
            }
        }
    };

    const sendTest = async () => {
        try {
            let res = API.post(`admin/messages/${stateL.id}/send_test_mail/`, {email: stateL.test_email})
        } catch (e) {
            // console.log(e.response)
        }
    };

    const listPage = () => {
        return (
            <Layout context={context} active_menu={'recipient'} title={'Mails'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>Mails</h5>
                        </div>
                        <div className="col-sm-7 float-right text-right pr-0">
                            <div className='row'>
                                <div className='col-sm-6 float-right text-right pr-0'>
                                    
                                </div>
                                <div className='col-sm-6'>
                                    <button onClick={event => {
                                        setStateL({
                                            ...stateL,             
                                            name: '',
                                            investment_id: false,
                                            email_from: '',
                                            emailList: '',
                                            page: 2
                                        })
                                        setMode("create")
                                    }}
                                        className="btn btn-green mr-2">Add New
                                        List
                                    </button>
                                    <Link to="/send-mails"
                                            className="btn btn-green">Send Email
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Mailing List</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Name
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Source
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Count
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Recipients
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {mailList.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1">{item.name}</td>
                                                    <td className="sorting_1">{item.email_from}</td>
                                                    <td className="sorting_1">{item.email_list.split(",").length}</td>
                                                    <td className="sorting_1"><span className='truncate'>{item.email_list.slice(0, 50)}...</span></td>
                                                    <td className="sorting_1"><i className="fas fa-eye btn btn-primary" aria-hidden="true" onClick={(e)=> {
                                                        setDetailList(item.email_list.split(","))
                                                        setStateL({
                                                            ...stateL,
                                                            page: 3,
                                                            name: item.name
                                                        })
                                                    }}></i><span className="mx-1"></span><i className="fas fa-pencil-alt btn btn-info" aria-hidden="true" onClick={(e)=> {
                                                        setMode("edit")
                                                        setStateL({ ...stateL,
                                                            name: item.name,
                                                            email_from: item.email_from,
                                                            emailList: item.email_list,
                                                            id: item.id,
                                                            page: 2
                                                        })
                                                    }}></i></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    const addPage = () => {
        return (
            <Layout context={context} active_menu={'recipient'} title={'Add Mails'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => setStateL({...stateL, page: 1})}><span className="fa fa-chevron-left"/> Mail
                            List</a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>{mode == "create" ? "Create" : "Edit" } Mail</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => process(event)}>
                                        <div className="form-group">
                                            <h4>Name</h4>
                                            <input required defaultValue={stateL.name}
                                                   onChange={event => setStateL({...stateL, name: event.target.value})}
                                                   className="form-control" type="text"/>
                                        </div>
                                        <div className="form-group">
                                            <h4>Add Emails From</h4>
                                            <select className="form-control" name="email_from" onChange={event => {
                                                    setStateL({
                                                        ...stateL, 
                                                        email_from: event.target.value
                                                    })
                                                }}>
                                                <option></option>
                                                <option value="investment" selected={stateL.email_from.includes("investment") ? true : false }>Investment</option>
                                                <option value="csv" selected={stateL.email_from.includes("csv") ? true : false }>CSV</option>
                                                <option value="custom" selected={stateL.email_from.includes("custom") ? true : false }>Custom</option>
                                            </select>
                                        </div>
                                        {stateL.email_from.includes("investment") ? 
                                            <div className="form-group">
                                                <h4></h4>
                                                <select className="form-control" name="email_from" onChange={event => {
                                                        setStateL({
                                                            ...stateL, investment_id: event.target.value
                                                        })
                                                        getInvestors(event.target.value);
                                                    }}>
                                                    <option></option>
                                                    {
                                                        investments.map((inv) => {
                                                            return (
                                                                <option value={inv.id}>{inv.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        : stateL.email_from === "csv" ? 
                                            <div className="form-group">
                                                <h4>Upload a list <i className='text-danger'>.CSV is required, sample file required <a href="/sample_mail_list.csv">Downlaod</a></i></h4>
                                                <input type="file" onChange={event => {
                                                    csvProcess(event.target.files[0])
                                                }}
                                                    className="form-control" />
                                            </div>
                                        : ""}

                                        <div className="form-group">
                                            <h4>Listing (<code> <small>Enter email address of users separated by a comma</small> e.g firstname|email@gmail.com, firstname|email@gmail.com</code>)</h4>
                                            <textarea className="form-control" placeholder="firstname|email@gmail.com,lastname|last@gmail.com" rows="20"
                                            value={stateL.emailList} onChange={(e) => {
                                                setStateL({
                                                    ...stateL,
                                                    emailList: e.target.value
                                                })
                                            }}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green mr-3">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    const detailPage =() =>{
        return (
            <Layout context={context} active_menu={'recipient'} title={'Add Mails'}>
                <div className="section">
                    <div className="breadcrumb">
                        <h5><a onClick={event => setStateL({...stateL, page: 1})}><span className="fa fa-chevron-left"/> Mailing
                            List</a></h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>{stateL.name}</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Name
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Email
                                                </th>
                                                {/* <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th> */}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {detailList.map(item => (
                                                <tr role="row" key={item.split("|")[0]}>
                                                    <td className="sorting_1">{item.split("|")[0]}</td>
                                                    <td className="sorting_1">{item.split("|")[1]}</td>
                                                    {/* <td className="sorting_1"><i class="fa fa-trash btn btn-danger" aria-hidden="true" onClick={(e)=> {
                                                        setStateL({
                                                            ...stateL,
                                                            page: 3
                                                        })
                                                    }}></i></td> */}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }


    if (stateL.page === 1) {
        return listPage();
    } else if (stateL.page === 2) {
        return addPage();
    } else {
        return detailPage();
    }
}

export default Recipients;
