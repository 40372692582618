import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Moment from "react-moment";
import Layout from "../Layout";
import NumberFormat from "react-number-format";
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import API from "../../api";
import $ from "jquery";
import {Context} from "../MyContext";
import moment from 'moment';
import excludeList from '../excludeList';

class UserInvestments extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            portfolio: '',
            total_networth: '',
            total_amount: 0,
            total_return: 0,
            investments: [],
            percentage_interest: 0,
            activities: [],
            worth_direction: 0,
            target: 0,
            user: {},
            chart_data: {},
            previous_page: null,
            next_page: null,
            count: null,
            current_page: null,
            total_pages: null,
            search_value: '',
            multiple_data: [],
            investment_order: false,
            modal_investment_order: false
        };
    }

    async componentDidMount() {
        showLoader();
        await this.getUser();
        await this.getInvestmentStats();
        await this.getInvestment();
        hideLoader();
    }

    getUser = async () => {
        try {
            let res = await API.get(`admin/users/${this.props.match.params.id}/`);
            this.setState({user: res.data});
        } catch (e) {
            // console.log(e.response);
        }
    };

    getInvestmentStats = async () => {
        let postData = {
            user: this.props.match.params.id,
            page_limit: 0,
        };
        try {
            let res = await API.post('admin/investor_orders/get_user_investment_and_stats/', postData);
            if (res) {
                this.setState({
                    investments: [],
                    total_amount: res.data.total_amount,
                    total_amount_usd: res.data.total_amount_usd,
                    total_return: res.data.expected_return,
                    total_return_usd: res.data.expected_return_usd,
                    percentage_interest: (res.data.percentage) ? (res.data.percentage) : 0,
                    percentage_interest_usd: (res.data.percentage_usd) ? (res.data.percentage_usd) : 0
                });
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    };

    async getInvestment() {
        try {
            let res = await API.get(`/admin/users/${this.props.match.params.id}/get_users_investments/?page=1`);
            // console.log(">>", res.data)
            if (res) {
                this.setState({
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    investments: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                });
            }
        } catch (e) {
            // console.log(e.response);
        }
    }

    nextPage = async (page_num) => {
        showLoader();
        try {
            let res = await API.get(`/admin/users/${this.props.match.params.id}/get_users_investments/?page=` + page_num);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                investments: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    previousPage = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                investments: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    processDates(payout_dates, the_amount, roi, paid_times, payout_date_percentages) {
        let multiple_data = []
        let count = paid_times
        let dateToNum = this.dateToNum
        // payout_dates.sort(function (a, b) {
        //     return dateToNum(a) - dateToNum(b);
        // });
        // console.log(payout_dates)
        if (payout_date_percentages.length < 1){
            payout_dates.map((item, key) => {
                let amount = 0;
                if (key + 1 < payout_dates.length) {
                    amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100)
                } else {
                    amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100) + parseFloat(the_amount)
                }
                multiple_data.push({
                    date: item, paid: (count > 0), amount: amount
                })
                count = count - 1
            })
        } else {
            //New implemetation
            payout_dates.map((item, key) => {
                let amount = 0;
                if (key + 1 < payout_dates.length) {
                    amount = parseFloat(the_amount) * (payout_date_percentages[key] / 100)
                } else {
                    amount = parseFloat(the_amount) * (parseFloat(payout_date_percentages[key]) / 100) + parseFloat(the_amount)
                }
                multiple_data.push({
                    date: item, paid: (count > 0), amount: amount
                })
                count = count - 1
            })
        }

        this.setState({multiple_data: multiple_data})
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'user'} title={this.state.user.first + ' Investments'}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to={`/user/${this.props.match.params.id}`}> <span className="fa fa-chevron-left"/> User
                            Dashboard</Link>
                    </div>
                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">{this.state.user.first_name}'s Investments</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-bar-chart text-blue"/> <strong>Total Investment</strong> <i
                                                                        data-tip="Total Amount Invested"
                                                                        className="fa fa-info-circle text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-reply-all text-blue"/> <strong>Expected ROI</strong>  <i
                                                                        data-tip="Expected return on investment"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-signal text-blue"/> <strong>Interest</strong> <i
                                                                        data-tip="Total amount you will receive from your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-line-chart text-blue"/> <strong>Percentage Interest</strong> <i
                                                                        data-tip="Percentage Interest gained from  your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <div id="example_wrapper"
                                             className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                                        role="grid" aria-describedby="example_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style={{width: "113px"}}>Name
                                                            </th>
                                                            <th className="wd-15p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style={{width: "113px"}}>Amount
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Unit
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>ROI(%)
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Expected Return
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Purchased Date
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Maturity Date
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Status
                                                            </th>
                                                            <th className="wd-20p sorting" tabIndex="0"
                                                                aria-controls="example"
                                                                rowSpan="1" colSpan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style={{width: "231.4px"}}>Actions
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.investments.map(item => (
                                                            <tr key={item.id}>
                                                                <td>{item.investment.title}</td>
                                                                <td><NumberFormat
                                                                    renderText={value => <div>{value}</div>}
                                                                    prefix={'₦'} displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    value={item.amount}/>
                                                                </td>
                                                                <td>{item.purchased_units}</td>
                                                                <td>{item.roi} </td>
                                                                <td><NumberFormat
                                                                    renderText={value => <div>{value}</div>}
                                                                    prefix={'₦'} displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    value={item.expected_return}/></td>
                                                                <td><Moment
                                                                    format="MMM D, Y">{item.created_at}</Moment>
                                                                </td>
                                                                <td><Moment
                                                                    format="MMM D, Y">{item.maturity_date}</Moment>
                                                                </td>
                                                                <td>
                                                                {item.investment.id == 27 && excludeList.includes(this.state.user.email.toLowerCase()) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}
                                                                </td>
                                                                {/* {(item.club_tag) ?
                                                                    <td><span className="fa fa-list"/></td> :
                                                                    <td><span className="fa fa-ban"/></td>
                                                                } */}
                                                                <td>
                                                                    {(item.investment.club_tag || item.investment.investment_type === 'real estate fund') ?
                                                                        <Link to={`/user-investment/${item.id}/history`}> <span
                                                                            className="fa fa-list"/></Link>
                                                                        : item.investment.multiple_payout ? (
                                                                                    <span
                                                                                        onClick={event => {
                                                                                            this.processDates(item.investment.payout_dates, item.amount, item.investment.inv_roi, item.payout_times, item.investment.payout_date_percentages)

                                                                                            this.setState({investment_order: item})
                                                                                            console.log(item)
                                                                                        }}
                                                                                        data-target="#dateModal"
                                                                                        data-toggle="modal"
                                                                                        style={{'cursor': 'pointer'}}
                                                                                        className="fa fa-eye"/>
                                                                            ) : item.investment.in_trust ?
                                                                            (<Link to={`/user-investment/${item.id}/history`}> <span
                                                                            className="fa fa-eye" title={`This invesment is held in Trust by ${item.investment.trustee}`} /></Link>) : item.investment.partner.is_partner && item.investment.partner.company_name.toLowerCase().includes("risevest") ? (<Link to={`/user-investment/${item.id}/history`}> <span
                                                                            className="fa fa-eye"/></Link>) : ""
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-end">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.previousPage(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog"
                         aria-labelledby="dateModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="dateModalLabel">Payout Dates</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text-red text-center">{this.state.investment_order ? this.state.investment_order.roll_over == null && this.state.investment_order.investment.can_roll_over && 5 < (moment(this.state.investment_order.maturity_date).diff(moment(new Date()), 'days')) < 40 ? (<i className="text-primary text-center">* This investment will mature in {this.state.investment_order.maturity_date}. Do you want to roll over this investment? <br/><br/> 
                                <div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: false})} className="btn btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></i>) : "" : ""}</div>

                                <div className="list-group">
                                    {(this.state.multiple_data).map(item => (
                                        <a href="#"
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{item.date}</h5>
                                                <small>{(item.paid) ? 'Completed' : 'Upcoming payout'}</small>
                                            </div>
                                            <p className="mb-1">₦{item.amount.toLocaleString()}</p>
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default UserInvestments;
