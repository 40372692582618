import React, {Component} from 'react';
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {CSVLink} from "react-csv";
import Moment from "react-moment";
import Layout from "../Layout";
import API from '../../api';
import {showLoader, hideLoader, toast, Utils} from "../../_helpers/Loader";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import NumberFormat from "react-number-format";
import uuidv1 from 'uuid';
import {Context} from "../MyContext";
import $ from 'jquery';
import {modal} from 'bootstrap';
import Chart from 'chart.js';
import moment from 'moment';
import excludeList from '../excludeList';
import {Button} from 'react-bootstrap';
import UserSettings from './Settings';

let echarts = require('echarts');


class UserDetail extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            portfolio: '',
            total_networth: 0,
            total_amount: 0,
            total_amount_usd: 0,
            total_return: 0,
            total_return_usd: 0,
            total_networth_ngn: 0,
            total_networth_usd: 0,
            investments: [],
            percentage_interest: 0,
            percentage_interest_usd: 0,
            activities: [],
            worth_direction: 0,
            target: 0,
            user: {},
            chart_data: {},
            radial_colour: 'circle-success',
            wallet: {},
            amount_spent: 0,
            fund_amount: '',
            password_val: '',
            ref_code: '',
            withdraw_amount: '',
            switch_option: '',
            portfolio_currency: "",
            multiple_data: [],
            investment_order: false,
            modal_investment_order: false,
            settingsModal: false
        };
    }

    async componentDidMount() {
        showLoader();
        await this.getUser();
        await this.getWalletInfo();
        await this.getPortfolio();
        await this.getActivities();
        await this.getInvestment();
        await this.getPieInfo();
        ReactTooltip.rebuild();
        var maxHeight = Math.max.apply(null, $(".boxes .card").map(function () {
            return $(this).height();
        }).get());
        $(".boxes .card").height(maxHeight);
        hideLoader();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.quarterlyChart();
    }

    getUser = async () => {
        try {
            let res = await API.get(`admin/users/${this.props.match.params.id}/`);
            // console.log(res.data)
            this.setState({user: res.data});
        } catch (e) {
            // console.log(e.response);
        }
    };

    getWalletInfo = async () => {
        let spent_count = 0;
        try {
            let res = await API.get(`admin/wallet/${this.props.match.params.id}/get_wallet_balance/`, {params: {page: 5}});
            this.setState({wallet: res.data, amount_spent: res.data.amount_spent})
        } catch (e) {

        }
    };

    convertCurrency = (value, currency) => {
        if (currency === 'NGN') {
            return parseFloat(this.context.user.display_usd) * parseFloat(value);
        } else {
            return parseFloat(value) / parseFloat(this.context.user.display_usd);
        }
    }

    getPortfolio = async () => {
        let postData = {user: this.props.match.params.id};
        try {
            let res = await API.post('admin/portfolios/get_user_portfolio/', postData);
            let data = res.data;
            this.setState({portfolio: data});
            let networth_ngn = data.total_asset_value_ngn - data.total_liability_value_ngn;
            let networth_usd = data.total_asset_value_usd - data.total_liability_value_usd;
            let target_ngn = ((networth_ngn / this.state.user.target_networth) * 100).toFixed(2);
            if (this.state.user.target_networth <= 0 || target_ngn < 0) {
                target_ngn = 0
            }
            this.setState({total_networth_ngn: networth_ngn, total_networth_usd: networth_usd});
            let total_current = data.total_current;
            let total_total_cycle = data.total_total_cycle;
            if (this.state.user.user_type === 'tgem') {
                let total_current = data.total_current;
                let total_total_cycle = data.total_total_cycle;
                if (total_total_cycle == 0) {
                    this.setState({target: 0});
                } else {
                    let target_ngn = ((total_current / total_total_cycle) * 100).toFixed(2)
                    this.setState({target: (target_ngn != Infinity) ? (target_ngn) : 0});
                }
            } else {
                this.setState({target: (target_ngn != Infinity) ? (target_ngn) : 0});
            }
            (target_ngn < 0) && (this.setState({radial_colour: 'circle-danger'}));
            this.setState({
                chart_data: {
                    asset: data.assets_total_quarterly,
                    liability: data.liabilities_total_quarterly,
                    networth: data.quarterly_networth
                }
            });
        } catch (e) {

        }
    };

    getActivities = async () => {
        let postData = {
            user: this.props.match.params.id
        };
        try {
            let res = await API.post('admin/users/get_user_activity_logs/', postData);
            this.setState({activities: res.data.slice(0, 4)});
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    };

    getInvestment = async () => {
        let postData = {
            user: this.props.match.params.id,
            page_limit: 5,
        };
        try {
            let res = await API.post('admin/investor_orders/get_user_investment_and_stats/', postData);
            if (res) {
                this.setState({
                    investments: res.data.investments,
                    total_amount: res.data.total_amount,
                    total_amount_usd: res.data.total_amount_usd,
                    total_return: res.data.expected_return,
                    total_return_usd: res.data.expected_return_usd,
                    percentage_interest: (res.data.percentage) ? (res.data.percentage) : 0,
                    percentage_interest_usd: (res.data.percentage_usd) ? (res.data.percentage_usd) : 0
                });
            }
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response);
        }
    };

    // quarterlyChart() {
    //     let legend = {
    //         data: ['Net Worth', 'Asset', 'Liability'],
    //         bottom: 15,
    //     };
    //     let color = ['#23414D', '#47c4aa', '#e5323e'];
    //     let series = [
    //         {
    //             name: 'Net Worth',
    //             type: 'bar',
    //             barGap: 0,
    //             data: this.state.chart_data.networth,
    //         },
    //         {
    //             name: 'Asset',
    //             type: 'bar',
    //             barGap: 0,
    //             data: this.state.chart_data.asset,
    //         },
    //         {
    //             name: 'Liability',
    //             type: 'bar',
    //             barGap: 0,
    //             data: this.state.chart_data.liability,
    //         },
    //     ];
    //     let myChart = echarts.init(document.getElementById('chart'));
    //     myChart.setOption({
    //         grid: {
    //             top: '18%',
    //             left: '1%',
    //             right: '10%',
    //             bottom: '15%',
    //             containLabel: true
    //         },
    //         title: {
    //             text: 'Quarterly representation of net worth data',
    //             // subtext: 'Use this to see how your assets, liabilities, net worth has grown over past 4 quarters',
    //             right: 'auto',
    //             textStyle: {
    //                 fontSize: 16,
    //                 fontWeight: 'bolder',
    //                 fontFamily: "'Quicksand', 'Open Sans'"
    //             },
    //             subtextStyle: {
    //                 fontSize: 12,
    //                 color: '#23414D',
    //             },
    //             padding: [0, 0, 50, 0]
    //         },
    //         responsive: true,
    //         color: color,
    //         tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //                 type: 'shadow'
    //             }
    //         },
    //         xAxis: {
    //             data: ['Q1', 'Q2', 'Q3', 'Q4']
    //         },
    //         yAxis: [
    //             {
    //                 type: 'value',
    //             }
    //         ],
    //         legend: legend,
    //         series: series
    //     });
    // }


    newPassword = async (event) => {
        event.preventDefault();
        let postData = {
            email: this.state.user.email,
            token: this.state.password_val,
        };
        showLoader();
        try {
            let res = API.post('admin/users/user_password_reset/', postData);
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            hideLoader();
        } catch (e) {
            if (e.status === 400) {
                toast.info("Unauthorized");
            } else {
                toast.error("Something went wrong");
            }
            hideLoader();
        }
    };


    fundWallet = async (e) => {
        e.preventDefault();
        let postData = {
            user_id: this.state.user.id,
            amount: this.state.fund_amount,
        };
        if (this.state.ref_code !== "") {
            postData['ref_code'] = this.state.ref_code
        }
        showLoader();
        try {
            let res = await API.post('admin/wallet/fund_user_wallet/', postData);
            await this.getWalletInfo();
            this.setState({fund_amount: ''});
            document.getElementById('close-modal').click();
            toast.success("User's wallet funded successfully");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    withdrawWallet = async (e) => {
        e.preventDefault();
        let postData = {
            user_id: this.state.user.id,
            amount: this.state.withdraw_amount,
        };
        showLoader();
        try {
            let res = await API.post('admin/wallet/withdraw_wallet/', postData);
            await this.getWalletInfo();
            this.setState({withdraw_amount: ''});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("User's wallet withdrawn successfully");
            hideLoader();
        } catch (e) {
            // console.log(e.response)
            hideLoader();
        }
    };

    sendNotification = async () => {
        let postData = {
            user_id: this.state.user.id,
            amount: this.state.withdraw_amount,
        };
        if (this.state.withdraw_amount === '') {
            toast.info('Enter an amount');
            return false
        }
        showLoader();
        try {
            let res = await API.post('admin/wallet/send_notification/', postData);
            this.setState({withdraw_amount: ''});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            toast.success("Notification sent successfully");
            hideLoader();
        } catch (e) {
            // console.log(e.response)
            toast.error("Something went wrong");
            hideLoader();
        }
    };

    handleSwitch = async event => {
        const postData = {
            'option': this.state.switch_option
        }
        showLoader();
        try {
            const res = await API.post(`admin/users/${this.state.user.id}/switch_user/`, postData)
            this.setState({user: res.data});
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            hideLoader();
            toast.success("User successfully switched");
        } catch (e) {
            hideLoader();
        }
    }

    quarterlyChart(labels, dataset, is_empty) {
        var canvas = document.getElementById("myChart");
        canvas.style.width = '110%';
        var ctx = canvas.getContext('2d');
        let title = 'Add or buy asset to see your asset distribution chart here.';
        var options = {
            title: {
                display: true,
                text: title,
                position: 'top'
            },
            legend: {
                position: 'bottom'
            },
            responsive: true,
            maintainAspectRatio: true,
            rotation: -0.7 * Math.PI,
            animation: {
                duration: 500,
                easing: "easeOutQuart",
                onComplete: function () {
                    var ctx = this.chart.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset) {

                        for (var i = 0; i < dataset.data.length; i++) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                                start_angle = model.startAngle,
                                end_angle = model.endAngle,
                                mid_angle = start_angle + (end_angle - start_angle) / 2;

                            var x = mid_radius * Math.cos(mid_angle);
                            var y = mid_radius * Math.sin(mid_angle);

                            ctx.fillStyle = '#fff';
                            var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                            //Don't Display If Legend is hide or value is 0
                            if (dataset.data[i] != 0) {
                                // Display percent in another line, line break doesn't work for fillText
                                ctx.fillText(percent, model.x + x, model.y + y + 15);
                            }
                        }
                    });
                }
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {

                        let label = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return ` ${label}: ₦${value.toLocaleString()}`

                    }
                }
            }
        };
        if (!is_empty) {
            delete options['title']
        }
        var oilData = {
            labels: labels,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: [
                        "#47C4AA",
                        "#23414D",
                        "#FB703E",
                        "#F2BF5E",
                        "#F28B50",
                        "#fcb8a9",
                        "#177f68",
                        "#41738b",
                        "#99d249",
                        "#d2fb3e",
                        "#3e57fb"
                    ]
                }]
        };

        var pieChart = new Chart(ctx, {
            type: 'pie',
            data: oilData,
            options: options
        });

    }

    getPieInfo = async () => {
        try {
            const res = await API.post('admin/portfolios/get_user_portfolio_stats/', {user: this.props.match.params.id})
            let data = res.data;
            let labels = Object.keys(data), dataset = [];
            labels = labels.filter(item => item !== 'is_empty');
            labels.map(item => {
                dataset.push(data[item])
            })
            this.quarterlyChart(labels, dataset, data.is_empty)
        } catch (e) {
            // console.log(e.response)
        }
    }

    processDates(payout_dates, the_amount, roi, paid_times, payout_date_percentages) {
        let multiple_data = []
        let count = paid_times
        let dateToNum = this.dateToNum
        // payout_dates.sort(function (a, b) {
        //     return dateToNum(a) - dateToNum(b);
        // });
        // console.log(payout_dates)
        if (payout_date_percentages.length < 1){
            payout_dates.map((item, key) => {
                let amount = 0;
                if (key + 1 < payout_dates.length) {
                    amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100)
                } else {
                    amount = parseFloat(the_amount) * ((parseFloat(roi) / payout_dates.length) / 100) + parseFloat(the_amount)
                }
                multiple_data.push({
                    date: item, paid: (count > 0), amount: amount
                })
                count = count - 1
            })
        } else {
            //New implemetation
            payout_dates.map((item, key) => {
                let amount = 0;
                if (key + 1 < payout_dates.length) {
                    amount = parseFloat(the_amount) * (payout_date_percentages[key] / 100)
                } else {
                    amount = parseFloat(the_amount) * (parseFloat(payout_date_percentages[key]) / 100) + parseFloat(the_amount)
                }
                multiple_data.push({
                    date: item, paid: (count > 0), amount: amount
                })
                count = count - 1
            })
        }

        this.setState({multiple_data: multiple_data})
    }

    handleClose = () => {
        this.setState({settingsModal: !this.state.settingsModal})
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'user'}
                    title={this.state.user.first_name + ' ' + this.state.user.last_name}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-sm-6 ml-0 pl-0">
                            <h5><Link to="/users"><span className="fa fa-chevron-left"/> Users</Link></h5>
                        </div>
                        {this.context.user.is_superuser ? (
                            <div className="col-sm-6 mr-0 pr-0">
                                <div className='row'>
                                    <div className='col-4 mr-0 pr-0'>
                                        <div className='form-group'>
                                            <select name="switch_user" onChange={(e) => this.setState({switch_option: e.target.value})} className="form-control">
                                                <option value="">-- Switch User To --</option>
                                                {this.state.user.user_type === 'twelve' ? "": <option value="twelve">Twelve</option>}
                                                {this.state.user.user_type === 'tgic' ? "": <option value="tgic">TGIC</option>}
                                                {this.state.user.user_type === 'twelveblack' ? "": <option value="twelveblack">Twelve Black</option>}
                                                {this.state.user.user_type === 'fwp' ? "": <option value="fwp">FWP</option>}

                                            </select>
                                        </div>
                                        <small>Current Profile: <span className="text-uppercase" style={{"color": "#1F5277"}}>({(this.state.user && this.state.user.user_type)})</span></small>
                                    </div>
                                    <div className='col-2 ml-0 pl-0'>
                                        <button className="btn btn-primary" data-toggle="modal" data-target="#greenModal" style={{padding:"6px 12px"}} disabled={this.state.switch_option == '' ? true : false}>Switch</button>
                                    </div>
                                    <div className='col-6'>
                                        <button
                                            onClick={event => this.setState({password_val: Math.round((Math.pow(36, 10 + 1) - Math.random() * Math.pow(36, 10))).toString(36).slice(1)})}
                                            data-toggle="modal" data-target="#resetModal"
                                            className="float-right btn btn-blue btn-block"><span style={{fontSize: "11px"}}>Reset User Password</span>
                                        </button>
                                    </div>
                                    {/* <div className='col-2'>
                                        <Button variant='primary' style={{fontSize: "11px"}}
                                            data-toggle="modal" data-target="#resetModal"
                                            className="float-right btn btn-blue btn-block">View Settings
                                        </Button>
                                    </div> */}
                                </div>
                            </div>) : ''}
                    </div>
                    <div className="row" id="portfolio">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h4>{this.state.user.first_name}'s Portfolio <b
                                                className="ml-2">{this.state.user.email} <span
                                                data-toggle="modal"
                                                data-target="#infoModal"
                                                className="badge badge-secondary pointer ml-2 my-auto"><i
                                                className="fa fa-info-circle"/></span></b></h4>
                                        </div>
                                        <div className="col-sm-2">
                                            <Button variant='primary' style={{fontSize: "11px"}}
                                                onClick={(e) => this.setState({settingsModal:true})}
                                                className="float-right btn btn-blue">View Settings
                                            </Button>
                                        </div>
                                        <div className="col-sm-5">
                                            <select
                                                onChange={event => this.setState({portfolio_currency: event.target.value})}
                                                value={this.state.portfolio_currency}
                                                className="form-control col-lg-7 col-xl-5 float-right pulse-out float-right">
                                                <option value="">Total NGN and Total USD</option>
                                                <option value="NGN">Total NGN value</option>
                                                <option value="USD">Total USD value</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 mx-auto justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5
                                                className="text-blue"
                                                style={{
                                                    fontWeight: 'bolder',
                                                    fontSize: '16px'
                                                }}>    &nbsp;
                                            </h5>
                                            </div>
                                            <div
                                                data-tip={`You have achieved ${this.state.target}% of your net worth goal`}>
                                                <CircularProgressbar
                                                    className={`mx-auto ${this.state.radial_colour} text-center`}
                                                    styles={{width: '70%'}}
                                                    value={parseInt(this.state.target).toFixed(2)}
                                                    text={`${parseInt(this.state.target)}%`}>
                                                </CircularProgressbar></div>
                                            <br/><br/>
                                            <strong className="text-blue">{parseInt(this.state.target)}% of
                                                target
                                                net worth achieved.</strong>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 justify-content-center text-center">
                                            <div className="mb-4 mt-4 circular-chart"><h5
                                                className="text-blue"
                                                style={{
                                                    fontWeight: 'bolder',
                                                    fontSize: '16px'
                                                }}>Breakdown of total assets</h5>
                                            </div>
                                            <div className="" style={{marginBottom: '2.5rem'}}></div>
                                            <div className="chart-container">
                                                <canvas id="myChart"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {(this.state.portfolio_currency === "NGN") ? (
                                                                <strong>₦{parseFloat(parseFloat(this.state.total_networth_ngn) + this.convertCurrency(this.state.total_networth_usd, 'NGN')).toLocaleString()}</strong>
                                                            ) : (this.state.portfolio_currency === "USD") ? (
                                                                <strong>${parseFloat(parseFloat(this.state.total_networth_usd) + this.convertCurrency(this.state.total_networth_ngn, 'USD')).toLocaleString()}</strong>
                                                            ) : (
                                                                <strong>₦{parseFloat(parseFloat(this.state.total_networth_ngn).toFixed(2)).toLocaleString()}</strong>
                                                            )}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Current Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {(this.state.portfolio_currency === "NGN") ? (
                                                                <strong>₦{parseFloat(parseFloat(this.state.user.target_networth).toFixed(2)).toLocaleString()}</strong>
                                                            ) : (this.state.portfolio_currency === "USD") ? (
                                                                <strong>${parseFloat(this.convertCurrency(this.state.user.target_networth, "USD").toFixed(2)).toLocaleString()}</strong>
                                                            ) : (
                                                                <strong>₦{parseFloat(parseFloat(this.state.user.target_networth).toFixed(2)).toLocaleString()}</strong>
                                                            )}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Target Net Worth</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {(this.state.portfolio_currency === "NGN") ? (
                                                                <strong>₦{parseFloat((parseFloat(this.state.portfolio.total_asset_value_ngn) + this.convertCurrency(this.state.portfolio.total_asset_value_usd, "NGN")).toFixed(2)).toLocaleString()}</strong>
                                                            ) : (this.state.portfolio_currency === "USD") ? (
                                                                <strong>${parseFloat((parseFloat(this.state.portfolio.total_asset_value_usd) + this.convertCurrency(this.state.portfolio.total_asset_value_ngn, "USD")).toFixed(2)).toLocaleString()}</strong>
                                                            ) : (
                                                                <strong>₦{parseFloat(parseFloat(this.state.portfolio.total_asset_value_ngn).toFixed(2)).toLocaleString()}</strong>
                                                            )}
                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Assets</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="list-group">
                                                <a href="#"
                                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-sm-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">
                                                            {(this.state.portfolio_currency === "NGN") ? (
                                                                <strong>₦{parseFloat((parseFloat(this.state.portfolio.total_liability_value_ngn) + this.convertCurrency(this.state.portfolio.total_liability_value_usd, "NGN")).toFixed(2)).toLocaleString()}</strong>
                                                            ) : (this.state.portfolio_currency === "USD") ? (
                                                                <strong>${parseFloat((parseFloat(this.state.portfolio.total_liability_value_usd) + this.convertCurrency(this.state.portfolio.total_liability_value_ngn, "USD")).toFixed(2)).toLocaleString()}</strong>
                                                            ) : (
                                                                <strong>₦{parseFloat(parseFloat(this.state.portfolio.total_liability_value_ngn).toFixed(2)).toLocaleString()}</strong>
                                                            )}

                                                        </h5>
                                                    </div>
                                                    <p className="mb-1">Total Liability</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.portfolio_currency === "") ? (
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                <strong>${parseFloat(parseFloat(this.state.total_networth_usd).toFixed(2)).toLocaleString()}</strong>
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Current Net Worth</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                <strong>${parseFloat((this.convertCurrency(this.state.user.target_networth, "USD")).toFixed(2)).toLocaleString()}</strong>
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Target Net Worth</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                <strong>${parseFloat(parseFloat(this.state.portfolio.total_asset_value_usd).toFixed(2)).toLocaleString()}</strong>
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Total Assets</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="list-group">
                                                    <a href="#"
                                                       className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-sm-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">
                                                                <strong>${parseFloat(parseFloat(this.state.portfolio.total_liability_value_usd).toFixed(2)).toLocaleString()}</strong>
                                                            </h5>
                                                        </div>
                                                        <p className="mb-1">Total Liability</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">{this.state.user.first_name}'s Investments</h4>
                                        </div>
                                        <div
                                            className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">
                                            <Link to={`/user/${this.props.match.params.id}/investments`}
                                                  className="btn m-b-5 btn-blue"> View All
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span className="text-blue"><i
                                                                        className="fa fa-bar-chart text-blue"/> <strong>Total Investment</strong> <i
                                                                        data-tip="Total Amount Invested"
                                                                        className="fa fa-info-circle text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-reply-all text-blue"/> <strong>Expected ROI</strong>  <i
                                                                        data-tip="Expected return on investment"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-signal text-blue"/> <strong>Interest</strong> <i
                                                                        data-tip="Total amount you will receive from your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">₦{parseFloat(parseFloat(this.state.total_return - this.state.total_amount).toFixed(2)).toLocaleString()}</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">${parseFloat(parseFloat(this.state.total_return_usd - this.state.total_amount_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><i
                                                                        className="fa fa-line-chart text-blue"/> <strong>Percentage Interest</strong> <i
                                                                        data-tip="Percentage Interest gained from  your investments"
                                                                        className="fa fa-info-circle text-right text-blue"/></span>
                                                                <h3 className="text-blue mb-0 mt-2">{parseFloat(this.state.percentage_interest).toFixed(2)}%</h3>
                                                                <hr className="line-2"/>
                                                                <h3 className="text-blue mb-0">{parseFloat(parseFloat(this.state.percentage_interest_usd).toFixed(2)).toLocaleString()}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Unit</th>
                                                <th>ROI(%)</th>
                                                <th>Expected Return</th>
                                                <th>Purchased Date</th>
                                                <th>Maturity Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            {this.state.investments.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.investment.title}</td>
                                                    <td data-tip={(item.currency === "USD") ? `Rate at ₦${parseFloat(item.amount_ngn / item.amount).toLocaleString()}` : parseFloat(item.amount).toFixed(2).toLocaleString()}>
                                                        <NumberFormat
                                                            renderText={value => <div>{value}</div>}
                                                            prefix={(item.currency === "NGN") ? "₦" : "$"}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            value={parseFloat(item.amount).toFixed(2)}/>
                                                    </td>
                                                    <td>{item.purchased_units}</td>
                                                    <td>{item.roi}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.expected_return)}/></td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.maturity_date}</Moment>
                                                    </td>
                                                    <td>
                                                        {item.investment.id == 27 && excludeList.includes(this.state.user.email.toLowerCase()) ? "Active" : (item.investment.payout_status == "pending" || item.investment.payout_status == "none" ? "Active" : "Completed")}

                                                    </td>
                                                    {/* {(item.club_tag) ?
                                                        <td><span className="fa fa-list"/></td> :
                                                        <td><span className="fa fa-ban"/></td>
                                                    } */}
                                                    <td>
                                                        {(item.investment.club_tag || item.investment.investment_type === 'real estate fund') ?
                                                            <Link to={`/user-investment/${item.id}/history`}> <span
                                                                className="fa fa-list"/></Link>
                                                            : item.investment.multiple_payout ? (
                                                                        <span
                                                                            onClick={event => {
                                                                                this.processDates(item.investment.payout_dates, item.amount, item.investment.inv_roi, item.payout_times, item.investment.payout_date_percentages)

                                                                                this.setState({investment_order: item})
                                                                                console.log(item)
                                                                            }}
                                                                            data-target="#dateModal"
                                                                            data-toggle="modal"
                                                                            style={{'cursor': 'pointer'}}
                                                                            className="fa fa-eye"/>
                                                                ) : item.investment.in_trust ?
                                                                (<Link to={`/user-investment/${item.id}/history`}> <span
                                                                className="fa fa-eye" title={`This invesment is held in Trust by ${item.investment.trustee}`} /></Link>) : item.investment.partner.is_partner && item.investment.partner.company_name.toLowerCase().includes("risevest") ? (<Link to={`/user-investment/${item.id}/history`}> <span
                                                                className="fa fa-eye"/></Link>) : ""
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="float-left">{this.state.user.first_name}'s Wallet</h4>
                                        </div>
                                        <div
                                            className="col-lg-6 col-sm-12 text-xl-right text-lg-right text-md-left">
                                            {(this.context.user.is_superuser) ? (
                                                <React.Fragment>
                                                    <button data-toggle="modal" data-target="#fundModal"
                                                            className="btn btn-blue m-b-5 mr-3">Fund
                                                        Wallet
                                                    </button>
                                                    <button data-toggle="modal" data-target="#withdrawModal"
                                                            className="btn btn-blue m-b-5 mr-3">Withdrawal
                                                    </button>
                                                    <Link to={`/user/${this.props.match.params.id}/wallet`}
                                                          className="btn m-b-5 btn-blue"> View All
                                                    </Link>
                                                </React.Fragment>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row boxe">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-bar-chart text-blue"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right text-blue"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Amount in user's wallet"
                                                               className="fa fa-info-circle text-right float-right text-blue"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-blue"><strong>Current Balance</strong></span>
                                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.wallet.current_balance)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-reply-all text-blue"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right text-blue"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total amount that has been spent"
                                                               className="fa fa-info-circle text-right text-blue"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-blue"><strong>Total Amount Spent</strong></span>
                                                                <h2 className="text-blue mb-0">{Utils.Currency(this.state.amount_spent)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Reference Number</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Balance</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                            {(this.state.wallet.history !== undefined) ? (this.state.wallet.history.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.ref_code}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.amount)}/>
                                                    </td>
                                                    <td>{(item.status.toLowerCase() === 'credit') ? (
                                                        <span className="badge bg-green m-b-5">Credit</span>
                                                    ) : (item.status.toLowerCase() === 'debit') ? (
                                                        <span className="badge bg-yellow m-b-5">Debit</span>
                                                    ) : (item.status.toLowerCase() === 'processing') ? (
                                                        <span
                                                            className="badge bg-dark m-b-5 text-white">Processing</span>
                                                    ) : (
                                                        <span className="badge badge-danger m-b-5">Failed</span>
                                                    )}
                                                    </td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.balance)}/>
                                                    </td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                    <td><Moment
                                                        format="HH:ss">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))) : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="activities">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">{this.state.user.first_name}'s Activities</h4>
                                    {/*<Link to="/activities" className="btn m-b-5 btn-orange float-right"> View All*/}
                                    {/*</Link>*/}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                                <tbody>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                </tr>
                                                {this.state.activities.map(item => (
                                                    <tr key={uuidv1()}>
                                                        <td>{item.action}</td>
                                                        <td><Moment
                                                            format="MMM D Y">{item.created_at}</Moment>
                                                        </td>
                                                        <td><Moment
                                                            format="HH:mm">{item.created_at}</Moment>
                                                        </td>
                                                    </tr>
                                                ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="activities">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">{this.state.user.first_name}'s last 5 logins</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                                <tbody>
                                                <tr>
                                                    <th>Ip Address</th>
                                                    <th>Browser</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                </tr>
                                                {(this.state.user.user_login !== undefined) ? (
                                                    this.state.user.user_login.slice(0, 5).map(item => (
                                                        <tr key={uuidv1()}>
                                                            <td>{item.ip}</td>
                                                            <td data-tip={item.browser} style={{
                                                                overflow: 'hidden',
                                                                maxWidth: '20ch',
                                                                textOverflow: 'ellipsis'
                                                            }}>{item.browser}</td>
                                                            <td><Moment
                                                                format="MMM D Y">{item.created_at}</Moment>
                                                            </td>
                                                            <td><Moment
                                                                format="HH:mm">{item.created_at}</Moment>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : ''}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.context.user.is_superuser) ? (
                    <div>
                        <div className="modal fade" id="fundModal" tabIndex="-1" role="dialog"
                             aria-labelledby="fundModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h4 className="modal-title" id="fundModalLabel">Fund Wallet</h4>
                                        <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={event => this.fundWallet(event)}>
                                            <div className="form-group">
                                                <label>Amount:</label>
                                                <NumberFormat required className="form-control"
                                                              value={this.state.fund_amount}
                                                              thousandSeparator={true}
                                                              prefix={'₦'} onValueChange={(values) => {
                                                    const {value} = values;
                                                    this.setState({fund_amount: value})
                                                }}/>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-green mr-3"
                                                        data-dismiss="modal">Close
                                                </button>
                                                <button type="submit" className="btn btn-dark">Fund</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="withdrawModal" tabIndex="-1" role="dialog"
                             aria-labelledby="withdrawModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h4 className="modal-title" id="withdrawModalLabel">Withdraw from Wallet</h4>
                                        <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={event => this.withdrawWallet(event)}>
                                            <div className="form-group">
                                                <label>Amount:</label>
                                                <NumberFormat required className="form-control"
                                                              value={this.state.withdraw_amount}
                                                              thousandSeparator={true}
                                                              prefix={'₦'} onValueChange={(values) => {
                                                    const {value} = values;
                                                    this.setState({withdraw_amount: value})
                                                }}/>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-green mr-3"
                                                        data-dismiss="modal">Close
                                                </button>
                                                <button
                                                    disabled={!(this.state.wallet.current_balance && parseFloat(this.state.wallet.current_balance) >= parseFloat(this.state.withdraw_amount))}
                                                    type="submit" className="btn btn-dark">Withdraw
                                                </button>
                                                <button
                                                    disabled={!(parseFloat(this.state.withdraw_amount) > 0)}
                                                    type="button" onClick={event => this.sendNotification(event)}
                                                    className="btn btn-success">Send Notification <i
                                                    className="fa fa-send"/></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="resetModal" tabIndex="-1" role="dialog"
                             aria-labelledby="resetModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h4 className="modal-title" id="resetModalLabel">Initiate Password Reset</h4>
                                        <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <form onSubmit={event => this.newPassword(event)}>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label>New Password:</label>
                                                <input type="text"
                                                       value={this.state.password_val}
                                                       disabled className="form-control"/>
                                            </div>
                                            <p className="text-orange">This password will be sent to user's email</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-outline-green mr-3"
                                                    data-dismiss="modal">Close
                                            </button>
                                            <button type="submit" className="btn btn-dark">Reset and Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="greenModal" tabIndex="-1" role="dialog"
                             aria-labelledby="greenModalLabel">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-green">
                                        <h5 className="modal-title" id="greenModalLabel">Switch to
                                            a <span
                                                className="text-capitalize font-weight-bold">{this.state.switch_option}</span> user
                                        </h5>
                                        <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        Are you sure you want to switch this user to a <strong
                                        className="text-capitalize">{this.state.switch_option} profile</strong>?
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-green mr-3"
                                                data-dismiss="modal">Close
                                        </button>
                                        <button type="button" onClick={event => this.handleSwitch(event)}
                                                className="btn btn-dark">Switch
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.user.user_bank !== undefined) ? (
                            <div className="modal fade" id="infoModal" tabIndex="-1" role="dialog"
                                 aria-labelledby="infoModalLabel">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header bg-green">
                                            <h4 className="modal-title" id="infoModalLabel">Extra User Information <sup>coming
                                                soon</sup></h4>
                                            <button id="close-modal" type="button" className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <dl className="row">
                                                {(this.state.user.user_type === 'twelve') ? (
                                                    <React.Fragment>
                                                        <dt className="col-sm-6">Spending Limit</dt>
                                                        <dd className="col-sm-3">{this.state.user.investment_goal}</dd>

                                                        <dt className="col-sm-6">Preferred Investment Location</dt>
                                                        <dd className="col-sm-6">{this.state.user.real_state_preferred_location}</dd>
                                                    </React.Fragment>
                                                ) : ''}
                                                {(this.state.user.user_bank.length > 0) ? (
                                                    <React.Fragment>
                                                        <dt className="col-sm-6">Account Name</dt>
                                                        <dd className="col-sm-6 text-capitalize">{this.state.user.user_bank[0].account_name}
                                                        </dd>

                                                        <dt className="col-sm-6">Account Number</dt>
                                                        <dd className="col-sm-6">{this.state.user.user_bank[0].account_number}
                                                        </dd>

                                                        <dt className="col-sm-6">Bank</dt>
                                                        <dd className="col-sm-6">{this.state.user.user_bank[0].bank_name}</dd>
                                                    </React.Fragment>
                                                ) : ''}
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}

                    </div>
                ) : (
                    ''
                )}
                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog"
                        aria-labelledby="dateModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="dateModalLabel">Payout Dates</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text-red text-center">{this.state.investment_order ? this.state.investment_order.roll_over == null && this.state.investment_order.investment.can_roll_over && 5 < (moment(this.state.investment_order.maturity_date).diff(moment(new Date()), 'days')) < 40 ? (<i className="text-primary text-center">* This investment will mature in {this.state.investment_order.maturity_date}. Do you want to roll over this investment? <br/><br/> 
                                <div className="text-center"><button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: false})} className="btn btn-negative-primary btn-outline-primary text-primary"> No </button>&nbsp;&nbsp;<button onClick={ e => this.updateInvestmentOrder(e, this.state.investment_order.id, {roll_over: true, roll_over_date: new Date().toISOString().split('T')[0]})} className="btn-primary btn"> Yes </button><br/><br/></div></i>) : "" : ""}</div>

                                <div className="list-group">
                                    {(this.state.multiple_data).map(item => (
                                        <a href="#"
                                            className="list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{item.date}</h5>
                                                <small>{(item.paid) ? 'Completed' : 'Upcoming payout'}</small>
                                            </div>
                                            <p className="mb-1">₦{item.amount.toLocaleString()}</p>
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn no-outline-green"
                                        data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <UserSettings show={this.state.settingsModal} user={this.state.user} handleClose={this.handleClose} />
            </Layout>
        );
    }
}

export default UserDetail;
