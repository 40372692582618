import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Moment from "react-moment";
import Layout from "../Layout";
import NumberFormat from "react-number-format";
import {hideLoader, showLoader, toast, Utils} from "../../_helpers/Loader";
import API from "../../api";
import $ from "jquery";
import {Context} from "../MyContext";

class UserInvestments extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            amount_spent: null,
            wallet: '',
            user: {},
            history: [],
            previous_page: null,
            next_page: null,
            count: null,
            current_page: null,
            total_pages: null,
        };
    }

    async componentDidMount() {
        showLoader();
        await this.getUser();
        await this.getWalletInfo();
        await this.getWalletLog();
        hideLoader();
    }

    getWalletInfo = async () => {
        let spent_count = 0;
        try {
            let res = await API.get(`admin/wallet/${this.props.match.params.id}/get_wallet_balance/`, {params: {page: 0}});
            this.setState({wallet: res.data, amount_spent: res.data.amount_spent})
        } catch (e) {

        }
    };

    getUser = async () => {
        try {
            let res = await API.get(`admin/users/${this.props.match.params.id}/`);
            this.setState({user: res.data});
        } catch (e) {
            // console.log(e.response);
        }
    };

    async getWalletLog() {
        try {
            let res = await API.get(`admin/wallet/${this.props.match.params.id}/get_wallet_logs/?page=1`);
            if (res) {
                this.setState({
                    next_page: res.data.next,
                    previous_page: res.data.previous,
                    history: res.data.results,
                    total_pages: res.data.total_pages,
                    current_page: res.data.current_page,
                });
            }
        } catch (e) {
            // console.log(e.response);
        }
    }

    nextPage = async (page_num) => {
        showLoader();
        try {
            let res = await API.get(`admin/wallet/${this.props.match.params.id}/get_wallet_logs/?page=` + page_num);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                history: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    previousPage = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                history: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    render() {
        return (
            <Layout context={this.context} active_menu={'user'} title={this.state.user.first + ' Wallet'}>
                <div className="section">
                    <div className="breadcrumb">
                        <Link to={`/user/${this.props.match.params.id}`}> <span className="fa fa-chevron-left"/> User
                            Dashboard</Link>
                    </div>
                    <div className="row" id="investment">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="float-left">{this.state.user.first_name}'s Wallet</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row boxes">
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-bar-chart"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Amount in user's wallet"
                                                               className="fa fa-info-circle text-right float-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                <span
                                                                    className="text-white"><strong>Current Balance</strong></span>
                                                                <h2 className="text-white mb-0">{Utils.Currency(this.state.wallet.current_balance)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3 col-md-6 col-12">
                                            <div className="card bg-blue-gray text-blue">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{paddingLeft: 0}}>
                                                            <i className="fa fa-reply-all"/>
                                                        </div>
                                                        <div className="col-md-6 float-right text-right"
                                                             style={{paddingRight: 0}}>
                                                            <i data-tip="Total amount that has been spent"
                                                               className="fa fa-info-circle text-right"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mt-3 text-center">
                                                                    <span
                                                                        className="text-white"><strong>Total Amount Spent</strong></span>
                                                                <h2 className="text-white mb-0">{Utils.Currency(this.state.amount_spent)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-responsive-xl table-striped mb-0 text-nowrap">
                                            <tbody>
                                            <tr>
                                                <th>Reference Number</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Balance</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                            {(this.state.history !== undefined) ? (this.state.history.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.ref_code}</td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.amount)}/>
                                                    </td>
                                                    <td>{(item.status.toLowerCase() === 'credit') ? (
                                                        <span className="badge bg-green m-b-5">Credit</span>
                                                    ) : (item.status.toLowerCase() === 'debit') ? (
                                                        <span className="badge bg-yellow m-b-5">Debit</span>
                                                    ) : (item.status.toLowerCase() === 'processing') ? (
                                                        <span
                                                            className="badge bg-dark m-b-5 text-white">Processing</span>
                                                    ) : (
                                                        <span className="badge badge-danger m-b-5">Failed</span>
                                                    )}
                                                    </td>
                                                    <td><NumberFormat
                                                        renderText={value => <div>{value}</div>}
                                                        prefix={'₦'} displayType={'text'}
                                                        thousandSeparator={true}
                                                        value={Utils.NumberDecimal(item.balance)}/>
                                                    </td>
                                                    <td><Moment
                                                        format="MMM D, Y">{item.created_at}</Moment>
                                                    </td>
                                                    <td><Moment
                                                        format="HH:ss">{item.created_at}</Moment>
                                                    </td>
                                                </tr>
                                            ))) : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-end">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.previousPage(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default UserInvestments;
