import React, {Component} from 'react';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Layout from "../Layout";
import {Context} from "../MyContext";
import Moment from "react-moment";
import API from '../../api';

class Otp extends Component {
    static contextType = Context;

    disableOtp = async () => {
        showLoader();
        try {
            const res = API.post('admin/wallet/disable_otp/');
            toast.success("Disable OTP initiated. Enter OTP to finalise.");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    finaliseDisableOtp = async () => {
        showLoader();
        try {
            const res = API.post('admin/wallet/disable_otp/');
            toast.success("Disable OTP initiated. Enter OTP to finalise.");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'otp'}>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-5 pl-0">
                            <h5>OTP</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>OTP activate and deactivation</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="card pointer">
                                            <div className="card-body bg-green">
                                                <strong>Activate OTP</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div data-toggle="modal" data-target="#otpModal"
                                             onClick={event => this.disableOtp(event)} className="card pointer">
                                            <div className="card-body bg-orange">
                                                <strong>Deactivate OTP</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="otpModal" tabIndex="-1" role="dialog"
                     aria-labelledby="otpModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="otpModalLabel"> Finalize activate </h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="">Enter OTP</label>
                                    <input type="text" className="form-control"/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-green" data-dismiss="modal">Confirm</button>
                                <button className="btn btn-outline-dark" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Otp;
