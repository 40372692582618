import HoldOn from 'react-hold-on';
import toastr from 'toastr';
import $ from 'jquery';

class SetToast {
    success = async (msg) => {
        const check = await this.checkInfo(msg);
        (!check) && toastr.success(msg)
    };

    checkInfo = async (msg) => {
        let div = $('#toast-container').find('.toast-message');
        for (let i = 0; i < div.length; i++) {
            if (div[i].innerText === msg) {
                return true
            }
        }
        return false;
    };

    error = async (msg) => {
        const check = await this.checkInfo(msg);
        (!check) && toastr.error(msg)
    };

    info = async (msg) => {
        const check = await this.checkInfo(msg);
        (!check) && toastr.info(msg)
    }
}

class UtilClass {
    Currency(value, currency) {
        let out_value = parseFloat(parseFloat((value) ? value : 0).toFixed(2)).toLocaleString();
        return `${this.currencyToSym(currency)}${out_value}`;
    }

    NumberDecimal(value) {
        return parseFloat(parseFloat((value) ? value : 0).toFixed(2)).toLocaleString();
    }

    Validate(value) {

    }

    currencyToSym(currency) {
        if (currency === "USD") {
            return '$'
        } else if (currency === "GBP") {
            return '£'
        } else {
            return '₦'
        }
    }
}

let toast = new SetToast();

let Utils = new UtilClass();

var options = {
    theme: "sk-cube-grid",
    message: 'Twelvevest',
    backgroundColor: "#1847B1",
    textColor: "white"
};

var showLoader = function () {
    HoldOn.open(options);
};

var hideLoader = function () {
    HoldOn.close()
};

export {showLoader, hideLoader, toast, Utils}
