import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader} from "../../_helpers/Loader";
import Moment from "react-moment";
import {Context} from "../MyContext";

class Clubs extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            clubs: [],
            email: '',
            previous_page: null,
            next_page: null,
            count: null,
            current_page: null,
            total_pages: null,
            search_value: '',
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getClubList();
        hideLoader();
    }

    getClubList = async () => {
        try {
            let res = await API.get('admin/clubs/');
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                clubs: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
        } catch (e) {


        }
    };

    nextPage = async (page_num) => {
        showLoader();
        try {
            let res = await API.get('admin/clubs/?page=' + page_num);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                clubs: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    nextPageUrl = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                clubs: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader()
        } catch (e) {
            hideLoader()
        }
    };

    searchFields = async (search_value) => {
        showLoader();
        try {
            let res = await API.get(`admin/clubs/?search=${search_value}`);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                clubs: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    render() {
        return (
            <Layout context={this.context} active_menu={'club'} title={'Clubs'}>
                <div className="section">
                    <div className="breadcrumb">
                        <div className="col-sm-6">
                            <h5>Clubs</h5>
                            {/*<a className="btn btn-green">Add to whitelist</a>*/}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Club list</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div id="example_filter" className="dataTables_filter"><label>Search:<input
                                                type="search" className="form-control form-control-sm" placeholder=""
                                                aria-controls="example"
                                                onKeyPress={event => {
                                                    (event.key === 'Enter') && this.searchFields(event.target.value)
                                                }}/></label>
                                            </div>
                                            <small>Press enter to search</small>
                                        </div>
                                    </div>
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Name
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Tag
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Members
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Profile Type
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Created on
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.clubs.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1">{item.name}</td>
                                                    <td>{item.tag}</td>
                                                    <td>{item.club_members.length}</td>
                                                    <td>{item.club_type.toUpperCase()}</td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td>{(item.status.toLowerCase() === 'active') ? (
                                                        <span
                                                            className="badge bg-green">{item.status.toLowerCase()}</span>
                                                    ) : (<span
                                                        className="badge bg-danger">{item.status.toLowerCase()}</span>)}
                                                        <Link to={`/club/${item.id}`} className="ml-3 badge btn-green">View</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPageUrl(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPageUrl(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(Clubs);
