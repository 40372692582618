import React, {useState, useEffect, useContext} from "react"
// import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
// import API from '../../api';
// import {Context} from "../MyContext";
// import Layout from '../Layout';
// import _ from 'lodash';
// import $ from 'jquery';

const ModalBulk = ({actionText, actionFunction, actionArgument, setActionText, size}) => {
  // //type is either income or response
  // let type = props.match.params.type,
  // responseId = props.match.params.responseId
  console.log(actionText, actionArgument, "ddskfss")
  let actionTextString = actionText ? actionText : `Do you want to ${actionArgument.action} these records?`;
  console.log(actionTextString, "F---------------F")
  // if (actionText){
  //   $("#confirmBulkModal").show();
  // }

  return (
    // { actionArgument.action ? "modal fade show" : }
    <div className={"modal fade"} id="confirmBulkModal" tabindex="-1" role="dialog"
              aria-labelledby="confirmBulkModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-${size}`} role="document">
          <div className="modal-content">
              <div className="modal-header bg-green">
                  <h4 className="modal-title" id="confirmBulkModalLabel">Confirm Changes</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={setActionText(null)}>
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div className="modal-body">
                  <>
                    {actionTextString}
                  </>
              </div>
              {/* {singleData.user.accredited == null ?  */}
              <div className="modal-footer">
                  <button type="button" className="btn btn-outline-primary mr-3"
                          data-dismiss="modal" onClick={setActionText(null)}>Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" data-dismiss="modal" onClick={() => actionFunction(actionArgument.action)}>Proceed</button>
              </div>
          </div>
      </div>
    </div>
  )
}

export default ModalBulk;