import React, {useEffect, useState, useContext} from 'react';
import {hideLoader, showLoader, toast} from "../../../_helpers/Loader";
import Layout from "../../Layout";
import {Context} from "../../MyContext";
import Moment from "react-moment";
import API from '../../../api';
import { Link } from 'react-router-dom';
import {MultiSelectCheckBox} from 'multi-select-checkbox/dist/MultiSelectCheckBox';
import ReactMultiselectCheckboxes from 'react-multiselect-checkboxes';
import './style.css'

function QueryParamsToJSON(value) {            
  var list = value.slice(1).split('&'),
      result = {};

  list.forEach(function(keyval) {
      keyval = keyval.split('=');
      var key = keyval[0];
      if (/\[[0-9]*\]/.test(key) === true) {
          var pkey = key.split(/\[[0-9]*\]/)[0];
          if (typeof result[pkey] === 'undefined') {
              result[pkey] = [];
          }
          result[pkey].push(decodeURIComponent(keyval[1] || ''));
      } else {
          result[key] = decodeURIComponent(keyval[1] || '');
      }
  });

  return JSON.parse(JSON.stringify(result));
}

export const CreateRolePermission = (props) => {
    const context = useContext(Context)
    const [permissions, setPermissions] = useState([])

    const edit = QueryParamsToJSON(window.location.search)
    
    const [selectedPerms, setSelectedPerms] = useState([])

    const [roleName, setRoleName] = useState("")
  

    // const handleSelectedPerm = (selectedPerms) => {
    //   setSelectedPerms([...selectedPerms, selectedOption])
    // }

    const getPermissions = async() => {
      showLoader();
      try {
          const res = await API.get('admin/roles/permissions/');
          let dat = res.data.map(item => {
            return{
              label: item.name,
              value: item.codename 
            }
          })
          // console.log(dat)
          setPermissions(dat)
          hideLoader();
      } catch (err) {
          hideLoader();
          // toast.error("fetch was not successful");
      }
    }

    const createOrUpdateRoleAndPerimssion = async(event) => {
      event.preventDefault()
      const postData = {
        name: roleName,
        permissions: selectedPerms.map(item => item.value)
      }

      try {
        if (!Object.keys(edit).includes("edit")){
          const res = await API.post('admin/roles/', postData);

          setPermissions(res.data)
          toast.success("operation successful");
          window.location.href ="/roles-and-permission" 
        } else {
          const res = await API.put(`admin/roles/${edit['edit']}/`, postData);

          setPermissions(res.data)
          toast.success("operation successful");
          window.location.href ="/roles-and-permission" 
        }
        hideLoader();
      } catch (err) {
        hideLoader();
        toast.error("error saving role.");
      }
    }

    const getSingleRoleAndPermission = async() => {
      showLoader();
      try {
          const res = await API.get('admin/roles/permissions/?edit='+edit['edit']);
          setRoleName(res?.data?.group?.name)
          // console.log(roleName)
          let dat = res?.data?.permissions.map(item => {
            return{
              label: item.name,
              value: item.codename 
            }
          })
          // console.log(res.data, dat, res.data.group.name, ">>>>>||||||<<<<<<")
          setSelectedPerms(dat)
          hideLoader();
      } catch (err) {
          hideLoader();
          // toast.error("fetch was not successful");
      }
    }

    useEffect(() => {
        if((Object.keys(edit)).includes("edit")){
          getSingleRoleAndPermission();
        }
        getPermissions();

        return () => {
            console.log("")
        };
    }, []);

    return (
      <Layout active_menu={'role'} context={context} title={'Roles and Permissions'}>
          <section>
              <div className="breadcrumb">
                  <Link to={"/roles-and-permission"}><span
                      className="fa fa-chevron-left"/> Create Roles & Permissions</Link>
              </div>
              <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                      <div className="card">
                          {/* <div className="card-header bg-green">
                              <h4 className="text-center">Add New Investment Opportunity</h4>
                          </div> */}
                          <div className="card-body">
                            {!Object.keys(edit).includes("edit") ?
                              <form onSubmit={event => createOrUpdateRoleAndPerimssion(event)}>
                                  <div className="form-group">
                                      <label>Role Name</label>
                                      <input required className="form-control"
                                            onChange={e => {
                                              setRoleName(e.target.value)
                                            }}/>
                                  </div>
                                  <div className="form-group">
                                    <label>Select Permission To Assign To This Role</label>
                                    <ReactMultiselectCheckboxes options={permissions} width="100%" className={"serach-control"} onChange={setSelectedPerms} value={selectedPerms}/>
                                    {/* <MultiSelectCheckBox selectAllShow={true} showSearchBox={true} onMenuOpen={true} menuIsOpen={true} width="100%" CheckBoxList={permissions} searchFilterClassName={"form-control"} onChange={setSelectedPerms}  selectedCheckBox={selectedPerms} listOfAllCheckBoxParentDivClassName={"mx-1"} listOfCheckBoxItemsClassName={'form-check-input-'} /> */}
                                  </div>
                                  {/* <div className="form-group">
                                      {[1, 2, 3, 4, 5].map(item => (<>
                                          <label>Select Permission To Assign To This Role</label>
                                          <input type="checkbox" defaultValue={item} required className="form-control"
                                            onChange={e => {
                                              if(e.target.checked){
                                                setSelectedPerms(
                                                  [...selectedPerms, e.target.defaultValue]
                                                )
                                              }
                                            }}/></>))}
                                  </div> */}
                                  <div className="form-group">
                                      <button className="btn btn-green btn-block">Add</button>
                                  </div>
                              </form> : <form onSubmit={event => createOrUpdateRoleAndPerimssion(event)}>
                                  <div className="form-group">
                                      <label>Role Name</label>
                                      <input required defaultValue={roleName&&roleName} className="form-control"
                                            onChange={e => {
                                              setRoleName(e.target.value)
                                            }}/>
                                  </div>
                                  <div className="form-group">
                                    <label>Select Permission To Assign To This Role</label>
                                    <ReactMultiselectCheckboxes options={permissions} width="100%" className={"serach-control"} onChange={setSelectedPerms} value={selectedPerms} defaultValue={selectedPerms} defaultMenuIsOpen={true}/>
                                    {/* <MultiSelectCheckBox selectAllShow={true} showSearchBox={true} onMenuOpen={true} menuIsOpen={true} width="100%" CheckBoxList={permissions} searchFilterClassName={"form-control"} onChange={setSelectedPerms}  selectedCheckBox={selectedPerms} listOfAllCheckBoxParentDivClassName={"mx-1"} listOfCheckBoxItemsClassName={'form-check-input-'} /> */}
                                  </div>
                                  {/* <div className="form-group">
                                      {[1, 2, 3, 4, 5].map(item => (<>
                                          <label>Select Permission To Assign To This Role</label>
                                          <input type="checkbox" defaultValue={item} required className="form-control"
                                            onChange={e => {
                                              if(e.target.checked){
                                                setSelectedPerms(
                                                  [...selectedPerms, e.target.defaultValue]
                                                )
                                              }
                                            }}/></>))}
                                  </div> */}
                                  <div className="form-group">
                                      <button className="btn btn-green btn-block">Submit</button>
                                  </div>
                              </form> }
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
    )
}