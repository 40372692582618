import React, {useState} from "react";
import API from '../../../api';

import Layout from '../../Layout';
import {showLoader, hideLoader, toast, Utils} from "../../../_helpers/Loader";
import NumberFormat from "react-number-format";
import {Context} from "../../MyContext";
import {Editor, bold, italic, underline,} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import optionsWysiwyg from '../../helpers/wysiwyg';

const FundInvestmentOffer = (props) => {
  const [editorState, setEditorState] = useState(props.edit ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.investment.description).contentBlocks)) : EditorState.createEmpty());
  const [edit, setEdit] = useState(props.edit ? true : false)

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.setFunds({
      investment: {
          ...props.investment,
          description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
      }
    })
  };

  const uploadCallback = (file, callback) => {
      console.log(file);
      return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        console.log(reader);
        reader.onloadend = async () => {
          const res = await API.uploadFile("users/uploads/", file);
          console.log(res)
          // setValue("thumbnail", res.data.secure_url);
          resolve({ data: { link: res.data.secure_url } });
        };
        reader.readAsDataURL(file);
      });
  };

  let options = optionsWysiwyg(uploadCallback);

  return (
    <>
    <div className="form-group">
        <label>Description</label>
        {/* <textarea required rows="20" className="form-control"
          onChange={event => props.setFunds({
              investment: {
                  ...props.investment,
                  description: event.target.value
              }
          })}> 
        </textarea> */}
        <Editor
            editorState={editorState}
            toolbar={options}
            initialEditorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
        />
    </div>
    <div className="form-group">
        <label>Investment Category</label>
        <select required
                defaultValue={edit ? props.investment.investment_type : ""}
                onChange={event => props.setFunds({
                    investment: {
                        ...props.investment,
                        investment_type: event.target.value
                    }
                })} className="form-control">
            <option value="">---- Select an Option ----</option>
            <option value="NGN Fund">NGN Fund</option>
            <option value="USD Fund">USD Fund</option>
        </select>
    </div>
    <div className="form-group">
      <label>Term <small className="text-danger">(number of months (2 means 2months). OPTIONAL)</small></label>
      <NumberFormat required className="form-control"
          defaultValue={edit ? props.investment.trade_cycle : null}
          onValueChange={(values) => {
            const {value} = values;
            props.setFunds({
              investment: {
                ...props.investment,
                trade_cycle: value
              }
            })
        }}/>
    </div>
    <div className="form-group">
      <label>Liquidity Condition <small className="text-danger">(OPTIONAL)</small></label>
      <input className="form-control"
          defaultValue={edit ? props.investment.liquidation_condition : null}
          onChange={event => props.setFunds({
              investment: {
                  ...props.investment,
                  liquidation_condition: event.target.value
              }
          })}/>
    </div>
    <div className="form-group">
        <label>Total Units <small className="text-danger"></small></label>
        <NumberFormat required className="form-control"
          defaultValue={edit ? props.investment.units : null}
          onValueChange={(values) => {
              const {value} = values;
              props.setFunds({
                  investment: {
                      ...props.investment,
                      units: value
                  }
              })
          }}/>
    </div>

    <div className="form-group">
        <label htmlFor="value">Currency</label>
        <select required
                name=""
                defaultValue={edit ? props.investment.currency : ""}
                onChange={event => props.setFunds({
                    investment: {
                        ...props.investment,
                        currency: event.target.value
                    }
                })}
                id="" className="form-control">
            <option value="">---- Select an Option ----</option>
            <option value="NGN">NGN</option>
            <option value="USD">USD</option>
            <option value="GBP">GBP</option>
        </select>
    </div>
    <div className="form-group">
      <label> Minimum Investment Amount</label>
      <NumberFormat required className="form-control"
        thousandSeparator={true}
        defaultValue={edit ? props.investment.unit_price : null}
        prefix={Utils.currencyToSym(props.investment.currency)}
        onValueChange={(values) => {
            const {value} = values;
            props.setFunds({
                investment: {
                    ...props.investment,
                    unit_price: value
                }
            })
        }}/>
    </div>
    <div className="form-group">
        <label>Risk Level</label>
        <select required
              defaultValue={edit ? props.investment.risk_level : ""}
              onChange={event => props.setFunds({
                  investment: {
                      ...props.investment,
                      risk_level: event.target.value
                  }
              })} className="form-control">
          <option value="">---- Select an Option ----</option>
          <option value="High">High</option>
          <option value="Medium">Medium</option>
          <option value="Low">Low</option>
        </select>
    </div>
    <div className="form-group">
        <label>Status</label>
        <select required
              defaultValue={edit ? props.investment.status : ""}
              onChange={event => props.setFunds({
                  investment: {
                      ...props.investment,
                      status: event.target.value
                  }
              })} className="form-control">
          <option value="">---- Select an Option ----</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="coming soon">Coming Soon</option>
          <option value="sold out">Sold Out</option>
          <option value="closed">Closed</option>
        </select>
    </div>
    <div className="form-group">
      <label>Liquidation Penalty <small><i className="text-danger">(Optional,
          depends on investment type) E.g 2 mean 2%</i></small></label>
        <NumberFormat className="form-control"
              defaultValue={edit ? props.investment.liquidation_penalty : null}
              onValueChange={(values) => {
              const {value} = values;
              props.setFunds({
                  investment: {
                      ...props.investment,
                      liquidation_penalty: value
                  }
              })
          }}/>
    </div>
    <div className="form-group">
      <label>Payout Interval</label>
      <select required
            defaultValue={edit ? props.investment.payout_intervals : ""}
            onChange={event => props.setFunds({
              investment: {
                  ...props.investment,
                  payout_intervals: event.target.value
              }
          })} className="form-control">
        <option value="">---- Select an Option ----</option>
        <option value="monthly">Monthly</option>
        <option value="quarterly">Quarterly</option>
        <option value="half year">Bi-annual</option>
        <option value="annual">Annual</option>
        <option value="annual">Event-based</option>
      </select>
    </div>
    <div className="form-group">
        <label>Partner Company</label>
        <select 
          defaultValue={edit ? props.investment.partner && props.investment.partner.id : ""}
          onChange={event => {
            props.setFunds({
                investment: {
                    ...props.investment,
                    partner: event.target.value
                }
            })
            // console.log(event.target.value)
            // if (["risevest", "RiseVest", "Rise Vest"].includes(props.partners.find(item => item.id == event.target.value).company_name.toLowerCase())) {
            //     props.setFunds({
            //         investment_thirdparty_id: true
            //     })
            // } else {
            //     props.setFunds({
            //         investment_thirdparty_id: false
            //     })
            // }
        }} className="form-control">
            <option value="">--- Select an Option ---</option>
            {props.partners.map(item => (
                <option key={item.id}  value={item.id}> {item.company_name}</option>
            ))}
        </select>
    </div>

    <div className="form-group">
        <label className="mb-2">Banner</label><br/>
        <img id="show-image" src={props.investment.image} alt=""
              style={{width: '41%'}}/>
        {!edit ? (
          <input             
              required accept="image/*" id="get-image"
              onChange={event => props.imageUpload(event)}
              type="file"
              className="form-control mt-2"/>): (
          <input             
                accept="image/*" id="get-image"
                onChange={event => props.imageUpload(event)}
                type="file"
                className="form-control mt-2"/>)
        }
    </div>
    <div className="form-group">
        <div className="custom-control custom-checkbox">
            <input
                defaultChecked={ edit && props.investment.card_payment ? true : false }
                onChange={event => props.setFunds({
                    investment: {
                        ...props.investment,
                        card_payment: event.target.checked
                    }
                })}
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"/>
            <label className="custom-control-label" htmlFor="customCheck1">Card
                Payment</label>
        </div>
    </div>
    <div className="form-group">
        <div className="custom-control custom-checkbox">
            <input
                defaultChecked={ edit && props.investment.transfer_payment ? true : false }
                type="checkbox"
                onChange={event => props.setFunds({
                    investment: {
                        ...props.investment,
                        transfer_payment: event.target.checked
                    }
                })}
                className="custom-control-input"
                id="customCheck2"/>
            <label className="custom-control-label" htmlFor="customCheck2">Transfer
                Payment</label>
        </div>
    </div>
    <div className="form-group">
        <div className="custom-control custom-checkbox">
            <input
                defaultChecked={ edit && props.investment.wallet_payment ? true : false }
                type="checkbox"
                onChange={event => props.setFunds({
                    investment: {
                        ...props.investment,
                        wallet_payment: event.target.checked
                    }
                })}
                className="custom-control-input"
                id="customCheck3"/>
            <label className="custom-control-label" htmlFor="customCheck3">Wallet
                Payment</label>
        </div>
    </div>
    <hr/>
    <div className="form-group">
        <div className="custom-control custom-checkbox">
            <input defaultChecked={edit ? props.investment.multiple_payout : false}
                    type="checkbox"
                    onChange={event => props.setFunds({
                        investment: {
                            ...props.investment,
                            multiple_payout: event.target.checked
                        }, dyna_dates: {}
                    })}
                    className="custom-control-input"
                    id="id_multiple_payout"/>
            <label className="custom-control-label" htmlFor="id_multiple_payout">Multiple
                Payout</label>
        </div>
    </div>
    {(props.investment.multiple_payout) ? (
        <div className="form-group">
            <label htmlFor="id_payout_times">Payout
                times</label>
            <input  min="1"
                    defaultValue={edit ? props.investment.payout_times : "1"}
                    type="number"
                    onChange={event => props.setFunds({
                        investment: {
                            ...props.investment,
                            payout_times: event.target.value
                        }, dyna_dates: {}
                    })}
                    className="form-control mb-2"
                    id="id_payout_times"/>
            <p>Enter dates & Percentages to be paid</p>
            {props.renderMultipleDates(props.investments.payout_dates)}
        </div>
    ) : ''}
    <hr/>
    <div className="form-group">
        <div className="custom-control custom-checkbox">
            <input
                type="checkbox"
                defaultChecked={edit ? props.investment.in_trust : false}
                onChange={event => {
                    props.setFunds({
                        investment: {
                            ...props.investment,
                            in_trust: event.target.checked
                        }
                    })
                }}
                className="custom-control-input"
                id="customCheck7"/>
            <label className="custom-control-label" htmlFor="customCheck7">In
                Trust</label>
        </div>
    </div>
    {props.investment.in_trust ? (
        <div className="form-group">
            <label>Trustee's Name</label>
            <input className="form-control"
                defaultValue={edit ? props.investment.trustee : ""}
                onChange={event => {
                  props.setFunds({
                      investment: {
                          ...props.investment,
                          trustee: event.target.value
                      }
                  })
              }}/>
        </div>
    ) : ""}
    <div className="form-group">
      <label>Management fees</label>
        <div className="input-group">
            <NumberFormat id="management_fees" className="form-control"
                defaultValue={edit ? props.investment.management_fees : ""}
                onValueChange={(values) => {
                  const {value} = values;
                  props.setFunds({
                      investment: {
                          ...props.investment,
                          management_fees: value
                      }
                  })
              }}/>
        </div>
    </div>
    <div className="form-group">
        <label>Which user category has access to this opportunity?</label>
        <select required
                defaultValue={edit ? props.investment.user_category : ""}
                onChange={event => props.setFunds({
                    investment: {
                        ...props.investment,
                        user_category: event.target.value
                    }
                })}
                className="form-control">
            <option value="">---- Select an Option ----</option>
            <option value="tgic">TGIC members only</option>
            <option value="twelve">Twelve Only</option>
            <option value="twelveblack">Twelve Black</option>
            <option value="fwp">FWP</option>
            <option value="all">All categories (all users)</option>
        </select>
    </div>
    <div className="form-group">
        <label>Start Date</label>
        <div className="input-group date">
            <input autoComplete="off" id="start_date" required defaultValue={edit ? props.investment.starting_date : null}
                    type="text" className="form-control datepicker"/>
            <div className="input-group-addon">
                <span className="fa fa-calendar"/>
            </div>
        </div>
    </div>
    <div className="form-group">
        <label>End Date</label>
        <div className="input-group date">
            <input autoComplete="off" id="end_date" required type="text" defaultValue={edit ? props.investment.closing_date : null}
                    className="form-control datepicker"/>
            <div className="input-group-addon">
                <span className="fa fa-calendar"/>
            </div>
        </div>
    </div>
    </>
  )
}


export default FundInvestmentOffer;
