import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Moment from "react-moment";

import $ from 'jquery';
import ReactTooltip from "react-tooltip";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";

require('datatables');

class Users extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            email: '',
            previous_page: null,
            next_page: null,
            count: null,
            current_page: null,
            total_pages: null,
            search_value: '',
            certification_form: {}
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getUserList();
        hideLoader();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactTooltip.rebuild();
    }

    getUserList = async () => {
        try {
            let res = await API.get('admin/sub_investors/');
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
        } catch (e) {
        }
    };

    nextPage = async (page_num) => {
        showLoader();
        try {
            let res = await API.get('admin/sub_investors/?page=' + page_num);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    previousPage = async (page_url) => {
        showLoader();
        try {
            let res = await API.get(page_url);
            this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            await $("html, body").animate({scrollTop: 0}, "slow");
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    searchFields = async (val) => {
        showLoader();
        try {
            let url = `admin/sub_investors/?search=${val}`;
            let res = await API.get(url);
            await this.setState({
                next_page: res.data.next,
                previous_page: res.data.previous,
                users: res.data.results,
                total_pages: res.data.total_pages,
                current_page: res.data.current_page,
            });
            hideLoader();
        } catch (e) {
            hideLoader();
            // console.log(e)
        }
    };

    deactivateAccount = async (id) => {
        try {
            let res = await API.post()
        } catch (e) {

        }
    };

    handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await this.searchFields(e.target.value);
        }
    };

    certify = async (event, option) => {
        const postData = this.state.certification_form;
        postData.approval_type = option;
        showLoader();
        try{
            const res = await API.post(`admin/sub_investors/${this.state.certification_form.id}/approval/`, postData);
            toast.success("Successful");
            await this.getUserList()
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            hideLoader();
        }catch (e) {
            toast.error("Something went wrong");
            hideLoader();
            // console.log(e.response)
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'subinvestor'} title={'Child Account'}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb">
                        <h5>Child Accounts</h5>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4>Account List</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div id="example_filter" className="dataTables_filter"><label>Search:<input
                                                type="search" className="form-control form-control-sm" placeholder=""
                                                aria-controls="example"
                                                onKeyPress={event => this.handleSearch(event)}/></label>
                                            </div>
                                            <small>Press enter to search</small>
                                        </div>
                                    </div>
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-responsive table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>Child Full Name
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Parent Full Name
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Parent Email
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Role
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Join Date
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Name Confirmation Request
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.users.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td className="sorting_1"><Link
                                                        to={`/child/${item.id}`}>{item.first_name} {item.last_name}</Link>
                                                    </td>
                                                    <td className="sorting_1"><Link
                                                        to={`/user/${item.user.id}`}>{item.user.first_name} {item.user.last_name}</Link>
                                                    </td>
                                                    <td>{item.user.email}</td>
                                                    <td>
                                                        <span className="badge bg-green ml-1 mr-1">Child</span>
                                                    </td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td>
                                                        <span
                                                            className="badge bg-green text-capitalize">{item.status}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        {(item.status === 'pending approval') ? (
                                                            <span
                                                                onClick={event => this.setState({certification_form: item})}
                                                                data-toggle='modal'
                                                                data-target='#certificateModal'
                                                                className="badge badge-orange pointer">View</span>
                                                        ) : (
                                                            <span className="badge badge-dark">None</span>
                                                        )}

                                                    </td>
                                                    <td>
                                                        <Link data-tip="View User" to={`/user/${item.id}`}
                                                              className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                            className="fa fa-eye ml-1"/>
                                                        </Link>
                                                        <button disabled data-tip="Deactivate User"
                                                                className="btn btn-danger btn-sm mt-1 mr-1">
                                                            <i className="fa fa-user-slash"/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.previousPage(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="certificateModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="example-Modal3">Certification information</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group mb-3">
                                    <label htmlFor="message-text"
                                           className="form-control-label">Last Name Modification</label>
                                    <input readOnly required value={this.state.certification_form.temp_last_name}
                                           className="form-control"/>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="message-text"
                                           className="form-control-label">Certification:</label><br/>
                                    <a target="_blank" href={this.state.certification_form.certificate}>View
                                        certificate</a>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                </button>
                                <button onClick={event => this.certify(event, 'decline')} className="btn btn-orange">Decline</button>
                                <button onClick={event => this.certify(event, 'approve')} className="btn btn-green">Approve</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(Users);
