import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {showLoader, hideLoader, toast, Utils} from "../../_helpers/Loader";
import NumberFormat from "react-number-format";
import {Context} from "../MyContext";
import $ from 'jquery';
import moment from 'moment'
import 'bootstrap-datepicker';
import uuid4 from 'uuid'
import FundInvestmentOffer from './funds/funds'
import NoteInvestmentOffer from './notes/notes';
import RealEstateInvestmentOffer from './realestate/realestate';
import VcFundsInvestmentOffer from './vcfunds/vcfunds';

require('bootstrap');

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class Investments extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            investments: [],
            investment: {},
            page: 1,
            image_change: false,
            form: {},
            partners: [],
            remove_item: {},
            new_save: false,
            investor: '',
            investor_type: 'individual',
            clubs: [],
            club_selected: '',
            dyna_dates: {},
            dyna_roi: {},
            creation: '',
            genericInvestment: {},
            riseVestCustomerAcc: false,
            thirdpartyInvestements: [],
            investment_thirdparty_id: false,
            fund_types: ['real estate fund', 'money market fund', 'investment fund', 'partner investment', 'treasury bill', 'mutual bonds'],
            real_estate: ['real estate', 'rental income']
        }
        this.setState = this.setState.bind(this);
        this.renderMultipleDates = this.renderMultipleDates.bind(this)
    }

    async componentDidMount() {
        showLoader();
        await this.getInvestments();
        await this.getPartners();
        await this.getRiseVestAccount();
        await this.getRiseVestInvestments();
        hideLoader()
    }

    getInvestments = async () => {
        try {
            let res = await API.get('admin/investments/');
            this.setState({
                investments: res.data
            });
        } catch (e) {

        }
    };

    getRiseVestAccount = async () => {
        try {
            let res = await API.get('admin/risevest/get_investment_on_risevest/');
            console.log(res, "we have new data")
            this.setState({
                riseVestCustomerAcc: res.data
            });
        } catch (e) {

        }
    };

    getRiseVestInvestments = async () => {
        try {
            let res = await API.get('admin/risevest/');
            console.log(res, "we have new data")
            this.setState({
                thirdpartyInvestements: res.data
            });
        } catch (e) {

        }
    };

    getPartners = async () => {
        try {
            let res = await API.get('admin/users/partners/');
            this.setState({partners: res.data});
        } catch (e) {
        }
    };

    getOneDetailInvestment = async (id, action='edit') => {
        showLoader();
        try {
            let res = await API.get(`admin/investments/${id}/`);
            console.log(res.data, "nhnhnnn")
            await this.setState({investment: res.data});
            if (action == 'edit') {
                await this.setState({page: 2})
            }
            let rise = ['risevest', 'RiseVest', 'Rise Vest']
            if (rise.includes(res.data.partner.company_name)) {
                await this.setState({investment_thirdparty_id: true})
            }
            await this.setState({page: 7});
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    getOneInvestment = async (id, action='edit') => {
        showLoader();
        try {
            let res = await API.get(`admin/investments/${id}/`);
            console.log(res.data, "nhnhnnn")
            await this.setState({investment: res.data});
            await this.setState({page: 2});
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    };

    getOneThirdpartyInvestment = async (partner, id) =>{
        showLoader();
        try {
            let res = await API.get(`admin/risevest/get_thirdparty_investment/?investment_id=${id}&partner=${partner}`);
            console.log(res);
            await this.setState({investment: res.data.data});
            await this.setState({page: 7});
            hideLoader();
        } catch (e) {
            hideLoader();
            toast.error("Error Fetching investment")
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $('#end_date').datepicker({
            format: "yyyy-mm-dd",
            autoclose: true
        }).on("changeDate", (e) => {
            let date = moment(e.date).format("YYYY-MM-DD");
            this.setState({investment: {...this.state.investment, closing_date: date}});
        });
        $('#start_date').datepicker({
            format: "yyyy-mm-dd",
            autoclose: true
        }).on("changeDate", (e) => {
            let date = moment(e.date).format("YYYY-MM-DD");
            this.setState({investment: {...this.state.investment, start_date: date}});
        });
        $('#order_date').datepicker({
            format: "yyyy-mm-dd",
            autoclose: true
        }).on("changeDate", (e) => {
            let date = moment(e.date).format("YYYY-MM-DD");
            this.setState({investment: {...this.state.investor, order_date: date}});
        });
    }

    imageUpload = async (event, type=null) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            if (type == "due-deligence"){
                if (event.target.files[0].type.toLowerCase().includes("pdf")){
                    showLoader()
                    // reader.onload = (e) => {
                    //     this.setState({investment: {...this.state.investment, due_deligence: e.target.result}, due_deligence_change: true});
                    // };
                    // reader.readAsDataURL(event.target.files[0]);
                    let res = await API.uploadFile("users/uploads/", $($('#get-due-deligence'))[0].files[0]);
                    this.setState({investment: {...this.state.investment, due_deligence: res.data.secure_url}});
                    hideLoader()
                } else {
                    event.target.value = null;
                    toast.error("pdf file is required for due deligence report")
                }
            } else {
                reader.onload = (e) => {
                    this.setState({investment: {...this.state.investment, image: e.target.result}, image_change: true});
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    };

    updateInvestment = async (event) => {
        event.preventDefault();
        let payout_dates = [],
            payout_date_percentages = [];
        $(".payout-dates").map((item) => {
            payout_dates.push($(".payout-dates")[item].value)
        })

        $(".payout-percentage").map((item) => {
            payout_date_percentages.push($(".payout-percentage")[item].value)
        })

        if (this.state.new_save) {
            delete this.state.investment.created_at;
            delete this.state.investment.modified_at;
            delete this.state.investment.user;
            delete this.state.investment.id;
            this.state.investment.partner = this.state.investment.partner.id;
            this.addInvestment(event)
        } else {
            let postData = this.state.investment;
            postData['starting_date'] = $("#start_date").val();
            postData['closing_date'] = $("#end_date").val();
            if (!postData.multiple_payout) {
                postData['payout_times'] = 0
            }
            if (payout_dates.length > 0) (postData['payout_dates'] = payout_dates);
            if (payout_date_percentages.length > 0) postData['payout_date_percentages'] = payout_date_percentages;
            postData['partner'] = this.state.investment.partner && this.state.investment.partner.id ? this.state.investment.partner.id : this.state.investment.partner
            postData['user'] = this.context.user.id
            showLoader();
            try {
                if (this.state.image_change) {
                    let res = await API.uploadFile("users/uploads/", $($('#get-image'))[0].files[0]);
                    postData.image = res.data.secure_url;
                }
                if (this.state.due_deligence_change) {
                    let res = await API.uploadFile("users/uploads/", $($('#get-due-deligence'))[0].files[0]);
                    postData.due_deligence = res.data.secure_url;
                }
                try {
                    let res = await API.put('admin/investments/' + this.state.investment.id + '/', postData);
                    await this.getInvestments();
                    this.setState({page: 1, new_save: false});
                    toast.success("Investment updated successfully");
                    hideLoader()
                } catch (e) {
                    toast.error("Something went wrong");
                    hideLoader()
                }
            } catch (e) {
                toast.error("Something went wrong");
                hideLoader()
            }
        }
    };

    addInvestment = async (event) => {
        event.preventDefault();
        let postData = this.state.investment;
        postData['starting_date'] = $("#start_date").val();
        postData['closing_date'] = $("#end_date").val();
        let payout_dates = [],
            payout_date_percentages = [];
        $(".payout-dates").map((item) => {
            payout_dates.push($(".payout-dates")[item].value)
        })

        $(".payout-percentage").map((item) => {
            payout_date_percentages.push($(".payout-percentage")[item].value)
        })
        postData['payout_dates'] = payout_dates;
        postData['payout_date_percentages'] = payout_date_percentages;
        postData['user'] = this.context.user.id;
        showLoader();
        try {
            console.log(postData, "postData>>")
            if (this.state.image_change) {
                let res = await API.uploadFile("users/uploads/", $($('#get-image'))[0].files[0]);
                postData.image = res.data.secure_url;
            }
            try {
                let res = await API.post('admin/investments/', postData);
                // console.log(res)
                toast.success("Investment successfully added");
                await this.getInvestments();
                this.setState({page: 1, new_save: false, image_change: false, investment: {}});
            } catch (e) {
                toast.error(e.response);
            }
            hideLoader()
        } catch (e) {
            toast.error(e.response);
        }
        hideLoader()
    };

    addGenericInvestment = async (event) => {
        event.preventDefault();
        let postData = this.state.genericInvestment;
        showLoader();
        try {
            // if (this.state.image_change) {
            //     let res = await API.uploadFile("users/uploads/", $($('#get-image'))[0].files[0]);
            //     postData.image = res.data.secure_url;
            // }
            try {
                let res = await API.post('admin/risevest/create_investment_on_risevest/', postData);
                console.log(res)
                toast.success("Investment successfully added on Risevest platform");
                // await this.getInvestments();
                this.setState({page: 1, new_save: false, image_change: false, genericInvestment: {}});
                hideLoader()
            } catch (e) {
                // console.log(e);
                toast.error(e);
                hideLoader()
            }
        } catch (e) {
            // console.log(e.response);
            hideLoader()
        }
    };

    addRiseVestCustomer = async (event) => {
        event.preventDefault();
        let postData = this.state.riseVestCustomerAcc;
        showLoader();
        try {
            if (this.state.image_change) {
                let res = await API.uploadFile("users/uploads/", $($('#get-image'))[0].files[0]);
                postData.proofOfId = res.data.secure_url;
            }
            try {
                let res = await API.post('admin/risevest/create_customer_on_risevest/', postData);
                console.log(res)
                toast.success("Account successfully created on Risevest platform");
                // await this.getInvestments();
                this.setState({page: 1, new_save: false, image_change: false, genericInvestment: {}});
                hideLoader()
            } catch (e) {
                // console.log(e);
                toast.error(e);
                hideLoader()
            }
        } catch (e) {
            // console.log(e.response);
            hideLoader()
        }
    };

    addInvestorOrder = async (event) => {
        event.preventDefault();
        let postData = this.state.investor;
        postData.date = $("#order_date").val();
        postData.units = parseInt(this.state.investor.units);
        postData.investor_type = this.state.investor_type
        showLoader();
        try {
            let res = await API.post('admin/investor_orders/add_investor_order/', postData);
            toast.success("Order added successfully");
            await this.getInvestments();
            this.setState({page: 1});
            hideLoader()
        } catch (e) {
            hideLoader();
            if (e.response.status === 404) {
                toast.error("User or club does not exit");
            } else if (e.response.status === 501) {
                toast.error(e.response.data.error)
            }
        }
    };

    deleteInvestment = async (event) => {
        event.preventDefault();
        showLoader();
        try {
            let res = API.delete('admin/investments/' + this.state.remove_item.id + '/');
            await this.getInvestments();
            window.location.reload();
            toast.success("Investment deleted successfully");
            hideLoader();
        } catch (e) {
            // console.log(e);
            hideLoader()
        }
    };

    changeInvestorType = async (event) => {
        let category = event.target.value;
        showLoader();
        try {
            const res = await API.get('admin/clubs/get_active_clubs/');
            this.setState({investor_type: category, clubs: res.data})
            hideLoader();
        } catch (e) {
            // console.log(e.response)

        }
    }

    renderMultipleDates(dates) {
        if (this.state.investment.multiple_payout && dates === undefined) {
            if (this.state.investment.payout_times === undefined) {
                return (
                    <input required
                           type="date"
                           name={1}
                           onChange={event => this.onDateChange(event)}
                           className="form-control col-8 mb-2 mr-1 payout-dates"/>
                )
            } else if (this.state.investment.payout_times === "") {
                return (
                    <input required
                           type="date"
                           name={1}
                           onChange={event => this.onDateChange(event)}
                           className="form-control col-8 mb-2 mr-1 payout-dates"/>
                )
            } else if (this.state.investment.payout_times > 1) {
                let list = Array.from(Array(parseInt(this.state.investment.payout_times)).keys())
                return (
                    <div>
                        {(list).map((item, key) => (
                            <div className='d-flex'>
                                <input required
                                    type="date"
                                    name={key}
                                    onChange={event => this.onDateChange(event)}
                                    className="form-control col-8 mb-2 mr-1 payout-dates"/>
                                <input required 
                                    type="number" 
                                    name={`${key}_roi`} 
                                    id={`${key}_roi`}
                                    onChange={event => this.onRoiChange(event)}
                                    className="form-control col-4 mb-2 payout-percentage" />
                            </div>
                        ))}
                    </div>
                )
            }
        } else if (this.state.investment.multiple_payout && dates !== undefined) {
            let list = Array.from(Array(parseInt(this.state.investment.payout_times || 0)).keys())
            let payout_dates = this.state.investment.payout_dates
            let payout_date_percentages = this.state.investment.payout_date_percentages || undefined
            console.log(payout_dates, payout_date_percentages, "=======>")
            return (
                <div>
                    {(list).map((item, key) => (
                        <div className='d-flex'>
                            <input required
                                type="date"
                                name={key}
                                defaultValue={(payout_dates[key]) !== undefined ? payout_dates[key] : ''}
                                onChange={event => this.onDateChange(event)}
                                className="form-control col-8 mb-2 mr-1 payout-dates"/>
                            <input required 
                            type="number"
                            step="any"  
                            name={`${key}_roi`} 
                            defaultValue={(payout_date_percentages && payout_date_percentages[key]) !== undefined ? payout_date_percentages[key]: ''}
                            id={`${key}_roi`} 
                            onChange={event => this.onRoiChange(event)}
                            className="form-control col-4 mb-2 payout-percentage" />
                        </div>
                    ))}

                </div>
            )
        }
    }

    onDateChange = (event) => {
        const input_obj = this.state.dyna_dates;
        let get_percentage = document.getElementById(`${event.target.name}_roi`).value
        console.log(get_percentage, "get_percentage")
        if(!get_percentage || get_percentage == ""){
            toast.error("Please input the corresponding percentage")
        } else {
            input_obj[event.target.name] = event.target.value;
            this.setState({dyna_dates: {...input_obj}});
        }
    }
    onRoiChange = (event) => {
        const input_obj = this.state.dyna_roi;
        input_obj[event.target.name] = event.target.value;
        this.setState({dyna_roi: {...input_obj}});
    }

    onInvestmentCreationChange = (e) => {
        e.preventDefault();
        this.setState({investment: {}, page: e.target.value && parseInt(e.target.value.split("|")[1])})
        $(".modal-backdrop").removeAttr("class");
    }

    onClickChange = (page) => {
        this.setState({investment: {}, page: page})
    }

    investmentsPage() {
        return (
            <Layout context={this.context} active_menu={'investment'} title={'Investments'}>
                <div className="breadcrumb pr-0">
                    <div className="col-5 pl-0">
                        <h5>Investments</h5>
                    </div>
                    <div className="col-sm-7 float-right text-right pr-0">
                        {this.context.user.is_superuser && (
                            <button type="button"
                                    className="btn btn-green mx-2"
                                    onClick={e => this.setState({investment: {}, page: 4})}>Add New
                                Investment
                            </button>
                        )}
                        {/* <button type="button" onClick={e => this.setState({investment: {}, page: 8})}
                                className="btn btn-green">Third Party Investments
                        </button> */}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green">
                                <h4>Investments</h4>
                            </div>
                            <div className="card-body">
                                <div id="example_wrapper"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <table
                                        className="table table-responsive table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Title
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Partner
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>ROI
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Maturity/Tenor
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Opening Date
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Close Date
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Maturity Date
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Action
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.investments.map(item => (
                                            <tr role="row" key={item.id}>
                                                <td className="sorting_1">{item.title}</td>
                                                <td className="sorting_1">{(item.partner !== null) ? item.partner.company_name : ""}</td>
                                                <td className="sorting_1">{item.inv_roi}{}</td>
                                                <td className="sorting_1">{item.maturity_cycle}{}</td>
                                                <td>{moment(item.closing_date).format("DD/MM/YYYY")}</td>
                                                <td>{moment(item.starting_date).format("DD/MM/YYYY")}</td>
                                                <td>{moment(item.starting_date).add(item.maturity_cycle, 'M').format("DD/MM/YYYY")}</td>
                                                <td>
                                                    <button onClick={event => this.getOneDetailInvestment(item.id, "view")}
                                                            className="btn btn-primary btn-sm mt-1 mr-1"><i
                                                        className="fa fa-eye ml-1"/>
                                                    </button>
                                                    <Link to={`/investments-orders/${item.id}`}
                                                          className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                        className="fa fa-list ml-1"/>
                                                    </Link>
                                                    <button onClick={event => {
                                                        this.getOneInvestment(item.id, 'edit')
                                                        this.setState({page: 2,investor: {investment_id: item.id} })
                                                        }}
                                                            className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                        className="fa fa-edit ml-1"/>
                                                    </button>
                                                    {(this.context.user.is_superuser) ? (
                                                        <button onClick={event => {
                                                            this.setState({page: 3, investor: {investment_id: item.id}})
                                                        }} className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                            className="fa fa-plus ml-1"/>
                                                        </button>
                                                    ) : ('')}
                                                    {(this.context.user.is_superuser) ? (
                                                        <button data-toggle="modal" data-target="#deleteModal"
                                                                onClick={event => this.setState({remove_item: item})}
                                                                className="btn btn-danger btn-sm mt-1"><i
                                                            className="fa fa-remove"/></button>
                                                    ) : ''}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog"
                     aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-orange">
                                <h4 className="modal-title" id="deleteModalLabel">Important Info</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.deleteInvestment(event)}>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete <b>{this.state.remove_item.title}</b>.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green mr-3"
                                            data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-danger">Delete</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/*modal for the rise vest and it likes investment */}
                <div className="modal fade" id="otherInvestmentModal" tabIndex="-1" role="dialog"
                     aria-labelledby="otherInvestmentModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="otherInvestmentModalLabel">Investment Creation
                                    Options</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        id="close_other">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label for=""></label>
                                        <select className="form-control" name="creation" id="creation"
                                                onChange={(e) => this.onInvestmentCreationChange(e)}>
                                            <option>Select what to create</option>
                                            <option value="Create Investment On Rise Vest|5">Create Investment On Rise
                                                Vest
                                            </option>
                                            <option value="Add New Investment|4">Add New Investment</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    editPage() {
        return (
            <Layout active_menu={'investment'} context={this.context} title={this.state.investment.title}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Investments</a>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Edit Investment Opportunity</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.updateInvestment(event)}>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input className="form-control" value={this.state.investment.title}
                                                   onChange={event => this.setState({
                                                       investment: {
                                                           ...this.state.investment,
                                                           title: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Company</label>
                                            <input className="form-control" value={this.state.investment.company}
                                                onChange={event => this.setState({
                                                    investment: {
                                                        ...this.state.investment,
                                                        company: event.target.value
                                                    }
                                                })}/>
                                        </div>

                                        <div className="form-group">
                                            <label className="mb-2">Due Diligence Report (Optional)</label><br/>
                                            {this.state.investment?.due_deligence ? 
                                            <a href={this.state.investment?.due_deligence} target="_blank">{this.state.investment?.due_deligence}</a> : ""}
                                            <input             
                                                accept=".pdf" id="get-due-deligence"
                                                onChange={event => this.imageUpload(event, "due-deligence")}
                                                type="file"
                                                className="form-control mt-2"/>
                                            
                                        </div>

                                        <div className="form-group">
                                            <label>Offers</label>
                                            <select required
                                                    value={this.state.investment.offer ? this.state.investment.offer : "Old Offers"}
                                                    onChange={event => this.setState({
                                                        investment: {
                                                            ...this.state.investment,
                                                            offer: event.target.value
                                                        }
                                                    })} className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option>Funds</option>
                                                <option>Notes</option>
                                                <option>Real Estate</option>
                                                <option>VC Funds</option>
                                                <option>Old Offers</option>
                                            </select>
                                        </div>
                                        { this.state.investment.offer == "Funds" ? (
                                            <FundInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={true} />
                                        ) : ""}
                                        { this.state.investment.offer == "Notes" ? (
                                            <NoteInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={true} />
                                        ) : ""}
                                        { this.state.investment.offer == "Real Estate" ? (
                                            <RealEstateInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={true} />
                                        ) : ""}
                                        { this.state.investment.offer == "VC Funds" ? (
                                            <VcFundsInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={true} />
                                        ) : ""}
                                        { !this.state.investment.offer || this.state.investment.offer == "Old Offers" ? (<>
                                        <div className="form-group">
                                            <label>Investment Category</label>
                                            <select
                                                value={this.state.fund_types.includes(this.state.investment.investment_type) ? 'funds' : this.state.real_estate.includes(this.state.investment.investment_type) ? 'real estate' : this.state.investment.investment_type }
                                                required
                                                onChange={event => this.setState({
                                                    investment: {
                                                        ...this.state.investment,
                                                        investment_type: event.target.value
                                                    }
                                                })} className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option value="real estate">Real Estate</option>
                                                <option value="agro tech">Agro Finance</option>
                                                <option value="funds">Funds</option>
                                                <option value="vc funds">VC Funds</option>
                                            </select>
                                        </div>

                                        {/* investment type options starts */}
                                        {this.state.investment.investment_type == "real estate" ? (
                                            <div className="form-group">
                                                <label>Investment Type</label>
                                                <select required
                                                        onChange={event => this.setState({
                                                            investment: {
                                                                ...this.state.investment,
                                                                investment_type: event.target.value
                                                            }
                                                        })} className="form-control">
                                                    <option value="">---- Select an Option ----</option>
                                                    <option value="real estate">Ownership</option>
                                                    <option value="rental income">Owner with Rental Income</option>
                                                </select>
                                            </div>): "" }

                                        {this.state.fund_types.includes(this.state.investment.investment_type) || this.state.investment.investment_type === 'funds' ? (
                                            <React.Fragment>
                                                <div className="form-group">
                                                    <label>Investment Type</label>
                                                    <select required
                                                            onChange={event => this.setState({
                                                                investment: {
                                                                    ...this.state.investment,
                                                                    investment_type: event.target.value
                                                                }
                                                            })} className="form-control">
                                                        <option value="">---- Select an Option ----</option>
                                                        <option value="real estate fund">Real Estate Fund</option>
                                                        <option value="money market fund">Money Market Fund</option>
                                                        <option value="investment fund">Investment fund / Credit Debit
                                                            note
                                                        </option>
                                                        <option value="partner investment">Partner Investments</option>
                                                        <option value="treasury bill">Treasury Bills</option>
                                                        <option value="mutual bonds">Mutual Bonds</option>
                                                    </select>
                                                </div>
                                                {this.state.investment.investment_type !== 'vc funds' || this.state.investment.investment_type !== 'real ' ?
                                                <div className="form-group">
                                                    <label>Payout Interval</label>
                                                    <select required
                                                            onChange={event => this.setState({
                                                                investment: {
                                                                    ...this.state.investment,
                                                                    payout_intervals: event.target.value
                                                                }
                                                            })} className="form-control">
                                                        <option value="monthly">Monthly</option>
                                                        <option value="quarterly">Quarterly</option>
                                                        <option value="half year">Half Year</option>
                                                        <option value="annual">Annual</option>
                                                    </select>
                                                </div>: ""}
                                            </React.Fragment>
                                        ) : ''}

                                        {this.state.investment.investment_type !== 'vc funds' ? 
                                        <div className="form-group">
                                            <label>Return on Investment</label>
                                            <NumberFormat required className="form-control"
                                                          suffix={'%'} onValueChange={(values) => {
                                                const {value} = values;
                                                this.setState({investment: {...this.state.investment, inv_roi: value}})
                                            }} value={this.state.investment.inv_roi}
                                            />
                                        </div> : "" }

                                        {/* investment type options ends */}

                                        {this.state.fund_types.includes(this.state.investment.investment_type) || this.state.investment.investment_type === 'funds' || this.state.investment.investment_type === 'real estate' || this.state.investment.investment_type === 'vc funds' || this.state.investment.investment_type === 'rental income' ? '' : (
                                            <div className="form-group">
                                                <label>Trade Cycle</label>
                                                <input required className="form-control"
                                                        value={this.state.investment.trade_cycle}
                                                        onChange={(e) => {
                                                            this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               trade_cycle: e.target.value
                                                           }})
                                                        }}/>
                                            </div>
                                        )}
                                        {this.state.investment.investment_type === 'vc funds' || this.state.investment.investment_type === 'rental income' ? "" :
                                        (<div className="form-group">
                                            <label>{this.state.fund_types.includes(this.state.investment.investment_type) || this.state.investment.investment_type === 'funds' ? 'Tenor' : 'Maturity Period(months)'}</label>
                                            <NumberFormat required className="form-control"
                                                onValueChange={(values) => {
                                                    const {value} = values;
                                                    this.setState({investment: {...this.state.investment, maturity_cycle: value}})
                                                }} value={this.state.investment.maturity_cycle}
                                            />
                                        </div>)}
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea rows="20" className="form-control"
                                                      value={this.state.investment.description}
                                                      onChange={event => this.setState({
                                                          investment: {
                                                              ...this.state.investment,
                                                              description: event.target.value
                                                          }
                                                      })}> </textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Total {this.state.fund_types.includes(this.state.investment.investment_type) || this.state.investment.investment_type === 'funds' || this.state.investment.investment_type === 'real estate' ? 'Tokens' : 'Units'}</label>
                                            <NumberFormat required className="form-control" value={this.state.investment.units}
                                                          onValueChange={(values) => {
                                                              const {value} = values;
                                                              this.setState({
                                                                  investment: {
                                                                      ...this.state.investment,
                                                                      units: value
                                                                  }
                                                              })
                                                          }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Real Estate Tag</label>
                                            <input className="form-control" value={this.state.investment.club_tag}
                                                   onChange={event => this.setState({
                                                       investment: {
                                                           ...this.state.investment,
                                                           club_tag: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="value">Currency</label>
                                            <select required value={this.state.investment.currency}
                                                    name=""
                                                    onChange={event => this.setState({
                                                        investment: {
                                                            ...this.state.investment,
                                                            currency: event.target.value
                                                        }
                                                    })}
                                                    id="" className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option value="NGN">NGN</option>
                                                <option value="USD">USD</option>
                                                <option value="GBP">GBP</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>{this.state.fund_types.includes(this.state.investment.investment_type) || this.state.investment.investment_type === 'funds' ? 'Token' : 'Unit'} Price</label>
                                            <NumberFormat className="form-control"
                                                          value={this.state.investment.unit_price}
                                                          thousandSeparator={true}
                                                          prefix={Utils.currencyToSym(this.state.investment.currency)}
                                                          onValueChange={(values) => {
                                                              const {value} = values;
                                                              this.setState({
                                                                  investment: {
                                                                      ...this.state.investment,
                                                                      unit_price: value
                                                                  }
                                                              })
                                                          }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Risk Level</label>
                                            <select
                                                onChange={event => this.setState({
                                                    investment: {
                                                        ...this.state.investment,
                                                        risk_level: event.target.value
                                                    }
                                                })}
                                                value={this.state.investment.risk_level} className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option value="High">High</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Low">Low</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select
                                                onChange={event => this.setState({
                                                    investment: {
                                                        ...this.state.investment,
                                                        status: event.target.value
                                                    }
                                                })}
                                                value={this.state.investment.status} className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                                <option value="coming soon">Coming Soon</option>
                                                <option value="sold out">Sold Out</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Partner
                                                Company</label>
                                            <select onChange={event => {
                                                this.setState({
                                                    investment: {
                                                        ...this.state.investment,
                                                        partner: event.target.value
                                                    }
                                                })
                                                let rise = ["risevest", "RiseVest", "Rise Vest"]
                                                if (rise.includes(this.state.partners.find(item => item.id == event.target.value).company_name)) {
                                                    this.setState({
                                                        investment_thirdparty_id: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        investment_thirdparty_id: false
                                                    })
                                                }
                                            }}
                                                    value={(this.state.investment.partner) ? this.state.investment.partner.id : ""}
                                                    className="form-control">
                                                <option value="">--- Select an Option ---</option>
                                                {this.state.partners.map(item => (
                                                    <option value={item.id}> {item.company_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {this.state.investment_thirdparty_id ? (<div className="form-group">
                                            <label>ThirdParty Investment Name</label>
                                            <select onChange={event => this.setState({
                                                investment: {
                                                    ...this.state.investment,
                                                    investment_thirdparty_id: event.target.value
                                                }
                                            })}
                                                    value={(this.state.investment.investment_thirdparty_id) ? this.state.investment.partner.investment_thirdparty_id : ""}
                                                    className="form-control">
                                                <option value="">--- Select an Option ---</option>
                                                {this.state.thirdpartyInvestements.map(item => (
                                                    <option value={item.investment_id}> {item.investment_name}</option>
                                                ))}
                                            </select>
                                        </div>) : ""}
                                        <div className="form-group">
                                            <label>Liquidation Penalty <small><i className="text-danger">(Optional,
                                                depends on investment type) E.g 2 mean 2%</i></small></label>
                                            <NumberFormat className="form-control"
                                                          value={this.state.investment.liquidation_penalty}
                                                          onValueChange={(values) => {
                                                              const {value} = values;
                                                              this.setState({
                                                                  investment: {
                                                                      ...this.state.investment,
                                                                      liquidation_penalty: value
                                                                  }
                                                              })
                                                          }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Tenor Type(pa/flat)</label>
                                            <select
                                                onChange={event => this.setState({
                                                    investment: {
                                                        ...this.state.investment,
                                                        tenor_type: event.target.value
                                                    }
                                                })}
                                                value={this.state.investment.tenor_type} className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option value="p.a">P.A</option>
                                                <option value="flat">Flat</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2">Banner</label><br/>
                                            <img id="show-image" src={this.state.investment.image} alt=""
                                                 style={{width: '41%'}}/>
                                            <input accept="image/*" id="get-image"
                                                   onChange={event => this.imageUpload(event)}
                                                   type="file"
                                                   className="form-control mt-2"/>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.investment.card_payment}
                                                       onChange={event => this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               card_payment: event.target.checked
                                                           }
                                                       })}
                                                       type="checkbox"
                                                       className="custom-control-input"
                                                       id="customCheck1"/>
                                                <label className="custom-control-label" htmlFor="customCheck1">Card
                                                    Payment</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.investment.transfer_payment}
                                                       type="checkbox"
                                                       onChange={event => this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               transfer_payment: event.target.checked
                                                           }
                                                       })}
                                                       className="custom-control-input"
                                                       id="customCheck2"/>
                                                <label className="custom-control-label" htmlFor="customCheck2">Transfer
                                                    Payment</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.investment.wallet_payment}
                                                       type="checkbox"
                                                       onChange={event => this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               wallet_payment: event.target.checked
                                                           }
                                                       })}
                                                       className="custom-control-input"
                                                       id="customCheck3"/>
                                                <label className="custom-control-label" htmlFor="customCheck3">Wallet
                                                    Payment</label>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.investment.multiple_payout}
                                                       type="checkbox"
                                                       onChange={event => this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               multiple_payout: event.target.checked
                                                           }
                                                       })}
                                                       className="custom-control-input"
                                                       id="id_multiple_payout"/>
                                                <label className="custom-control-label" htmlFor="id_multiple_payout">Multiple
                                                    Payout</label>
                                            </div>
                                        </div>
                                        {(this.state.investment.multiple_payout) ? (
                                            <div className="form-group">
                                                <label htmlFor="id_payout_times">Payout
                                                    times</label>
                                                <input defaultValue="1" min="1"
                                                       value={this.state.investment.payout_times}
                                                       type="number"
                                                       onChange={event => this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               payout_times: event.target.value
                                                           }, dyna_dates: {}
                                                       })}
                                                       className="form-control mb-2"
                                                       id="id_payout_times"/>
                                                <p>Enter dates & Percentages to be paid</p>
                                                {this.renderMultipleDates(this.state.investment.payout_dates)}
                                            </div>
                                        ) : ''}
                                        <hr/>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.investment.in_trust}
                                                       type="checkbox"
                                                       onChange={event => this.setState({
                                                           investment: {
                                                               ...this.state.investment,
                                                               in_trust: event.target.checked
                                                           }
                                                       })}
                                                       className="custom-control-input"
                                                       id="customCheck7"/>
                                                <label className="custom-control-label" htmlFor="customCheck7">In
                                                    Trust</label>
                                            </div>
                                        </div>
                                        {this.state.investment.in_trust ? (
                                            <div className="form-group">
                                                <label>Trustee's Name</label>
                                                <input className="form-control"
                                                defaultValue={this.state.investment.trustee}
                                                       onChange={event => {
                                                           this.setState({
                                                               investment: {
                                                                   ...this.state.investment,
                                                                   trustee: event.target.value
                                                               }
                                                           })
                                                       }}/>
                                            </div>
                                        ): "" }
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input defaultChecked={this.state.investment.can_roll_over}
                                                    type="checkbox"
                                                    onChange={event => {
                                                        this.setState({
                                                            investment: {
                                                                ...this.state.investment,
                                                                can_roll_over: event.target.checked
                                                            }
                                                        })
                                                    }}
                                                    className="custom-control-input"
                                                    id="customCheck8"/>
                                                <label className="custom-control-label" htmlFor="customCheck8">Rollover on Maturity</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Which user category has access to this opportunity?</label>
                                            <select value={this.state.investment.user_category} required
                                                    onChange={event => this.setState({
                                                        investment: {
                                                            ...this.state.investment,
                                                            user_category: event.target.value
                                                        }
                                                    })}
                                                    className="form-control">
                                                <option readOnly>---- Select an Option ----</option>
                                                <option value="tgic">TGIC members only</option>
                                                <option value="twelve">Twelve Only</option>
                                                <option value="twelveblack">Twelve Black</option>
                                                <option value="fwp">FWP</option>
                                                <option value="all">All categories (all users)</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <div className="input-group date">
                                                <input id="start_date" value={this.state.investment.starting_date}
                                                       type="text" className="form-control datepicker"/>
                                                <div className="input-group-addon">
                                                    <span className="fa fa-calendar"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <div className="input-group date">
                                                <input id="end_date" value={this.state.investment.closing_date}
                                                       type="text"
                                                       className="form-control datepicker"/>
                                                <div className="input-group-addon">
                                                    <span className="fa fa-calendar"/>
                                                </div>
                                            </div>
                                        </div> </>) : ""}
                                        <div className="form-group">
                                            <button className="btn btn-green btn-block mb-2">Update</button>
                                            <button onClick={event => this.setState({new_save: true})}
                                                    className="btn btn-green btn-block">Save as New
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    addPage() {
        return (
            <Layout active_menu={'investment'} context={this.context} title={'Add Investment'}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Investments</a>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Add New Investment Opportunity</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.addInvestment(event)}>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input required className="form-control"
                                                   onChange={event => this.setState({
                                                       investment: {
                                                           ...this.state.investment,
                                                           title: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Company</label>
                                            <input required className="form-control"
                                                   onChange={event => this.setState({
                                                       investment: {
                                                           ...this.state.investment,
                                                           company: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Offers</label>
                                            <select required
                                                    onChange={event => this.setState({
                                                        investment: {
                                                            ...this.state.investment,
                                                            offer: event.target.value
                                                        }
                                                    })} className="form-control">
                                                <option value="">---- Select an Option ----</option>
                                                <option>Funds</option>
                                                <option>Notes</option>
                                                <option>Real Estate</option>
                                                <option>VC Funds</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="mb-2">Due Diligence Report (Optional)</label><br/>
                                            <input             
                                                accept=".pdf" id="get-due-deligence"
                                                onChange={event => this.imageUpload(event, "due-deligence")}
                                                type="file"
                                                className="form-control mt-2"/>
                                            
                                        </div>

                                        { this.state.investment.offer == "Funds" ? (
                                            <FundInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={false} />
                                        ) : ""}
                                        { this.state.investment.offer == "Notes" ? (
                                            <NoteInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={false} />
                                        ) : ""}
                                        { this.state.investment.offer == "Real Estate" ? (
                                            <RealEstateInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={false} />
                                        ) : ""}
                                        { this.state.investment.offer == "VC Funds" ? (
                                            <VcFundsInvestmentOffer setFunds={this.setState} investment={this.state.investment} imageUpload={this.imageUpload} partners={this.state.partners} investments={this.state.investments} renderMultipleDates={this.renderMultipleDates} edit={false} />
                                        ) : ""}
                                        <div className="form-group">
                                            <button className="btn btn-green btn-block">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    addOrderPage() {
        return (
            <Layout active_menu={'investment'} context={this.context} title={'Add Investment Order'}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Investments</a>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Add Investment Order</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.addInvestorOrder(event)}>
                                        <div className="form-group">
                                            <label htmlFor="">Select Investor Category</label>
                                            <select value={this.state.investor_type}
                                                    onChange={event => this.changeInvestorType(event)}
                                                    className="form-control" name="" id="">
                                                <option value="individual">Individual</option>
                                                <option value="club">Club</option>
                                            </select>
                                        </div>
                                        {(this.state.investor_type === 'individual') ? (
                                            <div className="form-group">
                                                <label>Investor Email</label>
                                                <input type="email" required className="form-control"
                                                       onChange={event => this.setState({
                                                           investor: {
                                                               ...this.state.investor,
                                                               email: event.target.value
                                                           }
                                                       })}/>
                                            </div>
                                        ) : (this.state.investor_type === 'club') ? (
                                            <div className="form-group">
                                                <label>Club</label>
                                                <select required className="form-control"
                                                        onChange={event => this.setState({
                                                            investor: {
                                                                ...this.state.investor,
                                                                club_id: event.target.value
                                                            }
                                                        })}>
                                                    <option value="">--- Select a club ---</option>
                                                    {this.state.clubs.map(item => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        ) : ''}
                                        <div className="form-group">
                                            <label>Units/Tokens</label>
                                            <input type="number" min="1" required className="form-control"
                                                   onChange={event => this.setState({
                                                       investor: {
                                                           ...this.state.investor,
                                                           units: event.target.value
                                                       }
                                                   })}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Investment Date</label>
                                            <div className="input-group date">
                                                <input autoComplete="off" id="order_date" required type="text"
                                                       className="form-control datepicker"/>
                                                <div className="input-group-addon">
                                                    <span className="fa fa-calendar"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-green btn-block">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    addGenericInvestmentPage() {
        return (
            <Layout active_menu={'investment'} context={this.context} title={'Add Investment'}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Investments</a>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Add Investment On Rise Vest</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.riseVestCustomerAcc == false ? (
                                        <h4 className="text-danger text-center">
                                            You have to create a customer account on Risevest <br/> before you create an
                                            investement on their platform <br/>
                                            <Link to={`#`} className="btn btn-orange btn-sm mt-1 mr-1"
                                                  onClick={e => this.onClickChange(6)}>
                                                Create Now</Link>
                                        </h4>
                                    ) : (
                                        <form onSubmit={event => this.addGenericInvestment(event)}>
                                            <div className="form-group">
                                                <label>Title <i>(Name of investment on Risevest)</i></label>
                                                <input required className="form-control"
                                                       onChange={event => this.setState({
                                                           genericInvestment: {
                                                               ...this.state.genericInvestment,
                                                               name: event.target.value
                                                           }
                                                       })}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Duration <i>(Months)</i></label>
                                                <select required className="form-control"
                                                        onChange={event => this.setState({
                                                            genericInvestment: {
                                                                ...this.state.genericInvestment,
                                                                duration: event.target.value
                                                            }
                                                        })}>
                                                    <option value=""></option>
                                                    <option value="3">3</option>
                                                    <option value="6">6</option>
                                                    <option value="12">12</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label>Portfolio Type</label>
                                                <select required
                                                        onChange={event => this.setState({
                                                            genericInvestment: {
                                                                ...this.state.genericInvestment,
                                                                portfolio: event.target.value
                                                            }
                                                        })} className="form-control">
                                                    <option value="">---- Select an Option ----</option>
                                                    <option value="stocks">Stocks</option>
                                                    <option value="real_estate">Real Estate</option>
                                                    <option value="fixed_income">Fixed Income</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-green btn-block">Add</button>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    createCustomerAccPageRiseVest() {
        return (
            <Layout active_menu={'investment'} context={this.context} title={'Add Investment'}>
                <section>
                    <div className="breadcrumb">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Investments</a>
                    </div>
                    <div className="row" style={{bottomPadding: "50px"}}>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-md-12 mx-auto py-auto">
                            <div className="card">
                                <div className="card-header bg-green">
                                    <h4 className="text-center">Register On Rise Vest</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={event => this.addRiseVestCustomer(event)} className="pb-5">
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>First Name <i></i></label>
                                                <input required className="form-control"
                                                       onChange={event => this.setState({
                                                           riseVestCustomerAcc: {
                                                               ...this.state.riseVestCustomerAcc,
                                                               firstName: event.target.value
                                                           }
                                                       })}/>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Last Name <i></i></label>
                                                <input required className="form-control"
                                                       onChange={event => this.setState({
                                                           riseVestCustomerAcc: {
                                                               ...this.state.riseVestCustomerAcc,
                                                               lastName: event.target.value
                                                           }
                                                       })}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Email </label>
                                                <input required className="form-control"
                                                       onChange={event => this.setState({
                                                           riseVestCustomerAcc: {
                                                               ...this.state.riseVestCustomerAcc,
                                                               email: event.target.value
                                                           }
                                                       })}
                                                       type="email"/>
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Phone </label>
                                                <input required className="form-control"
                                                       onChange={event => this.setState({
                                                           riseVestCustomerAcc: {
                                                               ...this.state.riseVestCustomerAcc,
                                                               phone: event.target.value
                                                           }
                                                       })}
                                                       type="number"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>BVN </label>
                                                <input required className="form-control"
                                                       onChange={event => this.setState({
                                                           riseVestCustomerAcc: {
                                                               ...this.state.riseVestCustomerAcc,
                                                               bvn: event.target.value
                                                           }
                                                       })}
                                                       type="number"/>
                                            </div>
                                            <div className="form-group col-6">
                                                <label for="">Proof of Identification</label>
                                                <img id="show-image" src={this.state.investment.image} alt=""
                                                     style={{width: '10%'}}/>
                                                <input required accept="image/*" id="get-image"
                                                       onChange={event => this.imageUpload(event, )}
                                                       type="file"
                                                       className="form-control mt-2"/>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-green btn-block">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-5"></div>
                </section>
            </Layout>
        )
    }

    investmentDetailPage() {
        return (
            <Layout context={this.context} active_menu={'investment'} title={'Investments'}>
                <div className="breadcrumb">
                    <a onClick={event => this.setState({page: 1})}><span
                        className="fa fa-chevron-left"/> Investments</a>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green">
                                <h4>Investment: {this.state.investment.name ? this.state.investment.name : ""}</h4>
                            </div>
                            <div className="card-body">
                                <div id="example_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                        <div className="col-3 mb-2"><b>Investment
                                            Image</b><br></br>{this.state.investment.image ?
                                            <img src={this.state.investment.image} className="img-rounded"
                                                 width="100"/> : "No Image"}</div>
                                        <div className="col-3 mb-2"><b>Investment
                                            Name</b><br></br>{this.state.investment.title}</div>
                                        <div className="col-3 mb-2"><b>Investment
                                            ID</b><br></br>{this.state.investment.id}</div>
                                        <div className="col-3 mb-2">
                                            <b>Company</b><br></br>{this.state.investment.company}</div>
                                        <div className="col-3 mb-2"><b>Investment
                                            Type</b><br></br>{this.state.investment.investment_type}</div>
                                        <div className="col-3 mb-2"><b>Created
                                            at</b><br></br>{this.state.investment.created_at}</div>
                                        {/* <div className="col-3 mb-2"><b>Maturity Date</b><br></br>{this.state.investment.matu}</div> */}
                                        <div className="col-3 mb-2"><b>Start
                                            Date</b><br></br>{this.state.investment.closing_date}</div>
                                        <div className="col-3 mb-2"><b>Close
                                            Date</b><br></br>{this.state.investment.starting_date}</div>
                                        <div className="col-3 mb-2"><b>Unit
                                            Price </b><br></br>{this.state.investment.unit_price}</div>
                                        <div className="col-3 mb-2"><b>Investment
                                            ROI</b><br></br>{this.state.investment.inv_roi}</div>
                                        <div className="col-3 mb-2"><b>Club
                                            Tag</b><br></br>{this.state.investment.club_tag}</div>
                                        <div className="col-3 mb-2"><b>Card
                                            Payment</b><br></br>{this.state.investment.card_payment.toString()}</div>
                                        <div className="col-3 mb-2"><b>Wallet
                                            Payment</b><br></br>{this.state.investment.wallet_payment.toString()}</div>
                                        <div className="col-3 mb-2"><b>Transfer
                                            Payment</b><br></br>{this.state.investment.transfer_payment.toString()}
                                        </div>
                                        <div className="col-3 mb-2"><b>Maturity
                                            Cycle</b><br></br>{this.state.investment.maturity_cycle}</div>
                                        <div className="col-3 mb-2"><b>Status</b><br></br>{this.state.investment.status}
                                        </div>
                                        <div className="col-3 mb-2"><b>Tenor
                                            type </b><br></br>{this.state.investment.tenor_type}</div>
                                        <div className="col-3 mb-2"><b>Current Balance Local
                                            Currency</b><br></br>{this.state.investment.currency}</div>
                                        <div className="col-3 mb-2">
                                            <b>Trustee</b><br></br>{this.state.investment.trustee}</div>
                                        <div className="col-3 mb-2"><b>User
                                            Category</b><br></br>{this.state.investment.user_category}</div>
                                        <div className="col-3 mb-2"><b>Payout Times</b><br></br>{this.state.investment.payout_times}</div>
                                        <div className="col-3 mb-2"><b>Paid Times</b><br></br>{this.state.investment.paid_times}</div>
                                        <div className="col-3 mb-2"><b>Payout Dates</b><br></br>{this.state.investment.payout_dates && this.state.investment.payout_dates.join(", ")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    investmentDetail3rdPage() {
        return (
            <Layout context={this.context} active_menu={'investment'} title={'Investments'}>
                <div className="breadcrumb">
                    <a onClick={event => this.setState({page: 8})}><span
                        className="fa fa-chevron-left"/> Investments</a>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green">
                                <h4>Investment: {this.state.investment.name ? this.state.investment.name : ""}</h4>
                            </div>
                            <div className="card-body">
                                <div id="example_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                        <div className="col-3 mb-2"><b>Investment
                                            Name</b><br></br>{this.state.investment.name}</div>
                                        <div className="col-3 mb-2"><b>Investment
                                            ID</b><br></br>{this.state.investment.investmentId}</div>
                                        <div className="col-3 mb-2">
                                            <b>Duration</b><br></br>{this.state.investment.duration}</div>
                                        <div className="col-3 mb-2">
                                            <b>Frequency</b><br></br>{this.state.investment.frequency}</div>
                                        <div className="col-3 mb-2"><b>Plan
                                            Type</b><br></br>{this.state.investment.planType}</div>
                                        <div className="col-3 mb-2"><b>Asset
                                            Type</b><br></br>{this.state.investment.assetType}</div>
                                        <div className="col-3 mb-2"><b>Created
                                            at</b><br></br>{this.state.investment.createdAt}</div>
                                        <div className="col-3 mb-2"><b>Maturity
                                            Date</b><br></br>{this.state.investment.maturityDate}</div>
                                        <div className="col-3 mb-2"><b>Current Balance
                                            at</b><br></br>{this.state.investment.currentBalance}</div>
                                        <div className="col-3 mb-2"><b>Total
                                            Funded</b><br></br>{this.state.investment.totalFunded}</div>
                                        <div className="col-3 mb-2"><b>Total
                                            Withdrawn</b><br></br>{this.state.investment.totalWithdrawn}</div>
                                        <div className="col-3 mb-2"><b>Total
                                            Deposited</b><br></br>{this.state.investment.totalDeposited}</div>
                                        <div className="col-3 mb-2"><b>Total
                                            Returns</b><br></br>{this.state.investment.totalReturns}</div>
                                        <div className="col-3 mb-2"><b>Return
                                            Percentage</b><br></br>{this.state.investment.returnPercent}</div>
                                        <div className="col-3 mb-2"><b>Current Balance Local
                                            Currency</b><br></br>{this.state.investment.currentBalanceLocalCurr}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }


    thirdpartyInvestmentsPage() {
        return (
            <Layout context={this.context} active_menu={'investment'} title={'Investments'}>
                <div className="row breadcrumb pr-0">
                    <div className="col-sm-5 float-left">
                        <a onClick={event => this.setState({page: 1})}><span
                            className="fa fa-chevron-left"/> Investments</a>
                    </div>
                    <div className="col-sm-7 float-right text-right pr-0">
                        {/* <button type="button"
                            className="btn btn-green mx-2" onClick={e => this.setState({investment: {}, page: 4})}>Add New Thirty Party
                    </button> */}
                        <button type="button"
                                className="btn btn-green" data-toggle="modal" data-target="#otherInvestmentModal">Add
                            New
                            ThirdParty Investment
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header bg-green">
                                <h4>Investments on Thirdparty Platform</h4>
                            </div>
                            <div className="card-body">
                                <div id="example_wrapper"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <table
                                        className="table table-responsive-xl table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                        role="grid" aria-describedby="example_info">
                                        <thead>
                                        <tr role="row">
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Title
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Partner
                                            </th>
                                            <th className="wd-15p sorting_asc" tabIndex="0"
                                                aria-controls="example" rowSpan="1" colSpan="1"
                                                aria-sort="ascending"
                                                aria-label="First name: activate to sort column descending"
                                                style={{width: "113px"}}>Investment ID
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Duration
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Plan Type
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Assest Type
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Status
                                            </th>
                                            <th className="wd-15p sorting" tabIndex="0"
                                                aria-controls="example"
                                                rowSpan="1" colSpan="1"
                                                aria-label="Last name: activate to sort column ascending"
                                                style={{width: "113px"}}>Action
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.thirdpartyInvestements.map(item => (
                                            <tr role="row" key={item.id}>
                                                <td className="sorting_1">{item.investment_name}{}</td>
                                                <td className="sorting_1">{item.partner !== null ? item.investment_name : ""}</td>
                                                <td className="sorting_1">{item.investment_id}{}</td>
                                                <td className="sorting_1">{item.duration}{" Month(s)"}</td>
                                                <td className="sorting_1">{item.plan_type}{}</td>
                                                <td className="sorting_1">{item.asset_type}{}</td>
                                                <td className="sorting_1">{item.active}{}</td>
                                                <td>
                                                    <button
                                                        onClick={event => this.getOneThirdpartyInvestment(item.partner, item.investment_id)}
                                                        className="btn btn-orange btn-sm mt-1 mr-1"><i
                                                        className="fa fa-eye ml-1"/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog"
                     aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-orange">
                                <h4 className="modal-title" id="deleteModalLabel">Important Info</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.deleteInvestment(event)}>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete <b>{this.state.remove_item.title}</b>.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green mr-3"
                                            data-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-danger">Delete</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/*modal for the rise vest and it likes investment */}
                <div className="modal fade" id="otherInvestmentModal" tabIndex="-1" role="dialog"
                     aria-labelledby="otherInvestmentModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h4 className="modal-title" id="otherInvestmentModalLabel">Investment Creation
                                    Options</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        id="close_other">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label for=""></label>
                                        <select className="form-control" name="creation" id="creation"
                                                onChange={(e) => this.onInvestmentCreationChange(e)}>
                                            <option>Select what to create</option>
                                            <option value="Create Investment On Rise Vest|5">Create Investment On Rise
                                                Vest
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.investmentsPage();
        } else if (this.state.page === 2) {
            return this.editPage();
        } else if (this.state.page === 3) {
            return this.addOrderPage();
        } else if (this.state.page === 4) {
            return this.addPage();
        } else if (this.state.page === 5) {
            return this.addGenericInvestmentPage();
        } else if (this.state.page === 6) {
            return this.createCustomerAccPageRiseVest();
        } else if (this.state.page === 7) {
            return this.investmentDetailPage();
        } else if (this.state.page === 8) {
            return this.thirdpartyInvestmentsPage();
        } else {
            return this.investmentsPage();
        }
    }

}

export default withRouter(Investments);
