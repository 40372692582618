import React, {useState, useContext, useHistory, useEffect} from 'react';
import {Table, Image, Button, Card, Row, Modal, Icon} from 'react-bootstrap';
import Layout from '../Layout';
import API from '../../api';
import {Context} from "../MyContext";
import PdfViewer from '../fileViewer/pdfViewer';
import { hideLoader, toast, showLoader } from '../../_helpers/Loader';
import './style.css'

function IdentificationView () {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [title2, setTitle2] = useState("");
  const [link, setLink] = useState(null);
  const [userId, setUserId] = useState(null);
  const [identification, setIdentification] = useState([]);
  const [pagination, setPagination] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const context = useContext(Context)

  const getIdentifcation = async (status=null) => {
    let url = 'admin/users/identification_submissions/'
    if (status){
        url = 'admin/users/identification_submissions/?status=' + status 
    }
    try {
        let res = await API.get(url);
        console.log(res)
        setPagination({
            next_page: res.data.next,
            previous_page: res.data.previous,
            total_pages: res.data.total_pages,
            current_page: res.data.current_page,
        });
        setIdentification(res.data.results)
        console.log(res.data.results)
    } catch (e) {
    }
  };

  const actionIdentification = async () => {
    showLoader();
    console.log(userId, title2)
    if (!userId){
      console.log("okoko")
      return;
    }
    let url = `admin/users/${userId&&userId}/action_identification/`; 
    try {
      let res = await API.post(url, {action: title2});
      console.log(res.data)
      getIdentifcation()
    } catch (e) {
      toast.error("Operation could not be performed")
    }
    hideLoader();
  };

  useEffect(() => {
    getIdentifcation()
    return () => {
      console.log("hello")
    };
  }, []);

  return (<>
        <Layout context={context} active_menu={'identification'} title={'Identification'}>
          <Row>
            <Card style={{ width: '100%' }}>
              <Card.Header variant="h3">
                <h3><strong>Users Identification Submission.</strong></h3>
              </Card.Header>
              <Card.Body>
              <Table striped bordered hover responsive="md" size="md" class="">
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Email</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Issue Date</th>
                    <th>Expired Date</th>
                    <th>File</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {identification.map((item, index) => (
                    <tr>
                      {/* <td>{index + 1}</td> */}
                      <td><a href={`/user/${item.id}`} title={`view ${item.first_name} ${item.last_name}`}>{item.email}</a></td>
                      <td>{item.first_name} {item.last_name}</td>
                      <td>{item.kyc_doc_type}</td>
                      <td>{item.kyc_doc_issue_date}</td>
                      <td>{item.kyc_doc_expiry_date}</td>
                      <td>{item.kyc_doc_front ? <Button variant="link" as="a" className='btn-blue' onClick={(e) => {
                        handleShow()
                        setLink(item.kyc_doc_front)
                      }}>View</Button> : 'N/A'}
                      </td>
                      <td>{item.kyc_verified === false ? "Declined": "N/A"}</td>
                      <td><Button variant="outline-secondary" className='' onClick={(e) => {
                        handleShow2();
                        setTitle2("Decline");
                        setUserId(item.id);
                        }}>Decline</Button><span className="mx-1"></span>  
                        <Button variant="primary" className='btn-blue' onClick={(e) => {
                          handleShow2();
                          setTitle2("Approve");
                          setUserId(item.id);
                        }}>Approve</Button><span className="mx-1"></span>
                        <Button variant="light" onClick={(e) => {
                          handleShow2();
                          setTitle2("Delete");
                          setUserId(item.id);
                        }}><span className="fas fa-trash" style={{"color": "red"}}></span></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              </Card.Body>
            </Card>
          </Row>
          <Modal show={show} onHide={handleClose} id="view">
            <Modal.Header closeButton>
              <Modal.Title>Identification</Modal.Title>
            </Modal.Header>
            <Modal.Body>{link?.includes(".pdf") ? <PdfViewer pdf={link ? link : ""} back={null} /> : link?.includes(".png") ? <img src={link} width="100%" /> : "" }</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={show2} onHide={handleClose2} id="confirmation">
            <Modal.Header closeButton>
              <Modal.Title>{`${title2} Identification`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to proceed with this action?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose2}>
                Cancel
              </Button>
              <Button variant="primary" className="btn-blue" onClick={(e) => {
                handleClose2();
                actionIdentification();
              }}>
                Proceed
              </Button>
            </Modal.Footer>
          </Modal>
        </Layout>
  </>)
}

export default IdentificationView;