import React, {useEffect, useState, useContext} from 'react';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Layout from "../Layout";
import {Context} from "../MyContext";
import Moment from "react-moment";
import API from '../../api';
import moment from 'moment';

const ListWalletRequests = (indWallets, approveWalletSystem) => {
  const context = useContext(Context);
  console.log(indWallets, "props")
  return(
    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header bg-green">
                    <h4>Individual Withdrawal Requests</h4>
                </div>
                <div className="card-body">
                    <div id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer table-responsive">
                        <table
                            className="table table-responsive table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                            role="grid" aria-describedby="example_info">
                            <thead>
                              <tr role="row">
                                <th className="wd-15p sorting_asc" tabIndex="0"
                                    aria-controls="example" rowSpan="1" colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="First name: activate to sort column descending"
                                    style={{width: "150px"}}>User
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Amount
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Account
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Status
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Request Date
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Modified Date
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(indWallets || []).map((item) => 
                              <tr role="row" key={item.id}>
                                <td className="sorting_1">{item.user.email}</td>
                                <td className="sorting_1">{item.amount}</td>
                                <td className="sorting_1">{item.account_number}{}</td>
                                <td className="sorting_1">{item.status}{}</td>
                                <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                                <td>{moment(item.modified).format("DD/MM/YYYY")}</td>
                                <td>
                                    {(context.user.is_superuser) && item.status == "pending" ? (
                                        <button onClick={event => {
                                          approveWalletSystem("process", item.id)
                                        }} className="btn btn-orange btn-sm mt-1 mr-1 mx-2"> Approve
                                        </button>
                                    ) : ""}
                                    {(context.user.is_superuser) && item.status == "pending" || item.status == "waiting" ? (
                                        <button onClick={event => {
                                          approveWalletSystem("decline", item.id)
                                        }} className="btn btn-danger btn-sm mt-1 mr-1"> Decline
                                        </button>
                                    ) : ""}
                                </td>
                              </tr>
                              ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
const ListClubWalletRequest = (clubwallets, approveClubWalletSystem) => {
  const context = useContext(Context);
  console.log(clubwallets, "props")

  return(
    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header bg-green">
                    <h4>Club Withdrawal Requests</h4>
                </div>
                <div className="card-body">
                    <div id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer table-responsive">
                        <table
                            className="table table-responsive table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                            role="grid" aria-describedby="example_info">
                            <thead>
                              <tr role="row">
                                <th className="wd-15p sorting_asc" tabIndex="0"
                                    aria-controls="example" rowSpan="1" colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="First name: activate to sort column descending"
                                    style={{width: "150px"}}>User
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Amount
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Account
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Status
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Request Date
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Modified Date
                                </th>
                                <th className="wd-15p sorting" tabIndex="0"
                                    aria-controls="example"
                                    rowSpan="1" colSpan="1"
                                    aria-label="Last name: activate to sort column ascending"
                                    style={{width: "150px"}}>Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(clubwallets || []).map((item) => 
                              <tr role="row" key={item.id}>
                                <td className="sorting_1">{item.user.email}</td>
                                <td className="sorting_1">{item.amount}</td>
                                <td className="sorting_1">{item.account_number}{}</td>
                                <td className="sorting_1">{item.status}{}</td>
                                <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                                <td>{moment(item.modified).format("DD/MM/YYYY")}</td>
                                <td>
                                    {(context.user.is_superuser) && item.status == "pending" ? (
                                        <button onClick={event => {
                                          approveClubWalletSystem("process", item.id)
                                        }} className="btn btn-orange btn-sm mt-1 mr-1 mx-2"> Approve
                                        </button>
                                    ) : ""}
                                    {(context.user.is_superuser) && item.status == "pending" ? (
                                        <button onClick={event => {
                                          approveClubWalletSystem("decline", item.id)
                                        }} className="btn btn-danger btn-sm mt-1 mr-1"> Decline
                                        </button>
                                    ) : ""}
                                </td>
                              </tr>
                              ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const ListRequests = () => {
  const [individual, setIndividual] = useState("individual");
  const context = useContext(Context);

  const [indWallets, setIndWallets] = useState([]);
  const [clubWallets, setClubWallet] = useState([]);

  //get_withdrawal_requests
  const getWithdrawalRequests = async () => {
    showLoader();
    try {
        const res = await API.get('admin/wallet/get_withdrawal_requests/');
        if (res.status == 200) {
            let wallets = res.data.wallet,
                clubwallets = res.data.cwallet;
            setIndWallets(wallets)
            setClubWallet(clubwallets)
        }
        hideLoader();
    } catch (err) {
        hideLoader();
    }
  }

  const approveWalletSystem = async (approval, request_id) => {
    showLoader();
    try {
        const res = await API.post('wallet/admin_wallet_approval/', {approval: approval, request_id: request_id});
        if (res.status == 200) {
            toast.success(res.data.message)
            window.location.reload();
        }
        hideLoader();
    } catch (err) {
        hideLoader();
        toast.error(err)
    }
  }

  const approveClubWalletSystem = async (approval, request_id) => {
    showLoader();
    try {
        const res = await API.post('wallet/admin_club_wallet_approval/', {approval: approval, request_id: request_id});
        if (res.status == 200) {
          toast.success(res.data.message)
          window.location.reload();
        }
        hideLoader();
    } catch (err) {
        hideLoader();
        toast.error(err)
    }
  }

  useEffect(() => {
    getWithdrawalRequests()
    return () => {
      console.log("")
    };
  }, []);


  return (
    <Layout context={context} active_menu={'wallet'} title={'wallet'}>
      <div className="breadcrumb pr-0">
          <div className="col-5 pl-0">
              <h5>Withdrawal Requests</h5>
          </div>
          <div className="col-sm-7 float-right text-right pr-0">
              <button type="button"
                      className="btn btn-green mx-2"
                      onClick={e => setIndividual("individual")}>Individual
              </button>
              <button type="button" onClick={e => setIndividual("club")}
                      className="btn btn-green">Club
              </button>
          </div>
      </div>
      { individual == "individual" ? ListWalletRequests(indWallets, approveWalletSystem) : ListClubWalletRequest(clubWallets, approveClubWalletSystem)}
    </Layout>
  )
}

export default ListRequests;