import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import API from '../../api';

import Layout from '../Layout';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Moment from "react-moment";

import $ from 'jquery';
import ReactTooltip from "react-tooltip";
import {Context} from "../MyContext";
import NumberFormat from "react-number-format";

require('datatables');

class ManageFiles extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);

        this.state = {
            file: {},
            file_form: {},
            users: [],
            selected_obj: {}
        }
    }

    async componentDidMount() {
        showLoader();
        await this.getUserFiles();
        hideLoader();
    }

    getUserFiles = async () => {
        try {
            const res = await API.post('admin/users/get_user_manage_files/', {file_id: this.props.match.params.id});
            this.setState({file: res.data.admin, users: res.data.users})
        } catch (e) {
            // console.log(e.response)
        }
    }

    handleFormAdd = async (event) => {
        event.preventDefault();
        const postData = this.state.file_form;
        showLoader();
        try {
            let res = API.post('admin/users/upload_user_file/', postData);
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.setState({files: res.data, file_form: {}})
            hideLoader();
        } catch (e) {
            hideLoader();
        }
    }

    handleDecision = async decision => {
        const postData = {
            user_upload_id: this.state.selected_obj.id,
            decision: decision
        }
        showLoader();
        try {
            const res = await API.post('admin/users/update_user_upload/', postData)
            this.setState({
                users: [...this.state.users.map(item => {
                    return (item.id === res.data.id) ? res.data : item
                })]
            });
            $('.modal').modal('hide');
            $(document.body).removeClass('modal-open');
            $('.modal-backdrop').remove();
            hideLoader();
        } catch (e) {
            hideLoader();
            // console.log(e)
        }
    }

    render() {
        return (
            <Layout context={this.context} active_menu={'manage-files'} title={'Child Account'}>
                <ReactTooltip/>
                <div className="section">
                    <div className="breadcrumb pr-0">
                        <div className="col-sm-6 ml-0 pl-0">
                             <Link to="/manage-files"> <span className="fa fa-chevron-left"/> Manage Files</Link>
                        </div>
                        <div className="col-sm-6 mr-0 pr-0">
                            <button data-toggle="modal" data-target="#addModal"
                                    className="float-right btn btn-blue"> Add file
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header bg-blue">
                                    <h4>Manage Users Submitted Files</h4>
                                </div>
                                <div className="card-body">
                                    <div id="example_wrapper"
                                         className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <table
                                            className="table table-striped table-bordered border-t0 text-nowrap w-100 dataTable no-footer"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                            <tr role="row">
                                                <th className="wd-15p sorting_asc" tabIndex="0"
                                                    aria-controls="example" rowSpan="1" colSpan="1"
                                                    aria-sort="ascending"
                                                    aria-label="First name: activate to sort column descending"
                                                    style={{width: "113px"}}>User
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>User File
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Date Uploaded
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Status
                                                </th>
                                                <th className="wd-15p sorting" tabIndex="0"
                                                    aria-controls="example"
                                                    rowSpan="1" colSpan="1"
                                                    aria-label="Last name: activate to sort column ascending"
                                                    style={{width: "113px"}}>Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.users.map(item => (
                                                <tr role="row" key={item.id}>
                                                    <td><Link to={`/user/${item.user.id}`}>{item.user.email}</Link></td>
                                                    <td>
                                                        <span className="badge bg-blue"><a className="text-white"
                                                                                            target="_blank"
                                                                                            href={item.uploaded_file}>View</a></span>
                                                    </td>
                                                    <td><Moment format="MMM D, Y">{item.created_at}</Moment></td>
                                                    <td>
                                                        {(item.status === 'approved') ? (
                                                            <span
                                                                className="badge bg-blue text-capitalize">{item.status}</span>
                                                        ) : (
                                                            <span
                                                                className="badge badge-orange text-capitalize">{item.status}</span>
                                                        )}

                                                    </td>
                                                    <td>
                                                        <span onClick={event => this.setState({selected_obj: item})}
                                                              className="badge bg-white badge-outline-blue pointer mr-3" data-toggle="modal"
                                                              data-target="#declineModal">
                                                            Reject
                                                        </span>
                                                        <span onClick={event => this.setState({selected_obj: item})}
                                                              className="badge bg-blue pointer"
                                                              data-toggle="modal" data-target="#approveModal">
                                                            Approve
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex justify-content-center">
                                        <ul className="pagination mt-3 mb-0">
                                            <li className={`${(this.state.previous_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.previousPage(this.state.previous_page)}>‹</a>
                                            </li>
                                            {[...Array(this.state.total_pages)].map((item, key) => (
                                                <li className={`${((key + 1) === this.state.current_page) ? 'active' : ''} page-item`}>
                                                    <a className="page-link"
                                                       onClick={event => this.nextPage(key + 1)}>{key + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`${(this.state.next_page !== null) ? '' : 'disabled'} page-item`}>
                                                <a className="page-link"
                                                   onClick={event => this.nextPage(this.state.next_page)}>»</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="addModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue">
                                <h5 className="modal-title" id="example-Modal3">Add Files</h5>
                                <button type="button"
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={event => this.handleFormAdd(event)}>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="">File Name</label>
                                        <input type="text" required className="form-control"
                                               onChange={event => this.setState({
                                                   file_form: {
                                                       ...this.state.file_form,
                                                       filename: event.target.value
                                                   }
                                               })}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Description</label>
                                        <textarea rows="5" required className="form-control"
                                                  onChange={event => this.setState({
                                                      file_form: {
                                                          ...this.state.file_form,
                                                          description: event.target.value
                                                      }
                                                  })}/>
                                    </div>
                                    <div className="form-group">
                                        <label>File</label>
                                        <input required accept="image/*" id="get-image"
                                               onChange={event => this.imageUpload(event)}
                                               type="file"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-green" data-dismiss="modal">Close
                                    </button>
                                    <button type="submit"
                                            className="btn btn-green">Upload
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal" id="approveModal" tabIndex="-1" role="dialog"
                     aria-labelledby="approveModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue">
                                <h5 className="modal-title" id="approveModalLabel">Approve Upload</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" onClick={event => this.handleDecision('approve')}
                                        className="btn btn-blue">Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="declineModal" tabIndex="-1" role="dialog"
                     aria-labelledby="declineModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-blue-gray">
                                <h5 className="modal-title" id="declineModalLabel">Reject Upload</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button onClick={event => this.handleDecision('disapprove')} type="button"
                                        className="btn bg-white btn-outline-blue">Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(ManageFiles);
