import React, {useEffect, useState, useContext} from 'react';
import {hideLoader, showLoader, toast} from "../../_helpers/Loader";
import Layout from "../Layout";
import {Context} from "../MyContext";
import Moment from "react-moment";
import API from '../../api';

const WithDrawalApproval = () => {
    const [approval, setApproval] = useState("");
    const context = useContext(Context)

    const switchApproval = async (e) => {
        e.preventDefault();
        showLoader();
        try {
            const res = await API.post('admin/wallet/let_admin_approve_withdrawals/', {approval: approval});
            console.log(res)
            if (res.status == 200) {
                setApproval(res.data.approval ? "yes" : "no")
            }
            window.location.reload();
            hideLoader();
            toast.success(res.data.detail);
        } catch (err) {
            hideLoader();
            toast.error("could not update the settings");
        }
    }

    const getswitchApproval = async () => {
        showLoader();
        try {
            const res = await API.get('admin/wallet/let_admin_approve_withdrawals/');
            console.log(res)
            if (res.status == 200) {
                console.log(res.data.approval)
                setApproval(res.data.approval ? "yes" : "no")
                console.log(approval)
            }
            hideLoader();
        } catch (err) {
            hideLoader();
        }
    }


    useEffect(() => {
        getswitchApproval();
        
        return () => {
            console.log("")
        };
    }, []);

    return (
        <Layout context={context} active_menu={'withdrawal-settings'}>
        <div className="section">
            <div className="breadcrumb pr-0">
                <div className="col-5 pl-0">
                    <h5>App Settings</h5>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h4>Wallet Withdrawal Settings</h4>
                    </div>
                    <form onSubmit={event => switchApproval(event)}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="card">

                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Allow Admin Approval before finally initiating withdrawals</label>
                                                <select
                                                    onChange={event => setApproval(event.target.value)}
                                                    value={approval} 
                                                    className="form-control">
                                                        <option></option>
                                                        <option value={"yes"} selected={approval.toLowerCase() == "yes" ? true : false}>Yes</option>
                                                        <option value={"no"} selected={approval.toLowerCase() == "no" ? true : false}>No</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-green">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
    )
}


export default WithDrawalApproval;
